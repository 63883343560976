/* eslint-disable require-jsdoc */
import React, { Component, createRef } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import FormLoader from "../../../../../components/loader/formLoader";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NumberComp2 from "../../../../../components/NumberComp2";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactModal from "react-modal";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import { customStylesauto } from "../../../../../components/customscripts/customscript";
import * as moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import NumberComp from "../../../../../components/NumberComp";
import {
  DealRecurring,
  CalculatePrePayments,
  CalculatePayments,
  CalculateCollateralPayments,
  CalculateConsolidatedPayments,
  CalculateESMAPayments,
  edittransactionDealRecurring,
  deletetransactionDealRecurring,
  addtransactionDealRecurring,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
} from "../../../../../servies/services";
import BeanEater from "../../../../../images/BeanEater.gif";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";
import { Table } from "antd";
import { debounce } from "lodash";
class Accounts_Recurring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      formLoader: false,
      tableData: [],
      tableData1: [],
      AccountDropdown: [],
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      TableName: "Accounts",
      downArrow: false,
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      isAssetType: sessionStorage.getItem("AssetType"),
      isESMA_Flag: sessionStorage.getItem("isESMA_Flag"),
      newData: [],
      EndingBalanceTrackerAdd: "0",
      PreWaterfallEndingBalanceTrackerAdd: "0",
      getLoansLoader: false,
      open3: false,
      open4: false,
      open5: false,
      searchText: "",
      rowsSelected: null,
      endingBalancesArray: [],
      isLimited: false,
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
      openModalPayment: false,
      CollectionPeriodItems: [],
      formData: {
        "Collection Period": "",
        "From Account": "",
        "To Account": "",
        "Transaction Name": "",
        Amount: "",
      },
      formData1: {
        "Collection Period": "",
        "From Account": "",
        "To Account": "",
        "Transaction Name": "",
        Amount: "",
      },
      deleteRowVal: {
        "Collection Period": "",
        "From Account": "",
        "To Account": "",
        "Transaction Name": "",
        Amount: "",
        "Modified Date": "",
        "Txn ID": "",
      },
    };
    this.buttonRef = createRef();
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }
  showPrev = () => {
    window.location.assign("/admin/manualinput_recurring");
  };
  showNext = () => {
    window.location.assign("/admin/tests_recurring");
  };
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.Accounts(this.state.DealName)}>Deal</button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        {/* <hr className="servicer-popover-hr" />
        <button>Change Period</button> */}
      </Popover>
    );
  };

  onCloseModal2 = () => {
    this.setState({ open3: false });
  };

  onCloseModal3 = () => {
    this.setState({ open4: false });
  };
  onCloseModal4 = () => {
    this.setState({ open5: false });
  };

  AddSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.formData &&
      this.state.formData.Amount !== null &&
      this.state.formData.Amount !== undefined
    ) {
      console.log(this.state.formData);
      const renameAmount = parseFloat(
        this.state.formData.Amount.replace(/,/g, "")
      );
      delete this.state.formData.Amount;
      this.state.formData.Amount = renameAmount;

      //   const beginningBalanceArray = this.state.tableData.map(item => parseFloat(item["Beginning Balance"]) || 0);
      // const depositAmountArray = this.state.tableData.map(item => parseFloat(item["Deposit Amount"]) || 0);

      // const sumArray = beginningBalanceArray.map((balance, index) => balance + depositAmountArray[index]);
      // const maxSum = Math.max(...sumArray); // Find the maximum value in sumArray

      // const checkTotal = parseFloat(this.state.formData.Amount) < maxSum;

      // console.log("checkTotal", checkTotal);

      this.addtransactionDealRecurring();
      console.log("hello we have clicked the button");
    } else {
      console.log("formData or Amount is null or undefined");
    }
  };

  addtransactionDealRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.Version;
    data.TableName = "Transactions";
    const ObjAdd = {
      ...this.state.formData,
    };
    data.Data = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await addtransactionDealRecurring(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.Success === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.DealRecurring();
        this.onCloseModal4();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal4();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal4();
    }
  };

  onOpenModal3 = (deleteValue) => {
    console.log("value: ", deleteValue);
    console.log("delete tranche modal");
    this.setState({
      open4: true,
      deleteRowVal: {
        "Collection Period": deleteValue[0],
        "From Account": deleteValue[1],
        "To Account": deleteValue[2],
        "Transaction Name": deleteValue[3],
        Amount: deleteValue[4],
        "Modified Date": deleteValue[6],
        "Txn ID": deleteValue[7],
      },
    });
  };
  handleRowClick = (value, rowData, getKeys) => {
    const getKeyData = getKeys.find((obj) => obj["Txn ID"] === value);
    console.log("value: ", value, rowData, getKeyData);
    // console.log("rowData", rowData);

    this.setState({
      open3: true,
      selectedRow: {
        "Collection Period": getKeyData["Collection Period"],
        "From Account": getKeyData["From Account"],
        "To Account": getKeyData["To Account"],
        "Transaction Name": getKeyData["Transaction Name"],
        Amount: getKeyData["Amount"],
        "Modified Date": getKeyData["Modified Date"],
        "Txn ID": getKeyData["Txn ID"],
      },
      formData1: {
        "Collection Period": getKeyData["Collection Period"],
        "From Account": getKeyData["From Account"],
        "To Account": getKeyData["To Account"],
        "Transaction Name": getKeyData["Transaction Name"],
        Amount: getKeyData["Amount"],
      },
      showModal: true,
    });

    // // Filter the tableData array to get the rows corresponding to "From Account" and "To Account"
    // const fromAccountData = this.state.tableData.find(
    //   (item) => item["Account Name"] === getKeyData["From Account"]
    // );
    // const toAccountData = this.state.tableData.find(
    //   (item) => item["Account Name"] === getKeyData["To Account"]
    // );
    // console.log("?????????", fromAccountData);

    // if (fromAccountData || toAccountData) {
    //   if (fromAccountData) {
    //     this.setState({
    //       "Pre-Waterfall Ending Balance Tracker":
    //         fromAccountData["Pre-Waterfall Ending Balance"],
    //       "EndingBalance Tracker": fromAccountData["Ending Balance"],
    //     });
    //   } else {
    //     this.setState({
    //       "Pre-Waterfall Ending Balance Tracker": Infinity,
    //       "EndingBalance Tracker": Infinity,
    //     });
    //   }
    // } else {
    //   console.log("Data for 'From Account' or 'To Account' not found");
    // }
  };

  handleAddClick = (addData) => {
    console.log("value", addData);
    this.setState({
      open5: true,
      formData: {
        "Collection Period": "",
        "From Account": "",
        "To Account": "",
        "Transaction Name": "",
        Amount: "",
        "Txn ID": "",
      },
      showModal: true,
    });
    // console.log(">>>>>>", this.state.formData["From Account"]);
  };

  // Validation function to check if either "From Account" or "To Account" is selected
  validateAccountsWhileEdit = () => {
    const { "From Account": fromAccount, "To Account": toAccount } =
      this.state.formData1;
    return fromAccount || toAccount;
  };

  // Validation function to check if either "From Account" or "To Account" is selected
  validateAccountsWhileAdd = () => {
    const { "From Account": fromAccount, "To Account": toAccount } =
      this.state.formData;
    return fromAccount || toAccount;
  };

  EditSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formData1);

    const renameAmount = this.state.formData1.Amount.toString().includes(",")
      ? parseFloat(this.state.formData1.Amount.replace(/,/g, ""))
      : this.state.formData1.Amount;
    delete this.state.formData1.Amount;
    this.state.formData1.Amount = renameAmount;

    this.edittransactionDealRecurring();
    console.log("hello we have clicked the Edit button");
  };

  edittransactionDealRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.TableName = "Transactions";
    data.ExistingData = this.state.selectedRow;
    const ObjAdd = {
      ...this.state.formData1,
    };
    data.Data = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await edittransactionDealRecurring(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.DealRecurring();
        this.onCloseModal2();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal2();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal2();
    }
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/tranches_recurring");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
  };

  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/tests_recurring");
  };
  handleBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: true,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/borrowingbase_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/payment_recurring");
  };

  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/collateral_recurring");
  };

  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: true,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/loantape_recurring");
  };

  handleClickConsolidated = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: true,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/consolidated_recurring");
  };

  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: true,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/files_recurring");
  };

  handleClickManualInputs = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: true,
    });
    window.location.assign("/admin/manualinput_recurring");
  };

  deletetransactionDealRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.TableName = "Transactions";
    data.peers = this.state.peers;
    const Obj = {
      ...this.state.deleteRowVal,
    };
    data.DeleteData = Obj;
    data.peers = this.state.peers;
    console.log("dataDelete", data);
    const APIResponse = await deletetransactionDealRecurring(data);

    if (APIResponse.status === 200) {
      if (APIResponse.data.Success === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal3();
        this.DealRecurring();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal3();
      }
    } else {
      this.setState({ formLoader: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  DealRecurring = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      tableData1: [],
      loading: true,
    });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.Version;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await DealRecurring(data);

    console.log("accountsDealRecurring", APIResponse.data);

    if (APIResponse.status === 200) {
      let y = [];
      if (
        this.state.isAssetType === "RMBS" ||
        this.state.isAssetType === undefined ||
        this.state.isAssetType === "" ||
        this.state.isAssetType === "undefined"
      ) {
        // Handle RMBS response
        this.setState({
          getLoansLoader: false,
          loading: false,
          tableData: APIResponse.data["Accounts"],
          tableData1: APIResponse.data["Transactions"],
          openModalPayment: false,
        });

        APIResponse.data["Accounts"].forEach(function (key, value) {
          console.log("key", key["Account Name"]);
          console.log("value", value);
          y.push({ label: key["Account Name"], value: key["Account Name"] });
        });
      } else {
        // Handle other response
        const collectionPeriod =
          APIResponse.data["Accounts"]["Collection Period"];
        const preWaterfall = APIResponse.data["Accounts"]["Pre-Waterfall"];
        const postWaterfall = APIResponse.data["Accounts"]["Post-Waterfall"];

        if (
          collectionPeriod &&
          preWaterfall &&
          postWaterfall &&
          collectionPeriod.length === preWaterfall.length &&
          collectionPeriod.length === postWaterfall.length
        ) {
          const tableData = collectionPeriod.map((item, index) => ({
            ...item,
            "Pre-Waterfall Deposit Amount":
              preWaterfall[index]["Deposit Amount"],
            "Pre-Waterfall Withdrawal Amount":
              preWaterfall[index]["Withdrawal Amount"],
            "Pre-Waterfall Ending Balance":
              preWaterfall[index]["Ending Balance"],
            "Post-Waterfall Deposit Amount":
              postWaterfall[index]["Deposit Amount"],
            "Post-Waterfall Withdrawal Amount":
              postWaterfall[index]["Withdrawal Amount"],
            "Post-Waterfall Ending Balance":
              postWaterfall[index]["Ending Balance"],
            "Deposit Amount": item["Deposit Amount"],
            "Withdrawal Amount": item["Withdrawal Amount"],
            "Ending Balance": item["Ending Balance"],
            "Required Balance": item["Required Balance"],
          }));

          this.setState({
            getLoansLoader: false,
            loading: false,
            tableData: tableData,
            tableData1: APIResponse.data["Transactions"],
            openModalPayment: false,
          });

          collectionPeriod.forEach(function (key, value) {
            console.log("key", key["Account Name"]);
            console.log("value", value);
            y.push({ label: key["Account Name"], value: key["Account Name"] });
          });
        } else {
          // Handle case where arrays are not defined or not of the same length
          console.error("Arrays are not defined or not of the same length");
        }
      }

      console.log("y", y);
      this.setState({ AccountDropdown: y });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onCloseModalBorrow = () => {
    this.setState({ isBorrow: false });
  };
  onOpenModalBorrow = () => {
    this.setState({ isBorrow: true });
  };
  checkBorrowBaseYes = async () => {
    this.setState(
      { calculateBorrow: true, BorrowingBase: "True", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };
  checkBorrowBaseNo = async () => {
    this.setState(
      { calculateBorrow: false, BorrowingBase: "False", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        // const message = APIResponse.data.message;
        // this.props.enqueueSnackbar(message, {
        //   variant: "info",
        //   autoHideDuration: 3000,
        // });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.BorrowingBase = this.state.BorrowingBase;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateCollateralPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateCollateralPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateCollateralPayments(data);

    console.log("GeneralCalculateCollateralPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        await this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        if (sessionStorage.getItem("isESMA_Flag") === "Yes") {
          await this.CalculateESMAPayments();
        } else {
          window.location.assign("/admin/accounts_recurring");
        }
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateESMAPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateESMAPayments(data);

    console.log("PaymentCalculateESMAPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        window.location.assign("/admin/accounts_recurring");
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  Accounts = (value) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("dashChanges", false);
    this.props.history.push({
      pathname: "/admin/account",
      state: { checkRecurring: true },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: false,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: true,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringBorrowingBase: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState(
      { DealName: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.DealName);
        await this.getAllPaymentDatesByDealName();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.DealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.DealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.DealRecurring();
            });
          }
        }
      }
    );
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState(
      { NextPaymentDate: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        await this.getAllVersionsByPaymentDate();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.DealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.DealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.DealRecurring();
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.DealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.DealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.DealRecurring();
            });
          }
        }
      }
    );
  };
  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isDisable: false, Version: "WIP" }, async () => {
        await this.DealRecurring();
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isDisable: this.state.isDisable });
        this.DealRecurring();
      } else {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.DealRecurring();
        });
      }
    }
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },

        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },

        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "white !important",
            backgroundClip: "padding-box",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
            position: "relative",
            width: "1rem",
            zIndex: 1000,
            "&::after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(1, 142, 130, 0.1) !important",
              zIndex: -1,
            },
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            scrollbarWidth: "thin",
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
        },
      },
    });

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  render() {
    const { classes } = this.props;
    const isNonPositive1 = parseFloat(this.state.formData1.Amount) <= 0;
    const isNonPositive = parseFloat(this.state.formData.Amount) <= 0;

    // const checkTotal =
    //   this.state.isAssetType === "RMBS" ||
    //   this.state.formData1["Collection Period"] === "During Collection Period"
    //     ? Number(this.state["EndingBalance Tracker"]) <
    //       parseFloat(this.state.formData1.Amount)
    //     : Number(this.state["Pre-Waterfall Ending Balance Tracker"]) <
    //       parseFloat(this.state.formData1.Amount);

    const { tableData } = this.state;

    //Edit Transaction

    // Filter the tableData array to get the rows corresponding to "From Account" and "To Account"
    const fromAccountDataEdit = tableData.find(
      (item) => item["Account Name"] === this.state.formData1["From Account"]
    );
    const toAccountDataEdit = tableData.find(
      (item) => item["Account Name"] === this.state.formData1["To Account"]
    );

    // Initialize ending balances
    let EndingBalanceTracker = 0;
    let PreWaterfallEndingBalanceTracker = 0;

    // Check if fromAccountData exists, then assign ending balances
    if (fromAccountDataEdit || toAccountDataEdit) {
      if (fromAccountDataEdit) {
        EndingBalanceTracker = fromAccountDataEdit["Ending Balance"];
        PreWaterfallEndingBalanceTracker =
          fromAccountDataEdit["Pre-Waterfall Ending Balance"];
      } else {
        // Handle the case where fromAccountData is undefined
        // You can set default values or handle it according to your requirements
        // For example:
        EndingBalanceTracker = Infinity;
        PreWaterfallEndingBalanceTracker = Infinity;
      }
    }

    // Define a function to parse floats
    const parseAndCompare = (value1, value2) => {
      const parsedValue1 = Number(value1);
      const parsedValue2 = parseFloat(value2);
      return parsedValue1 < parsedValue2;
    };

    // Assign EndingBalanceTracker and this.state.formData1.Amount to variables
    const endingBalanceEdit = EndingBalanceTracker;
    const formDataAmountEdit = this.state.formData1.Amount;

    // Use the parseAndCompare function to compare the values
    const checkTotal =
      this.state.isAssetType === "RMBS" ||
      this.state.formData1["Collection Period"] === "During Collection Period"
        ? parseAndCompare(endingBalanceEdit, formDataAmountEdit)
        : parseAndCompare(PreWaterfallEndingBalanceTracker, formDataAmountEdit);

    // console.log(
    //   "EndingBalanceTracker",
    //   typeof endingBalanceEdit,
    //   typeof formDataAmountEdit,
    //   endingBalanceEdit,
    //   formDataAmountEdit
    // );

    // Add Transaction

    // Destructure formData and tableData from state

    // Find fromAccountData and toAccountData
    const fromAccountData = tableData.find(
      (item) => item["Account Name"] === this.state.formData["From Account"]
    );

    const toAccountData = tableData.find(
      (item) => item["Account Name"] === this.state.formData["To Account"]
    );

    // Initialize ending balances
    let EndingBalanceTrackerAdd = 0;
    let PreWaterfallEndingBalanceTrackerAdd = 0;

    // Check if fromAccountData exists, then assign ending balances
    if (fromAccountData || toAccountData) {
      if (fromAccountData) {
        EndingBalanceTrackerAdd = fromAccountData["Ending Balance"];
        PreWaterfallEndingBalanceTrackerAdd =
          fromAccountData["Pre-Waterfall Ending Balance"];
      } else {
        // Handle the case where fromAccountData is undefined
        // You can set default values or handle it according to your requirements
        // For example:
        EndingBalanceTrackerAdd = Infinity;
        PreWaterfallEndingBalanceTrackerAdd = Infinity;
      }
    }

    // Custom parsing function to convert comma-separated numbers to actual numbers
    const parseNumberWithCommas = (str) => {
      if (typeof str !== "string") {
        // If str is not a string, return it as is
        return str;
      }
      return parseFloat(str.replace(/,/g, ""));
    };
    // Parse numbers with commas for comparison
    const endingBalance = parseNumberWithCommas(EndingBalanceTrackerAdd);
    const formDataAmount = parseNumberWithCommas(this.state.formData["Amount"]);
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    // Check if TotalAdd condition
    const checkTotalAdd =
      this.state.isAssetType === "RMBS" ||
      this.state.formData["Collection Period"] === "During Collection Period"
        ? endingBalance < formDataAmount
        : parseNumberWithCommas(PreWaterfallEndingBalanceTrackerAdd) <
          formDataAmount;

    // console.log("checkTotalAdd", endingBalance, formDataAmount);

    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: "none",
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columnsOld = [
      {
        name: "Account Name",
        label: "Account Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Beginning Balance",
        label: "Beginning Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Deposit Amount",
        label: "Deposit Amount",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.3rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },

      {
        name: "Withdrawal Amount",
        label: "Withdrawal Amount",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.3rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Ending Balance",
        label: "Ending Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.3rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Required Balance",
        label: "Required Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const isNumber = /^-?\d*\.?\d+$/.test(value);

            return (
              <div
                style={{
                  paddingLeft: "1.3rem",
                }}
              >
                {!isNumber ? (
                  value
                ) : (
                  <React.Fragment>
                    <NumberComp2 value={value}></NumberComp2>
                  </React.Fragment>
                )}
              </div>
            );
          },
        },
      },
    ];

    const columns = [
      {
        title: "Account Name",
        dataIndex: "Account Name",
        key: "Account Name",
        fixed: "left",
        sorter: (a, b) => a["Account Name"].localeCompare(b["Account Name"]),
        width: 200,
        className: "account-column",

        // filters: [
        //   {
        //     text: 'Joe',
        //     value: 'Joe',
        //   },
        //   {
        //     text: 'John',
        //     value: 'John',
        //   },
        // ],
        // onFilter: (value, record) => record.name.indexOf(value) === 0,
      },
      // {
      //   title: 'Other',
      //   children: [
      //     {
      //       title: 'Age',
      //       dataIndex: 'age',
      //       key: 'age',
      //       sorter: (a, b) => a.age - b.age,
      //     },
      //     {
      //       title: 'Address',
      //       children: [
      //         {
      //           title: 'Street',
      //           dataIndex: 'street',
      //           key: 'street',
      //         },
      //         {
      //           title: 'Block',
      //           children: [
      //             {
      //               title: 'Building',
      //               dataIndex: 'building',
      //               key: 'building',
      //             },
      //             {
      //               title: 'Door No.',
      //               dataIndex: 'number',
      //               key: 'number',
      //               width: 100,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: "Collection Period",
        className: "parent-column",
        children: [
          {
            title: "Beginning Balance",
            dataIndex: "Beginning Balance",
            key: "Beginning Balance",
            width: 150,
            sorter: (a, b) => a["Beginning Balance"] - b["Beginning Balance"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Deposit Amount",
            dataIndex: "Deposit Amount",
            key: "Deposit Amount",
            width: 150,
            sorter: (a, b) => a["Deposit Amount"] - b["Deposit Amount"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Withdrawal Amount",
            dataIndex: "Withdrawal Amount",
            key: "Withdrawal Amount",
            width: 150,
            sorter: (a, b) => a["Withdrawal Amount"] - b["Withdrawal Amount"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Ending Balance",
            dataIndex: "Ending Balance",
            key: "Ending Balance",
            width: 150,
            sorter: (a, b) => a["Ending Balance"] - b["Ending Balance"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
            className: "child-column",
          },
        ],
      },
      {
        title: "Pre-Waterfall",
        className: "parent-column",
        children: [
          {
            title: "Deposit Amount",
            dataIndex: "Pre-Waterfall Deposit Amount",
            key: "Pre-Waterfall Deposit Amount",
            width: 150,
            sorter: (a, b) =>
              a["Pre-Waterfall Deposit Amount"] -
              b["Pre-Waterfall Deposit Amount"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Withdrawal Amount",
            dataIndex: "Pre-Waterfall Withdrawal Amount",
            key: "Pre-Waterfall Withdrawal Amount",
            width: 150,
            sorter: (a, b) =>
              a["Pre-Waterfall Withdrawal Amount"] -
              b["Pre-Waterfall Withdrawal Amount"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Ending Balance",
            dataIndex: "Pre-Waterfall Ending Balance",
            key: "Pre-Waterfall Ending Balance",
            width: 150,
            sorter: (a, b) =>
              a["Pre-Waterfall Ending Balance"] -
              b["Pre-Waterfall Ending Balance"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
            className: "child-column",
          },
        ],
      },
      {
        title: "Post-Waterfall",
        className: "parent-column",
        children: [
          {
            title: "Deposit Amount",
            dataIndex: "Post-Waterfall Deposit Amount",
            key: "Post-Waterfall Deposit Amount",
            width: 150,
            sorter: (a, b) =>
              a["Post-Waterfall Deposit Amount"] -
              b["Post-Waterfall Deposit Amount"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Withdrawal Amount",
            dataIndex: "Post-Waterfall Withdrawal Amount",
            key: "Post-Waterfall Withdrawal Amount",
            width: 150,
            sorter: (a, b) =>
              a["Post-Waterfall Withdrawal Amount"] -
              b["Post-Waterfall Withdrawal Amount"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
          },
          {
            title: "Ending Balance",
            dataIndex: "Post-Waterfall Ending Balance",
            key: "Post-Waterfall Ending Balance",
            width: 150,
            sorter: (a, b) =>
              a["Post-Waterfall Ending Balance"] -
              b["Post-Waterfall Ending Balance"],
            render: (text) => (
              <div>
                <React.Fragment>
                  <NumberComp2 value={text} />
                </React.Fragment>
              </div>
            ),
            className: "child-column",
          },
        ],
      },
      {
        title: "Required Balance",
        dataIndex: "Required Balance",
        key: "Required Balance",
        sorter: (a, b) => a["Required Balance"] - b["Required Balance"],
        width: 200,
        render: (text) => (
          <div>
            <React.Fragment>
              <NumberComp2 value={text} />
            </React.Fragment>
          </div>
        ),
      },
    ];
    const columns1 = [
      {
        name: "Collection Period",
        label: "Collection Period",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "From Account",
        label: "From Account",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "To Account",
        label: "To Account",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Transaction Name",
        label: "Transaction Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Amount",
        label: "Amount",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <NumberComp2 value={value}></NumberComp2>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Modified Date",
        label: "ModifiedDate",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "Txn ID",
        label: "Txn ID",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "Txn ID",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th
          //     style={{
          //       backgroundColor: "rgba(1, 142, 130, 0.1)",
          //       borderBottom: "none",
          //       paddingBottom: "5px",
          //       paddingTop: "5px",
          //       paddingLeft: "20px",
          //     }}
          //   >
          //     {columnMeta.label}
          //   </th>
          // ),
          customBodyRender: (value, tableMeta, updateValue) => {
            const getKey = this.state.tableData1;
            const rowData = tableMeta.rowData; // Accessing the row data
            const collectionPeriod = rowData[0];

            return (
              <React.Fragment>
                <div className="">
                  <span>
                    {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}

                    {this.state.isDisable === true ||
                    collectionPeriod === "Waterfall" ? (
                      <>
                        <button
                          className="popupwaterfall"
                          onClick={() =>
                            this.handleRowClick(
                              value,
                              tableMeta.rowData.splice(0, 8),
                              getKey
                            )
                          }
                          disabled
                        >
                          Edit
                        </button>
                        <button
                          className="popupwaterfalldelete"
                          onClick={() =>
                            this.onOpenModal3(tableMeta.rowData.splice(0, 8))
                          }
                          disabled
                        >
                          Delete
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="popupbutton1"
                          onClick={() =>
                            this.handleRowClick(
                              value,
                              tableMeta.rowData.splice(0, 8),
                              getKey
                            )
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="popupbuttons1"
                          onClick={() =>
                            this.onOpenModal3(tableMeta.rowData.splice(0, 8))
                          }
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </span>
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Deal Recurring"} />
          <div className="content">
            <div className="header">
              <Header></Header>
            </div>
            <div className="page-contentofpool1">
              <div className="row1 arrow-dealcontent">
                <div
                  className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1.5rem",
                    width: "90rem",
                  }}
                >
                  <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                    <KeyboardBackspaceIcon
                      onClick={this.goBackToDashboard}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">Deal Recurring</h3>
                  </div>
                </div>
              </div>

              <div className="row1 css-recurring move-dropdowns">
                <div className="recurring_details_new">
                  <div>
                    <label className="dealInfo">Deal Name </label>
                    {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                    <div>
                      <select
                        className="input-select-general-new1-deal"
                        value={this.state?.DealName}
                        onChange={this.handleDealNameChange}
                      >
                        {sortedDeals.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="dealInfo">Payment Date </label>
                    {/* <h6 className="dealInfo1">
                          {this.state?.NextPaymentDate}
                        </h6> */}
                    <div>
                      <select
                        className="input-select-general-new1"
                        value={this.state?.NextPaymentDate}
                        onChange={this.handlePaymentDateChange}
                      >
                        {this.state.getDashboardDates.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="dealInfo">Version </label>
                    {/* <h6 className="dealInfo1">{this.state?.Version}</h6> */}
                    <div>
                      <select
                        className="input-select-general-new1"
                        value={
                          this.state.Version === "WIP"
                            ? sessionStorage.getItem("version")
                            : this.state.Version
                        }
                        onChange={this.handleVersionChange}
                      >
                        {this.state.getDashboardVersions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="buttonsverified uw-deal-details-button-container">
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom"
                    overlay={this.popoverBottom()}
                    onExited={this.handleOverlayHide}
                  >
                    <Button variant="outlined" onClick={this.handleButtonClick}>
                      Edit
                      {this.state.downArrow ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </Button>
                  </OverlayTrigger>
                  {this.state.isDisable === true ||
                  this.state.isLoansProcessed === "No" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.CalculatePrePayments}
                      disabled
                    >
                      Calculate
                      {this.state.formLoader === true ? (
                        <CircularProgress size="22px" color="primary" />
                      ) : (
                        ""
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.formLoader}
                      onClick={
                        sessionStorage.getItem("isSecuritisation") !==
                        "Securitisation"
                          ? this.onOpenModalBorrow
                          : this.CalculatePrePayments
                      }
                    >
                      Calculate
                      {this.state.formLoader === true ? (
                        <CircularProgress size="22px" color="primary" />
                      ) : (
                        ""
                      )}
                    </Button>
                  )}
                </div>
              </div>

              <div className="row row1 movement-tabs">
                <div>
                  {/* <div className="tablechangebutton"> */}
                  <div className="tablechangebuttonloans">
                    <button
                      type="button"
                      onClick={() => this.handleClickGeneral()}
                      className={
                        this.state.activeInsights1 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      General
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickTranches()}
                      className={
                        this.state.activeInsights2 == true
                          ? "issuerDashboard-table-top-button-workbench-active"
                          : "issuerDashboard-table-top-button-workbench"
                      }
                    >
                      Tranches
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickFees()}
                      className={
                        this.state.activeInsights3 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Fees
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickExpenses()}
                      className={
                        this.state.activeInsights4 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Expenses
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickManualInputs()}
                      className={
                        this.state.activeInsightsMI == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Manual Inputs
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickAccounts()}
                      className={
                        this.state.activeInsights5 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Accounts
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickTests()}
                      className={
                        this.state.activeInsights6 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Tests
                    </button>
                    {this.state.isSecuritisation !== "Securitisation" ? (
                      <button
                        type="button"
                        onClick={() => this.handleBorrowingBase()}
                        className={
                          this.state.activeInsightsBB == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Borrowing Base
                      </button>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => this.handleClickVariables()}
                      className={
                        this.state.activeInsights7 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Variables
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickPaymentRules()}
                      className={
                        this.state.activeInsights8 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Payment Rules
                    </button>
                  </div>
                </div>
              </div>

              <div className="row row1 movement-header">
                <div className="investor-heading-container">
                  <h1 className="headerdashboard1">List of Accounts</h1>
                </div>
              </div>

              <React.Fragment>
                <div className="workbench-table-container movement-table">
                  {this.state.isAssetType === undefined ||
                  this.state.isAssetType === "undefined" ||
                  this.state.isAssetType === "RMBS" ||
                  this.state.isAssetType === "" ? (
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        // title={'Dashboard'}
                        data={this.state.tableData}
                        columns={columnsOld}
                        options={options}
                      />
                    </MuiThemeProvider>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={this.state.tableData}
                      bordered
                      size="middle"
                      scroll={{ x: "calc(750px - 100%)", y: 240 }}
                      className="custom-table"
                      pagination={false}
                      style={{ borderRadius: "15px" }}
                    />
                  )}
                </div>
              </React.Fragment>
              <>
                <div className="row row1 movement-header-accounts">
                  <div className="investor-heading-container">
                    <h1 className="headerdashboard1">List of Transactions</h1>
                  </div>
                  {this.state.isDisable === true ||
                  this.state.isLoansProcessed === "No" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleAddClick()}
                      disabled
                    >
                      Add Transactions
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleAddClick()}
                    >
                      Add Transactions
                    </Button>
                  )}
                </div>

                <React.Fragment>
                  <div className="workbench-table-container movement-table-accounts">
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        data={this.state.tableData1}
                        columns={columns1}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </div>
                </React.Fragment>
              </>
            </div>
            <>
              <div className="btn_move_accounts">
                <div className="btn_prev" onClick={this.showPrev}>
                  Previous
                </div>
                <button className="btn_next" onClick={this.showNext}>
                  Next
                </button>
              </div>
            </>
            <>
              <ReactModal
                isOpen={this.state.open3}
                onRequestClose={this.onCloseModal2}
                contentLabel="Minimal Modal Example"
                style={customStylesauto}
              >
                <React.Fragment>
                  <div className="modalPopup">
                    <h2>Edit Transactions</h2>
                    <button
                      type="button"
                      className="closePopup"
                      style={{ minWidth: "30px" }}
                      onClick={this.onCloseModal2}
                    >
                      <CloseIcon></CloseIcon>{" "}
                    </button>

                    {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                    <div className="modalshiftcontent">
                      <form
                        className="form-container"
                        onSubmit={this.EditSubmit}
                      >
                        <div className="input-container">
                          <label className="label"> Collection Period</label>
                          {this.state.getLoansLoader == false ? (
                            <select
                              required
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Collection Period": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Collection Period"]}
                            >
                              <>
                                <option
                                  value=""
                                  disabled
                                  className="selectclass"
                                >
                                  Select Any One
                                </option>
                                <option value="After Collection Period">
                                  After Collection Period
                                </option>
                                <option value="During Collection Period">
                                  During Collection Period
                                </option>
                              </>
                            </select>
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                        </div>
                        <div className="input-container">
                          <label className="label"> From Account</label>
                          {this.state.getLoansLoader == false ? (
                            <select
                              required={!this.validateAccountsWhileEdit()}
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "From Account": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["From Account"]}
                            >
                              <option value="" className="selectclass">
                                Select Any One{" "}
                              </option>
                              {this.state.AccountDropdown.map((item) => {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                        </div>

                        <div className="input-container">
                          <label className="label">To Account</label>
                          {this.state.getLoansLoader == false ? (
                            <select
                              required={!this.validateAccountsWhileEdit()}
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "To Account": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["To Account"]}
                            >
                              <option value="" className="selectclass">
                                Select Any One{" "}
                              </option>
                              {this.state.AccountDropdown.map((item) => {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                        </div>
                        <div className="input-container">
                          <label className="label">Transaction Name</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                formData1: {
                                  ...this.state.formData1,
                                  "Transaction Name": e.target.value,
                                },
                              });
                            }}
                            value={this.state.formData1["Transaction Name"]}
                          />
                        </div>
                        <div className="input-container">
                          <label className="label">Amount</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              let removeCharC = e.target.value.replace(
                                /[^0-9\.]/g,
                                ""
                              );
                              let formattedValue = removeCharC.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              );
                              this.setState({
                                formData1: {
                                  ...this.state.formData1,
                                  Amount: removeCharC,
                                },
                              });
                            }}
                            value={this.state.formData1.Amount}
                          />
                          {/* {isNonPositive1 || checkTotal ? (
                            <p style={{ color: "red" }}>
                              {
                                "Entered Amount should be > 0 and <= current Ending Balance"
                              }
                            </p>
                          ) : null} */}
                        </div>

                        <div className="modalsubmit">
                          <div className="submitbuttonbg">
                            <div className="row">
                              <div className="row justify-content-end1">
                                <button
                                  type="button"
                                  className="popupbutton22"
                                  onClick={this.onCloseModal2}
                                >
                                  Cancel
                                </button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isNonPositive1 || checkTotal}
                                >
                                  Save
                                  {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="22px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </React.Fragment>
              </ReactModal>

              <div id="modal1">
                <ReactModal
                  isOpen={this.state.open4}
                  onRequestClose={this.onCloseModal3}
                  contentLabel="Minimal Modal Example"
                  style={customStylesautosmallmodal1}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h3 className="popupheading">
                        Are you sure, you want to Delete this Transaction "
                        {this.state.deleteRowVal["Collection Period"]}"?
                      </h3>

                      {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                      <div className="modalshiftcontent">
                        <div className="modalsubmit">
                          <div className="submitbuttonbg">
                            <div className="row">
                              <div className="row justify-content-end11">
                                <button
                                  type="button"
                                  className="popupbutton1"
                                  onClick={this.onCloseModal3}
                                >
                                  No
                                </button>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  onClick={this.deletetransactionDealRecurring}
                                >
                                  Yes, Delete it
                                  {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.getLoansLoader === false ? (
                          ""
                        ) : (
                          <FormLoader></FormLoader>
                        )}
                        {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                      </div>
                    </div>
                  </React.Fragment>
                </ReactModal>
              </div>

              <ReactModal
                isOpen={this.state.openModalPayment}
                contentLabel="Minimal Modal Example"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed", // Use fixed positioning for the overlay
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                  },
                  content: {
                    position: "absolute",
                    background: "transparent", // Make the modal content transparent
                    border: "none", // Optional: Remove any borders
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflow: "hidden",
                  },
                }}
              >
                <React.Fragment>
                  <img
                    src={BeanEater}
                    style={{
                      width: "8vw",
                      height: "9vh",
                      backgroundColor: "transparent",
                    }}
                  />
                  <h3 className="dot-loader">Loading.Please wait</h3>
                </React.Fragment>
              </ReactModal>

              <ReactModal
                isOpen={this.state.open5}
                onRequestClose={this.onCloseModal4}
                contentLabel="Minimal Modal Example"
                style={customStylesauto}
              >
                <React.Fragment>
                  <div className="modalPopup">
                    <h2>Add Transactions</h2>
                    <button
                      type="button"
                      className="closePopup"
                      style={{ minWidth: "30px" }}
                      onClick={this.onCloseModal4}
                    >
                      <CloseIcon></CloseIcon>{" "}
                    </button>

                    {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                    <div className="modalshiftcontent">
                      <form
                        className="form-container"
                        onSubmit={this.AddSubmit}
                      >
                        <div className="input-container">
                          <label className="label"> Collection Period</label>
                          {this.state.getLoansLoader == false ? (
                            <select
                              required
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Collection Period": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Collection Period"]}
                            >
                              <>
                                <option
                                  value=""
                                  disabled
                                  className="selectclass"
                                >
                                  Select Any One
                                </option>
                                <option value="After Collection Period">
                                  After Collection Period
                                </option>
                                <option value="During Collection Period">
                                  During Collection Period
                                </option>
                              </>
                            </select>
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                        </div>
                        <div className="input-container">
                          <label className="label"> From Account</label>
                          {this.state.getLoansLoader == false ? (
                            <select
                              required={!this.validateAccountsWhileAdd()}
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "From Account": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["From Account"]}
                            >
                              <option value="" className="selectclass">
                                Select Any One{" "}
                              </option>
                              {this.state.AccountDropdown.map((item) => {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                        </div>

                        <div className="input-container">
                          <label className="label">To Account</label>
                          {this.state.getLoansLoader == false ? (
                            <select
                              required={!this.validateAccountsWhileAdd()}
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "To Account": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["To Account"]}
                            >
                              <option value="" className="selectclass">
                                Select Any One{" "}
                              </option>
                              {this.state.AccountDropdown.map((item) => {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                        </div>
                        <div className="input-container">
                          <label className="label">Transaction Name</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  "Transaction Name": e.target.value,
                                },
                              });
                            }}
                            value={this.state.formData["Transaction Name"]}
                          />
                        </div>
                        <div className="input-container">
                          <label className="label">Amount</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              let removeCharC = e.target.value.replace(
                                /[^0-9\.]/g,
                                ""
                              );
                              let formattedValue = removeCharC.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              );
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  Amount: formattedValue,
                                },
                              });
                            }}
                            value={this.state.formData.Amount}
                          />
                          {/* {isNonPositive || checkTotalAdd ? (
                            <p style={{ color: "red" }}>
                              {
                                "Entered Amount should be > 0 and <= current Ending Balance"
                              }
                            </p>
                          ) : null} */}
                        </div>

                        <div className="modalsubmit">
                          <div className="submitbuttonbg">
                            <div className="row">
                              <div className="row justify-content-end1">
                                <button
                                  type="button"
                                  className="popupbutton22"
                                  onClick={this.onCloseModal4}
                                >
                                  Cancel
                                </button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  // disabled={isNonPositive || checkTotalAdd}
                                >
                                  Save
                                  {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="22px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </React.Fragment>
              </ReactModal>
            </>
          </div>
          <div id="modal1">
            <ReactModal
              isOpen={this.state.isBorrow}
              onRequestClose={this.onCloseModalBorrow}
              contentLabel="Minimal Modal Example"
              style={customStylesautosmallmodalpopupBorrow}
            >
              <React.Fragment>
                <div className="modalPopup">
                  <h3 className="popupheading">
                    Do you want to run Payment Waterfall?
                  </h3>

                  {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                  <div className="modalshiftcontent">
                    <div className="modalsubmit">
                      <div className="submitbuttonbg">
                        <div className="row">
                          <div className="row justify-content-start11">
                            <button
                              type="button"
                              className="popupbutton1"
                              onClick={this.onCloseModalBorrow}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="row justify-content-end11">
                            <button
                              type="button"
                              className="popupbutton1"
                              onClick={this.checkBorrowBaseNo}
                            >
                              No
                            </button>

                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={this.checkBorrowBaseYes}
                            >
                              Yes
                              {/* {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )} */}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </ReactModal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Accounts_Recurring);
