import axios from 'axios';
import { createBrowserHistory } from "history";

const token = sessionStorage.getItem("token");
sessionStorage.setItem("peer_insurer", "peer0.insurer-net");
const peer = sessionStorage.getItem("peer");
const peer_insurer = "peer0.insurer-net";
const peers = sessionStorage.getItem("peers");
const factor_peer = "peer0.factor-net"
const userid = sessionStorage.getItem("user_id");
const LimaPeer = "peer1.trustee.trurep.testing.emulya.com";
const role = "trustee";
const saludaPeer = sessionStorage.getItem("peer");
const ChannelName = sessionStorage.getItem('ChannelName')

export const history = createBrowserHistory();

export const API = axios.create({
	baseURL: process.env.react_app_base_url + 'backendapiumb/',
});

API.interceptors.response.use(
  (response) => {
    // console.log("response", response)
    if (response.data.token == -1) {
      alert("Token expired, please login again!");
      window.location.href = "/";
    } else {
      return response;
    }
  },
  (error) => {
    console.log("error", JSON.stringify(error));
    if (!error.response) {
      console.log("Interceptor - Server is not running");
      // window.location.href = "/server-down";
    } else if (error.response.status === 401) {
      console.log(
        "Interceptor - 401 - Unauthorized: Token Invalid, please login again"
      );
      // alert("Token Missing!")
      sessionStorage.clear();
      window.location.assign("/");
    } else if (error.response.status === 400) {
      console.log("Interceptor - 400" + error.response.data.messages);
      alert("Missing Arguments!");
      return null;
    } else if (error.response.status === 503) {
      console.log("Interceptor - 503" + error.response.data.messages);
      alert("503 service unavailable");
      return null;
    } else if (error.response.status === 504) {
      console.log("Interceptor - 504" + error.response.data.messages);
      alert("504 Gateway Timeout");
      return null;
    } else if (error.response.status === 422) {
      console.log("Interceptor - 422" + error.response.data.messages);
      return error;
    } else if (error.response.status === 404) {
      console.log("Interceptor - 404" + error.response.data.messages);
      return error;
    } else if (error.response.status === 500) {
      console.log("Interceptor - 500" + error.response.data.messages);
      alert("Internal Server Error");
      return error;
    } else {
      return Promise.reject(error);
    }
  }
);

export const authenticate = async (data) => {
  console.log("authenticate data", data);
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/authenticate",
    data
  );
  return response;
};

export const registerUser = async (UserRole, data) => {
  let response = null;
  if (UserRole == "trustee") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "servicer") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "investor") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "investor2") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "investor3") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "investor4") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "investor5") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "demoInvestor") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "wsfstrustee") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  } else if (UserRole == "stoaissuer") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/registerUser",
      data
    );
    return response;
  }
};

export const getAllUserRoles = async () => {
  const response = await API.get(
    process.env.react_app_base_url + "backendapistoa/getAllUserRoles"
  );
  return response;
};

export const getuserByOrgname = async (OrgName) => {
  console.log("OrgName", OrgName);
  if (OrgName == "trustee") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "servicer") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor2") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor3") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor4") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor5") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "demoInvestor") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "wsfstrustee") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "stoaissuer") {
    const response = await API.get(
      process.env.react_app_base_url +
        "backendapistoa/getUserByOrgName?OrgName=" +
        OrgName +
        "&peer=" +
        peer,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  }
};

export const approveUser = async (OrgName, data) => {
  let response = null;
  if (OrgName == "trustee") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "servicer") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor2") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor3") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor4") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "investor5") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "demoInvestor") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "wsfstrustee") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  } else if (OrgName == "stoaissuer") {
    response = await API.post(
      process.env.react_app_base_url + "backendapistoa/approveUser",
      data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  }
};

export const updateUser = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/updateApprovedUserProfileDetails",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

//Intain Admin Apis deal creation

export const getalldeals = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/getalldeals?peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getallInvestors = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/getallInvestors?peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
// For Portfolio

export const GetDataByDefaultValue = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/demodashboard/defaultvalue",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const GetDataByUpdateAsset = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/demodashboard/updateasset",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const GetDataByUpdateIssuer = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/demodashboard/updateissuer",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const GetDataByTableChartData = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/demodashboard/portfolio",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

// Deal Portfolio
export const GetDataByTableChartDataDeal = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/demodashboard/deal",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const GetDataByPaymentDatesDeal = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/demodashboard/paymentdates",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const FilterDataOnPrePayments = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/prepayments",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const FilterDataOnDefaults = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/defaults",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const FilterDataOnLosses = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/losses",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const FilterDataOnCreditMetrics = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/credit",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const FilterDataOnDelinquency = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/delinquency",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const FilterDataOnCashFlow = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/cashflow",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const ViewStratsTab = async (data) => {
  const response = await API.post(
    "https://www.ask.intainabs.com/strats",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getDealDetailsByPaymentDate = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/getDealDetailsByPaymentDate",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const collateralDealRecurring = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/recurring/viewcollateralReport?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&Version=" +
      data.Version +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealPreviewMapFieldsRecurring = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/recurring/loanTape",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const PublishReport = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/recurring/publish",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const servicerGetalldeals = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/servicer/getalldeals?ServicerName=" +
      encodeURIComponent(data.ServicerName) +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealServicerViewDetails = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/dealservicerlist?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreationAddSave = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/savetableexp",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreationTable = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/viewtableexp?DealName=" +
      data.DealName +
      "&TableName=" +
      data.TableName +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getPDFLogoData = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/viewpdflogo?ChannelName=" +
      data.ChannelName +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreation_BC_Status = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/bcstatus?DealName=" +
      data.DealName +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreationCreateUpdate = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/createupdate",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreationDelete = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/deletetableexp",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const dealCreationEdit = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/edittableexp",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreationSaveForNow = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/savefornow",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealCreationAutoSave = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/autosave",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const PostPDFLogoData = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/uploadpdflogo",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const ViewAccountTable = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/trustee/viewaccounttable",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const ViewBorrowingTable = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/viewborrowingbasetable",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const savedealservicerdate = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/savedealservicerdate",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};
export const generatedealid = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/generatedealid?DealName=" +
      data.DealName +
      "&oldDealName=" +
      data.oldDealName +
      "&TableName=" +
      data.TableName +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const checkDealName = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/dealcheck?DealName=" +
      data.DealName +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

//Intain Admin Apis Loan processing

export const SaveLoanProcessDate = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/saveloanprocessdate",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const ViewAggregateSummary = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/viewaggregatesummary?peer=" +
      data.peer +
      "&DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&ServicerName=" +
      encodeURIComponent(data.ServicerName),
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const ConsolidatedAggregateSummaryToDB = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/consolidatedaggregatesummarytodb",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const ConsolidatedAggregateSummaryToBC = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/consolidatedaggregatesummarytobc",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealUploadLoanTape = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/UploadLoanTape",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const UploadClosingLoanTape = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/UploadClosingLoanTape",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealPreviewLoanTape = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/PreviewLoanTape1?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&ServicerName=" +
      encodeURIComponent(data.ServicerName) +
      "&ClosingTape=" +
      data.ClosingTape +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const StdfieldsQuery = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/StdfieldsQuery?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&ServicerName=" +
      encodeURIComponent(data.ServicerName) +
      "&ClosingTape=" +
      data.ClosingTape +
      "&peer=" +
      data.peer +
      "&AssetType=" +
      data.AssetType,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getMapping = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/getMapping?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&ServicerName=" +
      encodeURIComponent(data.ServicerName) +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getClosingTapeMapping = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/getClosingTapeMapping?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&ServicerName=" +
      encodeURIComponent(data.ServicerName) +
      "&ClosingTape=" +
      data.ClosingTape +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const saveMapping = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/saveMapping",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealPreviewMapFields = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/PreviewMappedFields?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&ServicerName=" +
      encodeURIComponent(data.ServicerName) +
      "&ClosingTape=" +
      data.ClosingTape +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const Summarize = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/summarize',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const SaveAggregateSummaryToBC = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/saveaggregatesummarytobc',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};


//-------------------------------------------------------------- recurring --------------------------------------------------//

export const generalOnPageReload = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

// export const generalDealRecurring = async (data) => {
// 	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/general',data,{ headers: {"authorization" : `Bearer ${token}`} })
// 	return response;
// };

export const DealRecurring = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/trustee/recurring?DealName=' + data.DealName + '&Month='+ data.Month + '&Year='+ data.Year + '&TableName='+ data.TableName + '&Version='+ data.Version + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const ModifiedDateRecurring = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/modifieddate?DealName=' + data.DealName + '&Month='+ data.Month + '&Year='+ data.Year  + '&peer='+ data.peer + '&OrgName=' + data.OrgName,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};
export const InvestorReportData = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/invreport?DealName=' + data.DealName + '&Month='+ data.Month + '&Year=' + data.Year + '&NextPaymentDate=' + data.NextPaymentDate  + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const getDealDetailsByInvestorPaymentDate = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/investor/getDealDetailsByPaymentDate',data,{ headers: {"authorization" : `Bearer ${token}`} });
	return response;
};


export const tranchesGetDealRecurring = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/tranchetxn?DealName=' + data.DealName + '&Month='+ data.Month + '&Year=' + data.Year +  '&TableName='+ data.TableName + '&Version=' + data.Version  + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};
export const getIndexDealRecurring = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/tranches?DealName=' + data.DealName + '&Month='+ data.Month + '&Year=' + data.Year +  '&TableName='+ data.TableName + '&Version=' + data.Version  + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};


export const editTranchestransactionDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/edittranchetransaction',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const deleteTranchestransactionDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/deletetranchetransaction',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const addtransactionTranchesRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/addtranchetransaction',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const CalculatePayments = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/calculatepayments',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const CalculateCollateralPayments = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/collateralReport',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const CalculateConsolidatedPayments = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/consolidatedReport',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const CalculateESMAPayments = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/recurring/EsmaReport",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const CalculatePrePayments = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/recurring/prepayment",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const ViewConsolidatedReport = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/trustee/recurring/viewconsolidatedReport?DealName=" +
      data.DealName +
      "&Month=" +
      data.Month +
      "&Year=" +
      data.Year +
      "&Version=" +
      data.Version +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getAllVersionsByPaymentDate = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/getAllVersionsByPaymentDate?DealName=" +
      data.DealName +
      "&selectedDate=" +
      data.selectedDate +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const getAllPaymentDatesByDealName = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/getAllPaymentDatesandVersionsByDealName?DealName=" +
      data.DealName +
      "&peer=" +
      data.peer,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const FilesAddDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/addDealDocument',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};
export const FilesRecurring = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/viewDealDocument?DealName=' + data.DealName + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const DownloadRowClick = async (data) => {
	const response = await API.get(
	  process.env.react_app_base_url +
		'backendapistoa/downloadDealDocument?documentID=' +
		data.documentID +
		'&peer=' +
		data.peer,
	  {
		headers: {
		  "authorization": `Bearer ${token}`
		},
		responseType: 'arraybuffer' // Add this line to request binary data
	  }
	);
	return response;
  };
  

export const DownloadExcel = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/downloadconsolidatedloantape?file=" +
      data.file +
      "&file2=" +
      data.file2,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: "arraybuffer", // Add this line to request binary data
    }
  );
  return response;
};

export const tranchesDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/tranches',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};


export const feesDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/fees',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const expensesDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/expenses',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const editexpensesDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/editexpenses',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const editmanualinputRecurring = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/trustee/recurring/editManualInputs",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const testsDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/tests',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const variablesDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/variables',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const accountsDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/accounts',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const addtransactionDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/addtransaction',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const deletetransactionDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/deletetransaction',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const edittransactionDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/edittransaction',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const paymentrulesDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/paymentrules',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const calculatepaymentsDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/calculatepayments',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const generatereportDealRecurring = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/trustee/recurring/generatereport',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const exportAsPDF = async (data) => {
	const response = await API.post(process.env.react_app_base_url + 'backendapistoa/invreportpdf',data,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};



// STD Fields
export const getdefinition = async (data) => {
	const response = await API.get(
	  process.env.react_app_base_url +
		"backendapistoa/trustee/getdefinition?AssetType=" +
		data.AssetType +
		"&peer=" +
		data.peer,
	  { headers: { authorization: `Bearer ${token}` } }
	);
	return response;
  };
  export const deletedefinition = async (data) => {
	const response = await API.post(
	  process.env.react_app_base_url + "backendapistoa/deletedefinition",
	  data,
	  { headers: { authorization: `Bearer ${token}` } }
	);
	return response;
  };
  export const updatedefinition = async (data) => {
	const response = await API.post(
	  process.env.react_app_base_url + "backendapistoa/updatedefinition",
	  data,
	  { headers: { authorization: `Bearer ${token}` } }
	);
	return response;
  };


//-------------------------------------------------------------- Investor Dashboard --------------------------------------------------//


export const investorGetalldeals = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/investor/getalldeals?InvID=' +
  data.InvID + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const getDealDetailsByDealName = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/investor/getDealDetailsByDealName?DealName=' + data.DealName + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};

export const months = [
	{
		value: '1',
		label: 'January',
	},
	{
		value: '2',
		label: 'February',
	},
	{
		value: '3',
		label: 'March',
	},
	{
		value: '4',
		label: 'April',
	},
	{
		value: '5',
		label: 'May',
	},
	{
		value: '6',
		label: 'June',
	},
	{
		value: '7',
		label: 'July',
	},
	{
		value: '8',
		label: 'August',
	},
	{
		value: '9',
		label: 'September',
	},
	{
		value: '10',
		label: 'October',
	},
	{
		value: '11',
		label: 'November',
	},
	{
		value: '12',
		label: 'December',
	},
];

export function generateYears() {
	let min = 2010;
	console.log("min", min)
	let max = new Date().getFullYear();
	console.log("max", max)
	let years = []
	for (var i = min; i <= max; i++) {
		console.log("asdasd", i)
		years.push(
			{
				value: i.toString(),
				label: i.toString()
			}
		)

	}
	let reversarray = years.reverse();
	return reversarray

}


//---------------------------ESMA----------------------//

export const ViewEsmaReport = async (data) => {
	const response = await API.get(process.env.react_app_base_url + 'backendapistoa/recurring/viewesmaReport?DealName=' + data.DealName + '&Month='+ data.Month+ '&Year='+data.Year+ '&Version='+ data.Version  + '&peer='+ data.peer,{ headers: {"authorization" : `Bearer ${token}`} })
	return response;
};


export const QueryDatatapes = async (data) => {
  const response = await API.get(
    process.env.react_app_base_url +
      "backendapistoa/QueryDatatapes?DealName=" +
      data.DealName +
      "&peer=" +
      data.peer +
      "&ServicerName=" + 
      data.ServicerName ,

    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

export const dealUploadLoanTapeprocessing = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url + "backendapistoa/UploadLoanTapeProcessing",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  console.log("servicerjs", response);
  return response;
};

export const getDealDetailsByPaymentDateservicer = async (data) => {
  const response = await API.post(
    process.env.react_app_base_url +
      "backendapistoa/servicer/getDealDetailsByPaymentDate",
    data,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};













