/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar from "../../../../../components/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import "react-input-range/lib/css/index.css";
import * as XLSX from "xlsx";
import { debounce } from "lodash";

import {
  ViewEsmaReport,
  getAllVersionsByPaymentDate,
  getDealDetailsByDealName,
  getAllPaymentDatesByDealName,
} from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import { TableVirtuoso } from "react-virtuoso";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";
import BeanEater from "../../../../../images/BeanEater.gif";
import ReactModal from "react-modal";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
class Annex_Inv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      loading: false,
      tableData: [],
      downArrow: false,
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      ServicerName:
        sessionStorage.getItem("Servicer") === null
          ? sessionStorage.getItem("servicerdashboardname")
          : sessionStorage.getItem("Servicer"),
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      VersionsChange: [],
      isVersionChange: false,
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      Assetclass: sessionStorage.getItem("Assetclass"),
      isESMA_Flag: sessionStorage.getItem("isESMA_Flag"),
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      openModalPayment: false,
      formLoader1: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isBorrow: false,
      BorrowingBase: "False",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "", // Track the currently sorted column
      sortAscending: true,
    };
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }

  handleSort = (column) => {
    const { sortColumn, sortAscending, tableData } = this.state;

    // Determine the new sort order
    let newSortAscending;
    if (sortColumn === column) {
      newSortAscending = !sortAscending;
    } else {
      // Default to ascending order when sorting a new column
      newSortAscending = true;
    }

    // Custom compare function for sorting
    const compareFunc = (a, b) => {
      const valueA = a[column] ? a[column].toLowerCase() : "";
      const valueB = b[column] ? b[column].toLowerCase() : "";
      if (valueA < valueB) return newSortAscending ? -1 : 1;
      if (valueA > valueB) return newSortAscending ? 1 : -1;
      return 0;
    };

    // Sort the table data based on the selected column and sort order
    const sortedTableData = [...tableData].sort(compareFunc);

    this.setState({
      tableData: sortedTableData,
      sortColumn: column,
      sortAscending: newSortAscending,
    });
  };

  popoverBottomDownload = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={this.DownloadExcel}>Excel</button>
        <hr className="servicer-popover-hr" />
        <button>XML</button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.DownloadCSV}>CSV</button>
      </Popover>
    );
  };

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  DownloadExcel = () => {
    const { tableData } = this.state;
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Annex2_data.xlsx");
  };

  DownloadCSV = () => {
    const { tableData } = this.state;
    const csvData = tableData.map((row) => Object.values(row).join(","));
    const csvHeaders = Object.keys(tableData[0]).join(",");
    const csvContent = `${csvHeaders}\n${csvData.join("\n")}`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Annex2_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Your browser does not support downloading files.");
    }
  };

  handleSearchIconClick = () => {
    this.setState({
      isSearchOpen: true,
    });
  };

  toggleFullScreen = () => {
    this.setState((prevState) => ({
      isFullScreen: !prevState.isFullScreen,
    }));

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  handleClickAnnex = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
    });
    window.location.assign("/investor/Annex_Inv");
  };

  handleClickAnnex12 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
    });
    window.location.assign("/investor/Annex12_Inv");
  };

  handleClickAnnex14 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
    });
    window.location.assign("/investor/Annex14_Inv");
  };
  showNext = () => {
    window.location.assign("/investor/Annex12_Inv");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.General(this.state.DealName)}>Deal</button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        {/* <hr className="servicer-popover-hr" />
<button>Change Period</button> */}
      </Popover>
    );
  };

  ViewEsmaReport = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      loading: true,
      screenloader: true,
    });
    let x = this.state.NextPaymentDate;
    console.log();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;

    data.peer = this.state.peer;
    data.Version = sessionStorage.getItem("version");

    console.log("datatatata", data);
    const APIResponse = await ViewEsmaReport(data);
    console.log("ViewEsmaReport", APIResponse);
    if (APIResponse.status === 200 && APIResponse.data.isSuccess === true) {
      console.log("APIResponse", APIResponse);
      const annexData = Object.keys(APIResponse.data.data)[0];
      console.log("annexData", annexData);
      const annex2Data = APIResponse.data.data[annexData];
      const tableData = annex2Data; ///array of obj

      this.setState({
        MapDataKeys:
          tableData && tableData.length > 0 && tableData[0]
            ? Object.keys(tableData[0])
            : [],
        MapDataValues:
          tableData && tableData.length > 0
            ? tableData.map((item) =>
                Object.keys(item).map((key) => ({ value: item[key] }))
              )
            : [],

        getLoansLoader: false,
        loading: false,
        tableData: annex2Data,
        screenloader: false,
        openModalPayment: false,
        annexData: annexData,
      });
    } else if (
      APIResponse.status === 200 &&
      APIResponse.data.isSuccess === false
    ) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        annexData: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        annexData: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        annexData: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value

    this.setState(
      {
        DealName: newValue,
        isVersionChange: false,
        screenloader: true,
      },
      async () => {
        sessionStorage.setItem("dealname", newValue);
        await this.getDealDetailsByDealName();
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version },
            async () => {
              sessionStorage.setItem("version", "WIP");
              await this.ViewEsmaReport();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            await this.ViewEsmaReport();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              {
                isVersionChange: false,
                publishVersion: Version,
              },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.ViewEsmaReport();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getDealDetailsByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getDealDetailsByDealName(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem("version", APIResponse.data.Versions[0]);
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Versions)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState(
      { NextPaymentDate: newValue, isVersionChange: true },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        await this.getAllVersionsByPaymentDate();

        // if (this.state.isLoansProcessed === "No") {
        //   window.location.assign("/admin/general_recurring");
        // }
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isVersionChange: false }, async () => {
            sessionStorage.setItem("version", "WIP");
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isVersionChange: this.state.isVersionChange });
            await this.ViewEsmaReport();
          } else {
            this.setState({ isVersionChange: false }, async () => {
              await sessionStorage.setItem("version", "WIP");
              await this.ViewEsmaReport();
              this.setState({ checkLoadStatus: false });
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isVersionChange: false,
        screenloader: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, Version: "WIP" },
            async () => {
              await this.ViewEsmaReport();
            }
          );
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call ViewEsmaReport
            this.setState({
              isVersionChange: this.state.isVersionChange,
            });
            this.ViewEsmaReport();
          } else {
            this.setState({ isVersionChange: false }, async () => {
              await sessionStorage.setItem("version", "WIP");
              await this.ViewEsmaReport();
            });
          }
        }
      }
    );
  };

  // DownloadExcel = async () => {
  //   this.setState({ getLoansLoader: true, formLoader1: true, loading: true });
  //   let x = moment(this.state.NextPaymentDate)
  //     .subtract(1, "months")
  //     .format("MM/DD/YYYY")
  //     .toString();
  //   let month = x.slice(0, 2);
  //   let year = x.slice(6, 10);
  //   var data = {};
  //   data.file = `${this.state.DealName}-${month}-${year}.xlsx`;
  //   data.file2 = `${this.state.DealName}-${month}-${year}.xlsx`;

  //   console.log("data", data);
  //   const APIResponse = await DownloadExcel(data);

  //   console.log("DownloadExcel", APIResponse.data);
  //   if (APIResponse.status === 200) {
  //     const blob = new Blob([APIResponse.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const downloadUrl = URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = downloadUrl;
  //     link.setAttribute("download", data.file);
  //     document.body.appendChild(link);
  //     link.click();

  //     this.setState({
  //       getLoansLoader: false,
  //       loading: false,
  //       formLoader1: false,
  //     });
  //   } else {
  //     const message = APIResponse.data.message;
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //     this.setState({
  //       getLoansLoader: false,
  //       loading: false,
  //       formLoader1: false,
  //     });
  //   }
  // };

  componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("isTabChange", false);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    // if (
    //   this.state.isLoansProcessed === "No" ||
    //   sessionStorage.getItem("isESMA_Flag") === "No"
    // ) {
    //   window.location.assign("/admin/general_recurring");
    // }
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isVersionChange: false }, async () => {
        sessionStorage.setItem("version", "WIP");
        await this.ViewEsmaReport();
        this.setState({ checkLoadStatus: false });
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          if (typeof version === "string") {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(
        getDashboardVersions.filter((version) => typeof version === "string")
      );

      if (sessionStorage.getItem("version") !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isVersionChange: this.state.isVersionChange });
        this.ViewEsmaReport();
      } else {
        this.setState({ isVersionChange: false }, async () => {
          sessionStorage.setItem("version", "WIP");
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isVersionChange: this.state.isVersionChange });
    //   this.ViewEsmaReport();
    // } else {
    //   this.setState({ isVersionChange: false });
    //   this.ViewEsmaReport();
    // }
  }
  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) => {
      for (let key in row) {
        if (
          row[key] &&
          row[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }
      return false;
    });

    this.setState({
      searchTerm: event.target.value,
      data: filteredData,
      isDataFound: filteredData.length > 0,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTerm: "",
      data: this.state.tableData, // Reset the data to the original dataset
    });
  };

  filterData = (searchTerm) => {
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) =>
      Object.values(row).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

    return filteredData;
  };

  render() {
    const {
      searchTerm,
      isDataFound,
      tableData,
      MapDataKeys,
      data,
      isSearchOpen,
      sortColumn,
      sortAscending,
    } = this.state;

    const filteredData = searchTerm ? this.filterData(searchTerm) : tableData;
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar activeComponent={"ESMA Reporting"} />
          <div className="content">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingBottom: "1.6rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      {this.state.annexData ? (
                        <h3 className="headerdashboard">
                          {this.state.annexData}
                        </h3>
                      ) : (
                        <h3 className="headerdashboard">Annex</h3>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row1 row14 move-dropdowns-annexs">
                  <div className="recurring_details_new_Reporting">
                    <div>
                      <label className="label">Deal Name </label>
                      {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1-deal"
                          value={this.state?.DealName}
                          onChange={this.handleDealNameChange}
                        >
                          {sortedDeals.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="label">Payment Date </label>
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={this.state?.NextPaymentDate}
                          onChange={this.handlePaymentDateChange}
                        >
                          {this.state.getDashboardDates.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="label">Version </label>
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={
                            this.state.Version === "WIP"
                              ? sessionStorage.getItem("version")
                              : this.state.Version
                          }
                          onChange={this.handleVersionChange}
                        >
                          {this.state.getDashboardVersions?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row1 movement-tabs-reporting">
                  <div>
                    {/* <div className="tablechangebutton"> */}
                    <div className="tablechangebuttonloans">
                      {this.state.annexData ? (
                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex()}
                          className={
                            this.state.activeInsights1 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          {this.state.annexData}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex()}
                          className={
                            this.state.activeInsights1 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Annex
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => this.handleClickAnnex12()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Annex 12
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAnnex14()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Annex 14
                      </button>
                    </div>
                  </div>
                  <div>
                    {" "}
                    {this.state.createStatus === "Update" ? null : (
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom"
                        overlay={this.popoverBottomDownload()}
                        onExited={this.handleOverlayHide}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleButtonClick}
                        >
                          Download
                          {this.state.downArrow ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {/* <div className="row row1">
                  <div className="investor-heading-container">
                    <h1 className="headerdashboard1">
                      {/* {this.state.buttonName} Details */}
                {/* </h1>
                  </div>
                  <div>
                      {" "}
                      {this.state.createStatus === "Update" ? null : (
                        <OverlayTrigger
                          rootClose
                          trigger="click"
                          placement="bottom"
                          overlay={this.popoverBottomDownload()}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            // onClick={() =>this.DownloadConsolidatedPdf()}
                          >
                            Download
                            {this.state.downArrow ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </Button>
                        </OverlayTrigger>
                      )}
                    </div> */}
                {/* </div> */}

                {this.state.screenloader == true ? (
                  <LinearLoader></LinearLoader>
                ) : (
                  <React.Fragment>
                    <div
                      style={{
                        overflow: "auto",
                        borderRadius: "10px",
                        border: "0.5px solid black",
                      }}
                      className="movement-table-reporting-annexs"
                    >
                      {/* <Spreadsheet
data={this.state?.MapDataValues}
title={"Servicer Details"}
DataEditor="false"
columnLabels={MapFieldKeys}
readOnly={true}
/> */}
                      <TableVirtuoso
                        styles={{ overflowAnchor: "none" }}
                        style={{ height: 800 }}
                        data={filteredData}
                        components={TableComponents}
                        fixedHeaderContent={() => (
                          <TableRow>
                            <>
                              <TableCell
                                style={{ background: "white", width: "100px" }}
                              ></TableCell>
                              {MapDataKeys.map((column, index) => (
                                <TableCell
                                  key={index}
                                  style={{
                                    background: "white",
                                    cursor: "pointer",
                                    width: `${column.length * 10}px`,
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() => this.handleSort(column)}
                                >
                                  <div style={{ display: "flex", gap: "6px" }}>
                                    {column}
                                    {sortColumn === column && (
                                      <span>{sortAscending ? " ▲" : " ▼"}</span>
                                    )}
                                  </div>
                                </TableCell>
                              ))}
                            </>
                          </TableRow>
                        )}
                        itemContent={(index, row) => (
                          <>
                            <TableCell
                              style={{ background: "white", width: "100px" }}
                            >
                              {index + 1}
                            </TableCell>
                            {MapDataKeys.map((column, columnIndex) => (
                              <TableCell
                                key={columnIndex}
                                style={{
                                  background: "white",
                                  width: `${column.length * 10}px`,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row[column]}
                              </TableCell>
                            ))}
                          </>
                        )}
                      />
                    </div>
                    <div></div>
                    <ReactModal
                      isOpen={this.state.openModalPayment}
                      contentLabel="Minimal Modal Example"
                      style={{
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "fixed", // Use fixed positioning for the overlay
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                        },
                        content: {
                          position: "absolute",
                          background: "transparent", // Make the modal content transparent
                          border: "none", // Optional: Remove any borders
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          overflow: "hidden",
                        },
                      }}
                    >
                      <React.Fragment>
                        <img
                          src={BeanEater}
                          style={{
                            width: "8vw",
                            height: "9vh",
                            backgroundColor: "transparent",
                          }}
                        />
                        <h3 className="dot-loader">Loading.Please wait</h3>
                      </React.Fragment>
                    </ReactModal>
                    <div id="modal1">
                      <ReactModal
                        isOpen={this.state.isBorrow}
                        onRequestClose={this.onCloseModalBorrow}
                        contentLabel="Minimal Modal Example"
                        style={customStylesautosmallmodalpopupBorrow}
                      >
                        <React.Fragment>
                          <div className="modalPopup">
                            <h3 className="popupheading">
                              Do you want to run Payment Waterfall?
                            </h3>

                            {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                            <div className="modalshiftcontent">
                              <div className="modalsubmit">
                                <div className="submitbuttonbg">
                                  <div className="row">
                                    <div className="row justify-content-start11">
                                      <button
                                        type="button"
                                        className="popupbutton1"
                                        onClick={this.onCloseModalBorrow}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="row justify-content-end11">
                                      <button
                                        type="button"
                                        className="popupbutton1"
                                        onClick={this.checkBorrowBaseNo}
                                      >
                                        No
                                      </button>

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={this.checkBorrowBaseYes}
                                      >
                                        Yes
                                        {/* {this.state.formLoader === true ? (
<CircularProgress
size="25px"
color="primary"
/>
) : (
""
)} */}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      </ReactModal>
                    </div>
                  </React.Fragment>
                )}
                <>
                  <div className="btn_move_Needed">
                    <button className="btn_next" onClick={this.showNext}>
                      Next
                    </button>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Annex_Inv);
