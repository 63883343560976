import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Performance from "./Performance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

export default function PerformanceDropDowns() {
  const options = [
    { label: "CPR", value: "CPR" },
    { label: "M3 CPR", value: "M3 CPR" },
    { label: "Life CPR", value: "Life CPR" },
  ];
  const optionPrePayments = [
    { label: "CPR", value: "CPR" },
    { label: "M3 CPR", value: "M3 CPR" },
    { label: "Life CPR", value: "Life CPR" },
  ];

  const optionsDefaults = [
    { label: "CDR", value: "CDR" },
    { label: "M3 CDR", value: "M3 CDR" },
    { label: "Life CDR", value: "Life CDR" },
  ];

  const optionsLosses = [
    {
      label: "Cumulative Realized Losses",
      value: "Cumulative Realized Losses",
    },
    { label: "Realized Losses", value: "Realized Losses" },
  ];

  const optionsCreditMetrics = [
    {
      label: "WAC",
      value: "WAC",
    },
    { label: "FICO", value: "FICO" },
    { label: "DTI", value: "DTI" },
    { label: "LTV", value: "LTV" },
  ];

  const optionsDelinquencies = [{ label: "Current", value: "Current" }];

  const optionsCashflows = [
    { label: "Interest", value: "Interest" },
    { label: "Principal", value: "Principal" },
  ];

  const [selected, setSelected] = useState([]);
  const [selectedPrePayments, setSelectedPrePayments] = useState([
    {
      label: "CPR",
      value: "CPR",
    },
  ]);
  const [selectedDefaults, setSelectedDefaults] = useState([
    {
      label: "CDR",
      value: "CDR",
    },
  ]);
  const [selectedLosses, setSelectedLosses] = useState([]);
  const [selectedCreditMetrics, setSelectedCreditMetrics] = useState([]);
  const [selectedDelinquencies, setSelectedDelinquencies] = useState([]);
  const [selectedCashflows, setSelectedCashflows] = useState([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState("");

  const toggleDatepicker = () => {
    setShowDatepicker(!showDatepicker);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null); // Reset end date if it's before start date
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const applyDateRange = () => {
    setShowDatepicker(false);
    console.log("endDate", endDate);
  };

  const handlePrepayments = (selectedOptions) => {
    setSelectedPrePayments(selectedOptions);
    console.log("selectedPrePayments", selectedOptions, selectedPrePayments);
  };
  const handledDefaults = (selectedOptions) => {
    setSelectedDefaults(selectedOptions);
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
    }).format(date);
  };
  return (
    <div>
      <div className="align-perf-dropdowns">
        <div className="date-range-picker">
          <h6>Date Range</h6>
          <div className="align-per-tabs">
            <div className="date-input-container">
              <input
                type="text"
                readOnly
                className="date-input"
                onClick={toggleDatepicker}
                placeholder="Select Date Range"
                value={
                  startDate && endDate
                    ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                    : startDate
                    ? `${formatDate(startDate)}`
                    : endDate
                    ? `${formatDate(endDate)}`
                    : ""
                }
              />
              <FaRegCalendarAlt
                className="calendar-icon"
                onClick={toggleDatepicker}
              />
            </div>

            {showDatepicker && (
              <div className="datepicker">
                <span>Select the Date Range</span>
                <div className="datepicker-row" style={{ marginTop: "20px" }}>
                  <label className="datepicker-label">From:</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select Start Date"
                    className="date-picker"
                  />
                </div>
                <div className="datepicker-row">
                  <label className="datepicker-label">To:</label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    showYearDropdown
                    dropdownMode="select"
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select End Date"
                    className="date-picker"
                  />
                </div>
                <div className="button-row">
                  <button className="daterange-btn" onClick={applyDateRange}>
                    Apply
                  </button>
                  <button
                    className="daterange-cancelbtn"
                    onClick={() => setShowDatepicker(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="align-perf-side-dropdowns">
          <div>
            <h6>Prepayments</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionPrePayments}
                value={selectedPrePayments}
                onChange={handlePrepayments}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Defaults</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsDefaults}
                value={selectedDefaults}
                onChange={handledDefaults}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Losses</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsLosses}
                value={selectedLosses}
                onChange={setSelectedLosses}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Credit Metrics</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsCreditMetrics}
                value={selectedCreditMetrics}
                onChange={setSelectedCreditMetrics}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Delinquencies</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsDelinquencies}
                value={selectedDelinquencies}
                onChange={setSelectedDelinquencies}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Cashflows</h6>
            <div className="align-per-tabs">
              <MultiSelect
                options={optionsCashflows}
                value={selectedCashflows}
                onChange={setSelectedCashflows}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
        </div>
      </div>

      <Performance
        selectedPrePayments={selectedPrePayments}
        selectedDefaults={selectedDefaults}
        selectedLosses={selectedLosses}
        selectedCreditMetrics={selectedCreditMetrics}
        selectedDelinquencies={selectedDelinquencies}
        selectedCashflows={selectedCashflows}
        endDate={endDate}
      />
    </div>
  );
}
