import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useSnackbar } from "notistack";
import {
  FilterDataOnLosses,
  FilterDataOnPrePayments,
  FilterDataOnDefaults,
  FilterDataOnCashFlow,
  FilterDataOnCreditMetrics,
  FilterDataOnDelinquency,
} from "../../../../../servies/services";
import NumberComp2 from "../../../../../components/NumberComp2";

// Define transformData function
const transformData = (data, selectedLosses) => {
  const cumulativeLoss = data[0].cumulativeLoss;
  const realizedLoss = data[0].realizedLoss;
  const dates = Object.keys(cumulativeLoss);

  const selectedValues = selectedLosses.map((option) => option.value);

  const transformedData = dates.map((date) => {
    const dataForDate = { date };

    if (selectedValues.includes("Cumulative Realized Losses")) {
      dataForDate["Cumulative Realized Losses"] = cumulativeLoss[date];
    }

    if (selectedValues.includes("Realized Losses")) {
      dataForDate["Realized Losses"] = realizedLoss[date];
    }
    return dataForDate;
  });
  return transformedData;
};

const transformDataPrePayents = (data, selectedPrePayments) => {
  const cpr = data[0].cpr;
  const lifecpr = data[0].lifecpr;
  const m3cpr = data[0].m3cpr;
  const dates = Object.keys(cpr);

  // Extract selected prepayment values
  const selectedValues = selectedPrePayments.map((option) => option.value);

  // Transform data to the required format
  const transformedData = dates.map((date) => {
    const dataForDate = { date };

    if (selectedValues.includes("CPR")) {
      dataForDate["CPR%"] = cpr[date];
    }

    if (selectedValues.includes("Life CPR")) {
      dataForDate["Life CPR%"] = lifecpr[date];
    }

    if (selectedValues.includes("M3 CPR")) {
      dataForDate["M3 CPR%"] = m3cpr[date];
    }

    return dataForDate;
  });

  console.log("transformedData", transformedData);
  return transformedData;
};

const transformDataDefault = (data, selectedDefaults) => {
  const cdr = data[0].cdr;
  const lifecdr = data[0].lifecdr;
  const m3cdr = data[0].m3cdr;
  const dates = Object.keys(cdr);

  const selectedValues = selectedDefaults.map((option) => option.value);

  const transformedData = dates.map((date) => {
    const dataForDate = { date };

    if (selectedValues.includes("CDR")) {
      dataForDate["CDR%"] = cdr[date];
    }

    if (selectedValues.includes("Life CDR")) {
      dataForDate["Life CDR%"] = lifecdr[date];
    }

    if (selectedValues.includes("M3 CDR")) {
      dataForDate["M3 CDR%"] = m3cdr[date];
    }

    return dataForDate;
  });

  return transformedData;
};

const transformCashFLowData = (data, selectedCashflows) => {
  const interestPayment = data[0].interestPayment;
  const principalPayment = data[0].principalPayment;
  const dates = Object.keys(interestPayment);

  const selectedValues = selectedCashflows.map((option) => option.value);
  const transformedData = dates.map((date) => {
    const dataForDate = { date };

    if (selectedValues.includes("Interest")) {
      dataForDate.Interest = interestPayment[date];
    }

    if (selectedValues.includes("Principal")) {
      dataForDate.Principal = principalPayment[date];
    }
    return dataForDate;
  });
  return transformedData;
};

const transformDataCreditMetrics = (data, selectedCreditMetrics) => {
  const DTI = data[0].DTI;
  const FICO = data[0].FICO;
  const LTV = data[0].LTV;
  const WAC = data[0].WAC;
  const dates = Object.keys(DTI);

  const selectedValues = selectedCreditMetrics.map((option) => option.value);
  const transformedData = dates.map((date) => {
    const dataForDate = { date };

    if (selectedValues.includes("DTI")) {
      dataForDate.DTI = DTI[date];
    }

    if (selectedValues.includes("FICO")) {
      dataForDate.FICO = FICO[date];
    }

    if (selectedValues.includes("LTV")) {
      dataForDate.LTV = LTV[date];
    }

    if (selectedValues.includes("WAC")) {
      dataForDate.WAC = WAC[date];
    }
    return dataForDate;
  });
  return transformedData;
};

const transformDataDelinquency = (data, selectedDelinquencies) => {
  const current = data[0].currentPrincipalBalance;
  const dates = Object.keys(current);

  const selectedValues = selectedDelinquencies.map((option) => option.value);
  const transformedData = dates.map((date) => {
    const dataForDate = { date };

    if (selectedValues.includes("Current")) {
      dataForDate.Current = current[date];
    }
    return dataForDate;
  });
  return transformedData;
};

const Performance = ({
  selectedPrePayments,
  selectedDefaults,
  selectedLosses,
  selectedCreditMetrics,
  selectedDelinquencies,
  selectedCashflows,
  endDate,
}) => {
  const [getLoansLoader, setGetLoansLoader] = useState(false);
  const [tableDataLosses, setTableDataLosses] = useState([]);
  const [tableDataPrepayments, setTableDataPrepayments] = useState([]);
  const [tableDataDefaults, setTableDataDefaults] = useState([]);
  const [tableDataCashFlow, setTableDataCashFlow] = useState([]);
  const [tableDataCreditMetrics, setTableDataCreditMetrics] = useState([]);
  const [tableDataDelinquency, setTableDataDelinquency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [endDates, setEndDates] = useState("01-01-2022");
  const { enqueueSnackbar } = useSnackbar();

  const filterDataOnLosses = async () => {
    const data = {
      dealName: "Test-F",
      date: endDates,
      assetClass: "",
      issuer: "Issuer",
    };

    console.log("datata", data);
    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnLosses(data);

      console.log("APIResponse", APIResponse);

      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          ...item,
          cumulativeLoss: JSON.parse(item.cumulativeLoss),
          realizedLoss: JSON.parse(item.realizedLoss),
        }));
        const transformedData = transformData(parsedResult, selectedLosses);

        const uniqueDates = Array.from(
          new Set(transformedData.map((item) => item.date))
        ).sort((a, b) => new Date("1-" + a) - new Date("1-" + b));

        setDates(uniqueDates);
        setTableDataLosses(transformedData);
        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const filterDataOnCreditMetrics = async () => {
    const data = {
      dealName: "Test-F",
      date: endDates,
      assetClass: "",
      issuer: "Issuer",
    };
    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnCreditMetrics(data);
      console.log("APIResponsecredit", APIResponse);
      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          ...item,
          DTI: JSON.parse(item.DTI),
          FICO: JSON.parse(item.FICO),
          LTV: JSON.parse(item.LTV),
          WAC: JSON.parse(item.WAC),
        }));
        const transformedData = transformDataCreditMetrics(
          parsedResult,
          selectedCreditMetrics
        );
        setTableDataCreditMetrics(transformedData);
        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const filterDataOnDelinquency = async () => {
    const data = {
      dealName: "Test-F",
      date: endDates,
      assetClass: "",
      issuer: "Issuer",
      accountStatus: "Current",
    };

    console.log("datata", data);
    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnDelinquency(data);
      console.log("APIResponsedelinquency", APIResponse);

      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          ...item,
          currentPrincipalBalance: JSON.parse(item.currentPrincipalBalance),
        }));

        const transformedData = transformDataDelinquency(
          parsedResult,
          selectedDelinquencies
        );

        console.log({ transformedData });

        const uniqueDates = Array.from(
          new Set(transformedData.map((item) => item.date))
        ).sort((a, b) => new Date("1-" + a) - new Date("1-" + b));

        setDates(uniqueDates);
        setTableDataDelinquency(transformedData);
        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const filterDataOnPrePayments = async () => {
    const data = {
      dealName: "Test-F",
      date: endDates,
      assetClass: "",
      issuer: "Issuer",
    };

    console.log("datata", data);
    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnPrePayments(data);
      console.log("APIResponse", APIResponse);

      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          ...item,
          // Assuming the structure of the data is similar and requires parsing
          cpr: JSON.parse(item.cpr), // Adjust this line based on the actual data structure
          lifecpr: JSON.parse(item.lifecpr), // Adjust this line based on the actual data structure
          m3cpr: JSON.parse(item.m3cpr), // Adjust this line based on the actual data structure
        }));

        console.log({ parsedResult });

        // Transform data to the required format
        const transformedData = transformDataPrePayents(
          parsedResult,
          selectedPrePayments
        );

        console.log({ transformedData });

        const uniqueDates = Array.from(
          new Set(transformedData.map((item) => item.date))
        ).sort((a, b) => new Date("1-" + a) - new Date("1-" + b));

        setDates(uniqueDates);
        setTableDataPrepayments(transformedData);
        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const filterDataOnDefaults = async () => {
    const data = {
      dealName: "Test-F",
      date: endDates,
      assetClass: "",
      issuer: "Issuer",
    };

    console.log("datata", data);
    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnDefaults(data);
      console.log("APIResponsedefault", APIResponse);
      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          ...item,
          cdr: JSON.parse(item.cdr),
          lifecdr: JSON.parse(item.lifecdr),
          m3cdr: JSON.parse(item.m3cdr),
        }));
        const transformedData = transformDataDefault(
          parsedResult,
          selectedDefaults
        );

        console.log({ transformedData });
        setTableDataDefaults(transformedData);
        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const filterDataOnCashFlow = async () => {
    const data = {
      dealName: "Test-F",
      date: endDates,
      assetClass: "",
      issuer: "Issuer",
    };

    console.log("datata", data);
    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await FilterDataOnCashFlow(data);
      console.log("APIResponse", APIResponse);
      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          ...item,
          interestPayment: JSON.parse(item.interestPayment),
          principalPayment: JSON.parse(item.principalPayment),
        }));
        const transformedData = transformCashFLowData(
          parsedResult,
          selectedCashflows
        );
        setTableDataCashFlow(transformedData);
        setGetLoansLoader(false);
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    if (selectedPrePayments.length > 0) {
      filterDataOnPrePayments();
    }
  }, [selectedPrePayments, endDates]);

  useEffect(() => {
    if (selectedDefaults.length > 0) {
      filterDataOnDefaults();
    }
  }, [selectedDefaults, endDates]);

  useEffect(() => {
    if (selectedLosses.length > 0) {
      filterDataOnLosses();
    }
  }, [selectedLosses, endDates]);

  useEffect(() => {
    if (selectedCashflows.length > 0) {
      filterDataOnCashFlow();
    }
  }, [selectedCashflows, endDates]);

  useEffect(() => {
    if (selectedCreditMetrics.length > 0) {
      filterDataOnCreditMetrics();
    }
  }, [selectedCreditMetrics, endDates]);

  useEffect(() => {
    if (selectedDelinquencies.length > 0) {
      filterDataOnDelinquency();
    }
  }, [selectedDelinquencies, endDates]);

  useEffect(() => {
    if (endDate) {
      const day = String(endDate.getDate()).padStart(2, "0");
      const month = String(endDate.getMonth() + 1).padStart(2, "0");
      const year = endDate.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      console.log("formattedDate", formattedDate);
      setEndDates(formattedDate);
    }
  }, [endDate]);

  const columns = [
    {
      title: "",
      dataIndex: "metric",
      key: "metric",
      width: 200,
    },
    ...dates.map((date) => ({
      title: date,
      dataIndex: date,
      key: date,
      width: 200,
      render: (value) => <NumberComp2 value={value} />,
    })),
  ];

  const constructMainTableData = () => {
    const tableData = [
      {
        key: "1",
        metric: "Prepayments",
        isExpandable: selectedPrePayments.length > 0,
      },
      {
        key: "2",
        metric: "Default",
        isExpandable: selectedDefaults.length > 0,
      },
      { key: "3", metric: "Losses", isExpandable: selectedLosses.length > 0 },
      {
        key: "4",
        metric: "Credit Metrics",
        isExpandable: selectedCreditMetrics.length > 0,
      },
      {
        key: "5",
        metric: "Delinquencies",
        isExpandable: selectedDelinquencies.length > 0,
      },
      {
        key: "6",
        metric: "Cashflows",
        isExpandable: selectedCashflows.length > 0,
      },
    ];

    // Filter out non-expandable items
    return tableData.filter((item) => item.isExpandable);
  };

  const mainTableData = constructMainTableData();

  const createExpandableData = (tableDataPrepayments) => {
    // Extract all unique metrics from the first item in the array
    const metrics = Object.keys(tableDataPrepayments[0] || {}).filter(
      (key) => key !== "date"
    );

    // Generate expandable data for each metric
    const expandableData = metrics.map((metric, index) => ({
      key: `${index + 1}-expand`,
      metric,
      ...Object.fromEntries(
        tableDataPrepayments.map((item) => [item.date, item[metric]]) // Handle missing metrics
      ),
    }));
    return expandableData;
  };

  // Generate expandable data based on tableDataPrepayments
  const expandablePrepaymentData = createExpandableData(tableDataPrepayments);
  const expandeDefaultData = createExpandableData(tableDataDefaults);
  const expandLossesData = createExpandableData(tableDataLosses);
  const expandCashFlowData = createExpandableData(tableDataCashFlow);
  const expandCreditMetrics = createExpandableData(tableDataCreditMetrics);
  const expandDelinquency = createExpandableData(tableDataDelinquency);

  const expandableRowRender = (record) => {
    console.log("metric", record, mainTableData);
    if (record.metric === "Prepayments") {
      return (
        <Table
          columns={columns}
          dataSource={expandablePrepaymentData}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      );
    }
    if (record.metric === "Default") {
      return (
        <Table
          columns={columns}
          dataSource={expandeDefaultData}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      );
    }
    if (record.metric === "Losses") {
      return (
        <Table
          columns={columns}
          dataSource={expandLossesData}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      );
    }
    if (record.metric === "Credit Metrics") {
      return (
        <Table
          columns={columns}
          dataSource={expandCreditMetrics}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      );
    }
    if (record.metric === "Delinquencies") {
      return (
        <Table
          columns={columns}
          dataSource={expandDelinquency}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      );
    }
    if (record.metric === "Cashflows") {
      return (
        <Table
          columns={columns}
          dataSource={expandCashFlowData}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      );
    }
    return null;
  };

  return (
    <div>
      <Table
        className="performance-table"
        columns={columns}
        dataSource={mainTableData}
        pagination={false}
        bordered
        loading={loading}
        expandable={{
          expandedRowRender: expandableRowRender,
          defaultExpandAllRows: true,
          rowExpandable: (record) => record.isExpandable,
        }}
        rowKey="key"
      />
    </div>
  );
};

export default Performance;
