/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../../../components/loader";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  generalOnPageReload,
  DealRecurring,
  CalculatePayments,
  FilterDataOnPrePayments,
  FilterDataOnDefaults,
  // FilterDataOnLosses,
  FilterDataOnCashFlow,
} from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import Iframe from "react-iframe";
import Plot from "react-plotly.js";
import NumberComp2 from "../../../../../components/NumberComp2";
// import Performance from "./Performance.js";

class StandardDemo_investor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
      screenloader: false,
      pooldetails: {},
      bdbUrl: "",
      formData1: {
        "First Payment Date": "",
      },
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickStandard = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
    });
    window.location.assign("/investor/standard_investor");
  };

  handleClickOnDemand = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
      investorTab3: false,
    });
    window.location.assign("/investor/ondemand_investor");
  };

  handleClickOnStdDemo = () => {
    this.setState({
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
    });
    window.location.assign("/investor/StdDemo_investor");
  };

  bdbapi = async () => {
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(8, 10);
    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-US", { month: "short" });
    }

    const getMonth = getMonthName(month); // Jan

    let poolidold = JSON.stringify({
      "Deal Name": {
        type: "in",
        value: [this.state.DealName],
      },
      MonthandYear: {
        type: "in",
        value: [`${getMonth}-${year}`],
      },
    });
    console.log("poolidold", poolidold);
    let mailid = this.state.Email;
    let password = this.state.Password;
    const res = await axios.get(
      process.env.react_app_base_url +
        "backendapi_wsfs_bdb/IA/reportlink?" +
        "mailid=" +
        mailid +
        "&password=" +
        password +
        "&type=Standard"
    );
    if (res.status == 204) {
      this.setState({ bdbUrl: "UrlBdbNew", bdb_loader: false });
    } else {
      let UrlBdbNew =
        "https://analytics.demo-iaedge.intainabs.com/home/#/opendocument?data=" +
        res.data;
      console.log("111", res.data);
      this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false });
    }
  };

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },

        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },

        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "white !important",
            backgroundClip: "padding-box",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "30px !important",
            position: "relative",
            zIndex: 1000,
            "&::after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(1, 142, 130, 0.1) !important",
              zIndex: -1,
            },
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            scrollbarWidth: "thin",
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
        },
      },
    });

  FilterDataOnPrePayments = async () => {
    var data = {};
    data.dealName = "Test-F";
    data.date = "01-01-2022";

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await FilterDataOnPrePayments(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data.result,
        loading: false,
      });
      // this.setState(
      //   {
      //     getLoansLoader: false,
      //     tableData: APIResponse.data,
      //     // manipulatedTableData: APIResponse.data,
      //     // IssuerArrays: [
      //     //   ...new Set(APIResponse.data.map((item) => item["Issuer"])),
      //     // ],
      //     // TrusteeArrays: [
      //     //   ...new Set(APIResponse.data.map((item) => item["Trustee"])),
      //     // ],
      //     // AssetArrays: [
      //     //   ...new Set(APIResponse.data.map((item) => item["Asset Class"])),
      //     // ],
      //     loading: false,
      //   },
      //   () => {}
      // );
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      // variant: "info",
      // autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  FilterDataOnDefaults = async () => {
    var data = {};
    data.dealName = "Test-F";
    data.date = "01-01-2022";

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData1: [], loading: true });
    const APIResponse = await FilterDataOnDefaults(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData1: APIResponse.data.result,
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // FilterDataOnLosses = async () => {
  //   var data = {};
  //   data.dealName = "Test-F";
  //   data.date = "01-01-2022";

  //   console.log("datata", data);
  //   this.setState({ getLoansLoader: true, tableData2: [], loading: true });
  //   const APIResponse = await FilterDataOnLosses(data);
  //   console.log("APIResponse", APIResponse);

  //   if (APIResponse.status === 200) {
  //     // Parse the cumulativeLoss field
  //     const parsedResult = APIResponse.data.result.map((item) => {
  //       return {
  //         ...item,
  //         cumulativeLoss: JSON.parse(item.cumulativeLoss),
  //       };
  //     });

  //     // Transform the parsedResult to fit the table format
  //     const transformedData = this.transformData(parsedResult);

  //     this.setState({
  //       getLoansLoader: false,
  //       tableData2: transformedData,
  //       loading: false,
  //     });
  //   } else {
  //     this.setState({ getLoansLoader: false, loading: false });
  //     const message = "Something went wrong";
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  // };

  transformData = (data) => {
    const cumulativeLoss = data[0].cumulativeLoss;
    const dates = Object.keys(cumulativeLoss);

    // Transform data to the required format
    const transformedData = dates.map((date) => ({
      date,
      CPR: cumulativeLoss[date],
      CDR: data[0].realizedLoss, // Assuming realizedLoss is the same for all dates
    }));

    return transformedData;
  };

  FilterDataOnCashFlow = async () => {
    var data = {};
    data.dealName = "Test-F";
    data.date = "01-01-2022";

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData3: [], loading: true });
    const APIResponse = await FilterDataOnCashFlow(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData3: APIResponse.data.result,
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    // this.bdbapi();
    await this.FilterDataOnPrePayments();
    await this.FilterDataOnDefaults();
    // await this.FilterDataOnLosses();
    await this.FilterDataOnCashFlow();
  }

  render() {
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };
    const columns = [
      {
        name: "cpr",
        label: "CPR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
      {
        name: "lifecpr",
        label: "Life CPR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
      {
        name: "m3cpr",
        label: "M3 CPR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
    ];
    const columns1 = [
      {
        name: "cdr",
        label: "CDR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
      {
        name: "lifecdr",
        label: "Life CDR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
      {
        name: "m3cdr",
        label: "M3 CDR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
    ];

    const columns2 = [
      {
        name: "date",
        label: "Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <NumberComp2 value={value}></NumberComp2>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "CPR",
        label: "CPR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <NumberComp2 value={value}></NumberComp2>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "CDR",
        label: "CDR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <NumberComp2 value={value}></NumberComp2>
              </React.Fragment>
            );
          },
        },
      },
    ];
    const columns3 = [
      {
        name: "principalPayment",
        label: "Principal Payment",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
      {
        name: "interestPayment",
        label: "Interest Payment",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "1.3rem",
                  textAlign: "center",
                }}
              >
                <NumberComp2 value={value}></NumberComp2>
              </div>
            );
          },
        },
      },
    ];
    const dates = [
      "Jan-22",
      "Feb-22",
      "Mar-22",
      "Apr-22",
      "May-22",
      "Jun-22",
      "Jul-22",
      "Aug-22",
      "Sep-22",
      "Oct-22",
      "Nov-22",
      "Dec-22",
    ];

    const dtiValues = [40, 61, 48, 45, 50, 52, 62, 53, 51, 50, 49, 47];
    const ltvValues = [63, 48, 63, 62, 61, 60, 53, 44, 44, 44, 59, 58];

    return (
      <React.Fragment>
        <div className="">
          <Sidebar1 activeComponent={"InvestorPortfolioAnalytics"} />
          <div className="content-bdb">
            <div className="header">
              <Header></Header>
            </div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "1.5rem",
                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                        <h3 className="headerdashboard">Portfolio Analytics</h3>
                      </div>
                    </div>
                    <div className="col-7 col-sm-6 col-md-7 hellocard">
                      <button
                        type="button"
                        onClick={() => this.handleClickStandard()}
                        className={
                          this.state.investorTab1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        Standard
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickOnDemand()}
                        className={
                          this.state.investorTab2 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        On-Demand
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickOnStdDemo()}
                        className={
                          this.state.investorTab3 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        Standard SampleDemo
                      </button>
                    </div>
                  </div>
                  {/* <React.Fragment>
              <Iframe
                // allowtransparency="true"
                      // frameborder="0"
                      // style="background: #FFFFFF;"
                      url={this.state.bdbUrl}
                      // url={pdffile}
                      width="100%"
                      height="665px"
                      id="myId"
                      className="bdb-charts"
                      display="initial"
                      position="relative"
              />
              
            </React.Fragment> */}

                  <div className="scrollable-content workbench-table-container">
                    {/* <iframe
                      // allowtransparency="true"
                      // frameborder="0"
                      // style="background: #FFFFFF;"
                      src={this.state.bdbUrl}
                      // url={pdffile}
                      width="100%"
                      height="100%"
                      id="myId"
                      className="bdb-charts iframe-no-horizontal-scroll"
                      // display="block"
                      // position="absolute"
                      // overflow="hidden"
                    ></iframe> */}
                    <div className="row row14">
                      <div className="general-InvContainer">
                        <div>
                          <label className="label">AssetClass</label>
                          <select
                            className="input-select-general-new1"
                            value={this.state.Issuer}
                            onChange={(event) =>
                              this.handleSelectChange(event, "Issuer")
                            }
                          >
                            <option value="">Select any one</option>
                            {/* {this.state.IssuerArrays.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))} */}
                          </select>
                        </div>
                      </div>
                      <div className="general-InvContainer">
                        <div>
                          <label className="label">Issuer</label>
                          <select
                            className="input-select-general-new1"
                            value={this.state.Trustee}
                            onChange={(event) =>
                              this.handleSelectChange(event, "Trustee")
                            }
                          >
                            <option value="">Select any one</option>
                            {/* {this.state.TrusteeArrays.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))} */}
                          </select>
                        </div>
                      </div>
                      <div className="general-InvContainer gap-InvContainer">
                        <div>
                          <label className="label">Deals</label>
                          <select
                            className="input-select-general-new1"
                            value={this.state["Asset Class"]}
                            onChange={(event) =>
                              this.handleSelectChange(event, "Asset Class")
                            }
                          >
                            <option value="">Select any one</option>
                            {/* {this.state.AssetArrays.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))} */}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="chart-container">
                      <div className="plot-borders">
                        <Plot
                          className="plot-inside"
                          data={[
                            {
                              values: [
                                837138531.0, 238476126.0, 729936357.0,
                                134867646.0,
                              ],
                              labels: ["1 Month", "6 Month", "NA", "None"],
                              type: "pie",
                            },
                          ]}
                          layout={{
                            width: 550,
                            title: {
                              text: "Prime/Sub-prime",
                              font: {
                                size: 24, // You can adjust the size as needed
                                weight: "bold",
                              },
                            },
                          }}
                        />
                      </div>
                      <div className="plot-borders">
                        <Plot
                          className="plot-inside"
                          data={[
                            {
                              x: [
                                "Jan-22",
                                "Feb-22",
                                "Mar-22",
                                "Apr-22",
                                "May-22",
                                "Jun-22",
                                "Jul-22",
                                "Aug-22",
                                "Sep-22",
                                "Oct-22",
                                "Nov-22",
                                "Dec-22",
                              ],
                              y: [
                                new Date(2022, 1, 25),
                                67.0,
                                37.0,
                                722.0,
                                1.0,
                                99.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 2, 25),
                                67.0,
                                50.0,
                                721.0,
                                1.0,
                                99.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 3, 25),
                                67.0,
                                44.0,
                                721.0,
                                1.0,
                                99.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 4, 25),
                                67.0,
                                25.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 5, 25),
                                67.0,
                                50.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 6, 27),
                                66.0,
                                51.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 7, 25),
                                66.0,
                                50.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 8, 25),
                                66.0,
                                52.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 9, 26),
                                66.0,
                                51.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 10, 25),
                                66.0,
                                50.0,
                                721.0,
                                3.0,
                                97.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 11, 25),
                                66.0,
                                51.0,
                                721.0,
                                2.0,
                                98.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                                new Date(2022, 12, 26),
                                66.0,
                                49.0,
                                721.0,
                                3.0,
                                97.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                0.0,
                                1.0,
                              ],

                              type: "scatter",
                              mode: "lines+markers",
                              marker: { color: "blue" },
                            },
                          ]}
                          layout={{
                            width: 550,
                            title: {
                              text: "Weighted Average FICO",
                              font: {
                                size: 24, // You can adjust the size as needed
                                weight: "bold",
                              },
                            },
                            xaxis: {
                              title: {
                                text: "Month",
                                font: {
                                  size: 16, // Adjust the size as needed
                                  weight: "bold",
                                },
                              },
                            },
                            yaxis: {
                              title: {
                                text: "Values",
                                font: {
                                  size: 16, // Adjust the size as needed
                                  weight: "bold",
                                },
                              },
                            },
                          }}
                        />
                      </div>

                      <div className="plot-borders-bar-scatter">
                        <Plot
                          data={[
                            {
                              x: dates,
                              y: dtiValues,
                              type: "bar",
                              name: "Wt Avg Debt to Income",
                              marker: { color: "blue" },
                            },
                            {
                              x: dates,
                              y: ltvValues,
                              type: "scatter",
                              mode: "lines+markers",
                              name: "Wt Avg LTV",
                              line: { color: "red" },
                            },
                          ]}
                          layout={{
                            title: "DTI and LTV",
                            yaxis: {
                              title: "Wt Avg Debt to Income",
                              titlefont: { size: 18, color: "black" },
                              tickfont: { size: 14, color: "black" },
                              range: [35, 65],
                            },
                            yaxis2: {
                              title: "Wt Avg LTV",
                              titlefont: { size: 18, color: "black" },
                              tickfont: { size: 14, color: "black" },
                              overlaying: "y",
                              side: "right",
                              range: [35, 65],
                            },
                            xaxis: {
                              title: "Month",
                              titlefont: { size: 18, color: "black" },
                              tickfont: { size: 14, color: "black" },
                            },
                            barmode: "group",
                          }}
                          config={{
                            responsive: true,
                          }}
                          useResizeHandler
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>

                    <div className="workbench-table-container bdb-Ondemand">
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          // title={'Dashboard'}
                          data={this.state.tableData}
                          columns={columns}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>

                    <div className="workbench-table-container bdb-Ondemand">
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          // title={'Dashboard'}
                          data={this.state.tableData1}
                          columns={columns1}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>

                    <div className="workbench-table-container bdb-Ondemand">
                      {/* <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          // title={'Dashboard'}
                          data={this.state.tableData2}
                          columns={columns2}
                          options={options}
                        />
                      </MuiThemeProvider> */}

                      {/* <Performance /> */}
                    </div>

                    <div className="workbench-table-container bdb-Ondemand">
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          // title={'Dashboard'}
                          data={this.state.tableData3}
                          columns={columns3}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(StandardDemo_investor);
