/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
// import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
// import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import Popover from "react-bootstrap/Popover";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import {
// MuiPickersUtilsProvider,
// KeyboardTimePicker,
// KeyboardDatePicker,
// } from "@material-ui/pickers";
// import {
// generalOnPageReload,
// DealRecurring,
// CalculatePayments,
// } from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// import { investorGetalldeals } from "../../../../../servies/services";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../../components/loader";
import axios from "axios";
import Iframe from "react-iframe";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedRow: null,
      // token: sessionStorage.getItem("token"),
      // loading: false,
      // getLoansLoader: false,
      // openPopup: false,
      // searchText: "",
      // tableData: [
      //   {
      //     Tranche: "A1 Notes",
      //     OriginalBalance: "$109,770,000",
      //     CurrentBalance: "$109,770,000",
      //     CreditEnhancement: "-",
      //     Ratings: "-",
      //     Factor: "0.5",
      //     Coupon: "3.32%",
      //     WritedownAmount: "0",
      //     Percentage: "0.000%",
      //   },
      // ],
      // DealName: sessionStorage.getItem("dealname"),
      // NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      // LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      // TableName: "General",
      // peer: sessionStorage.getItem("peer"),
      // peers: JSON.parse(sessionStorage.getItem("peers")),
      // rowsSelected: null,
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      screenloader: false,
      // pooldetails: {},
      // peer: sessionStorage.getItem("peer"),
      loading: false,
      getLoansLoader: false,
      screenloader: false,
      // CurrentPaymentDate: "",
      // LastPaymentDate: "",
      // anchorEl: null,
      // servicerValues: [],
      // isDateSelected: null,
      // reorderchart: true,
      // barchart: false,
      bdbUrl: "",
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  handleClickSummary = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
    });
    window.location.assign("/investor/Summary_investor");
  };

  handleClickStrat = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
    });
    window.location.assign("/investor/Strats_investor");
  };
  handleClickPerformance = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
    });
    window.location.assign("/investor/Performance_investor");
  };

  bdbapi = async () => {
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(8, 10);
    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-US", { month: "short" });
    }

    const getMonth = getMonthName(month); // Jan

    let poolidold = JSON.stringify({
      "Deal Name": {
        type: "in",
        value: [this.state.DealName],
      },
      MonthandYear: {
        type: "in",
        value: [`${getMonth}-${year}`],
      },
    });
    console.log("poolidold", poolidold);
    let mailid = this.state.Email;
    let password = this.state.Password;
    const res = await axios.get(
      process.env.react_app_base_url +
        "backendapi_wsfs_bdb/IA/reportlink?" +
        "mailid=" +
        mailid+
      
      "&password=" +
      password+
     
      "&type=Deal Analytics"
    );
    if (res.status == 204) {
      this.setState({ bdbUrl: "UrlBdbNew", bdb_loader: false });
    } else {
      let UrlBdbNew =
"https://analytics.demo-iaedge.intainabs.com/home/#/opendocument?data="+ res.data ;
      console.log("111",res.data);
        this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false   });
      
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    this.bdbapi();
  }

  render() {
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"InvestorDealAnalytics"} />
          <div className="content-bdb">
            <div className="header">
              <Header></Header>
            </div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div className="row1">
                    <div>
                      <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                        <h3 className="headerdashboard">Deal Analytics</h3>
                      </div>
                    </div>
                  </div>

                  {/* <React.Fragment>
                    <Iframe
                     // allowtransparency="true"
                      // frameborder="0"
                      // style="background: #FFFFFF;"
                      url={this.state.bdbUrl}
                      // url={pdffile}
                      width="100%"
                      height="665px"
                      id="myId"
                      className="bdb-charts"
                      display="initial"
                      position="relative"
                    />
                  </React.Fragment> */}
                  <div className="BdbChart">
                    <iframe
                      // allowtransparency="true"
                      // frameborder="0"
                      // style="background: #FFFFFF;"
                      src={this.state.bdbUrl}
                      // url={pdffile}
                      width="100%"
                      height="100%"
                      id="myId"
                      className="bdb-charts iframe-no-horizontal-scroll"
                      // display="block"
                      // position="absolute"
                      // overflow="hidden"
                    
                      ></iframe>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Summary);
