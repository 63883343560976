/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Header from "../../../../../components/header/index.js";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../../../components/loader/index.js";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  GetDataByDefaultValue,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
  GetDataByTableChartData,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import Iframe from "react-iframe";
import NumberComp2 from "../../../../../components/NumberComp2.js";
import SummaryChart from "../Summary/SummaryChart.js";
import DataDisplay from "./DataDisplay.js";
import PortfolioDash from "./PortfolioDash.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { format } from "date-fns";
import "./PortfolioStandard.css";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

class PortfolioStandard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      finalData: {},
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      AssetClass: [],
      assetClassOptions: [],
      transacionOptions: [],
      selectedAssetClass: [], // Selected option for react-select
      selectedTransaction: [], // Selected option for react-select
      IssuerGroup: [],
      issuerOptions: [], // New state for Issuer options
      selectedIssuer: [], // New state for selected Issuers
      dealsOptions: [], // New state for Deals options
      selectedDeals: [],
      showDatepicker: false,
      showApplyChange: false,
      startDate: new Date("2022-01-01"), // Setting startDate to May 1, 2024
      endDate: new Date("2022-07-25"),
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
      screenloader: false,
      pooldetails: {},
      bdbUrl: "",
      formData1: {
        "First Payment Date": "",
      },
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTransactionChange = this.handleTransactionChange.bind(this);
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickStandard = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
    });
    window.location.assign("/investor/standardAnalytics");
  };

  handleClickOnDemand = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
    });
    window.location.assign("/investor/ondemandAnalytics");
  };

  toggleDatepicker = () => {
    this.setState((prevState) => ({
      showDatepicker: !prevState.showDatepicker,
    }));
  };

  handleStartDateChange = (date) => {
    this.setState((prevState) => ({
      startDate: date,
      endDate:
        prevState.endDate && date > prevState.endDate
          ? null
          : prevState.endDate,
    }));
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  toggleDatepicker = () => {
    this.setState((prevState) => ({
      showDatepicker: !prevState.showDatepicker,
    }));
  };

  applyDateRange = () => {
    this.setState((prevState) => ({
      showApplyChange: !prevState.showApplyChange,
    }));
    console.log("endDate", this.state.endDate);
  };

  formatDate = (date) => {
    try {
      if (!date || isNaN(new Date(date))) return "";
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(date));
    } catch (error) {
      console.error("Error formatting date:", error, date);
      return "";
    }
  };

  formatDateForData = (date) => {
    try {
      if (!date || isNaN(new Date(date))) return "";
      return format(new Date(date), "dd-MM-yyyy");
    } catch (error) {
      console.error("Error formatting date for data:", error, date);
      return "";
    }
  };

  handleSelectChange(selectedOption) {
    // console.log("Updating selectedAssetClass:", selectedOption); // Log the new value
    this.setState({ selectedAssetClass: selectedOption });
  }
  handleTransactionChange(selectedOption) {
    this.setState({ selectedTransaction: selectedOption });
  }

  handleIssuerChange = (selectedOptions) => {
    this.setState({ selectedIssuer: selectedOptions });
  };

  handleDealsChange = (selectedOptions) => {
    this.setState({ selectedDeals: selectedOptions });
  };

  GetDataByDefaultValue = async () => {
    var data = {};
    data.UserId = sessionStorage.getItem("user_id");
    data.UserName = sessionStorage.getItem("user_name");

    console.log("datata", data);
    this.setState({ screenloader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByDefaultValue(data);
    // console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const assetClassArray = APIResponse.data.result.asset_class;
      const TransactionArray = APIResponse.data.result.transaction_type;
      const assetClassOptions = assetClassArray.map((option) => ({
        value: option,
        label: option,
      }));
      const transacionOptions = TransactionArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all asset classes as selected by default
      const allAssetClassesSelected = assetClassOptions;
      const allTransactionsSelected = transacionOptions;

      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all issuers as selected by default
      const allIssuersSelected = issuerOptions;

      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      // Set all deals as selected by default
      const allDealsSelected = dealsOptions; // Include all deals

      this.setState({
        getLoansLoader: false,
        AssetClass: assetClassArray,
        assetClassOptions: assetClassOptions,
        transacionOptions: transacionOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        selectedTransaction: allTransactionsSelected, // Set all asset classes as selected
        selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateAsset = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");

    // Ensure selectedAssetClass is an array before using map
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    data.asset_class = selectedAssetClass.map((item) => item.value);
    // console.log("Data sent to GetDataByUpdateAsset:", data);

    // Set loading states before making the API call
    this.setState({ getLoansLoader: true, tableData: [], loading: true });

    const APIResponse = await GetDataByUpdateAsset(data);
    // console.log("APIResponse from GetDataByUpdateAsset:", APIResponse);

    if (APIResponse.status === 200) {
      // Check if the response contains issuer data
      let issuerArray = APIResponse.data.result.issuer_group || [];

      // Filter out any null or undefined values
      issuerArray = issuerArray.filter(
        (option) => option !== null && option !== undefined
      );

      // Map issuerArray to create options for the dropdown
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Check if selectedAssetClass is empty and set selectedIssuer accordingly
      const shouldClearIssuer = selectedAssetClass.length === 0;

      // Update the state with the issuer options and reset selectedIssuer if needed
      this.setState({
        getLoansLoader: false,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions,
        selectedIssuer: shouldClearIssuer ? [] : this.state.selectedIssuer, // Clear selectedIssuer if no asset class selected
        loading: false,
      });
    } else {
      // Handle the error case and reset loading states
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateIssuer = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    data.issuer_group = selectedIssuer.map((item) => item.value);
    console.log("datata", data);
    this.setState({ screenloader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateIssuer(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      this.setState({
        getLoansLoader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        // IssuerGroup: issuerArray,
        // issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        selectedDeals: dealsArray.length > 0 ? this.state.selectedDeals : [],
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        // selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
        screenloader: false,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        screenloader: false,
        loading: false,
      });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByTableChartData = async () => {
    const { selectedDeals, Deals, startDate, endDate } = this.state;

    console.log("selectedDeals", selectedDeals);

    // Map selected deals back to their original structure
    const selectedDealsOriginal = selectedDeals
      .map((selectedDeal) => {
        const originalDeal = Deals.find(
          (deal) => Object.keys(deal)[0] === selectedDeal.value
        );
        return originalDeal || null; // Ensure to return null if not found
      })
      .filter((deal) => deal !== null); // Remove any null entries

    var data = {};
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    const formattedStartDate = this.formatDateForData(startDate);
    const formattedEndDate = this.formatDateForData(endDate);
    // console.log("formattedStartDate", formattedStartDate, formattedEndDate);

    data.asset_class = selectedAssetClass.map((item) => item.value);
    data.issuer_group = selectedIssuer.map((item) => item.value);
    data.deal_type =
      selectedDealsOriginal.length > 0 ? selectedDealsOriginal : [];
    // data.start_date = "01-05-2024";
    // data.end_date = "25-07-2024";
    data.start_date = formattedStartDate;
    data.end_date = formattedEndDate;
    console.log("datata", data);
    this.setState({ screenloader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByTableChartData(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        screenloader: false,
      });
      sessionStorage.setItem(
        "finalData",
        JSON.stringify(APIResponse.data.result)
      );
      console.log(">>>>>>>>>>>>>", APIResponse.data.result.table);
      sessionStorage.setItem(
        "finalDataTable",
        JSON.stringify(APIResponse.data.result.table)
      );
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    // this.bdbapi();
    await this.GetDataByDefaultValue();
    // await this.GetDataByTableChartData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const prevAssetValues = Array.isArray(prevState.selectedAssetClass)
      ? prevState.selectedAssetClass.map((item) => item.value)
      : [];
    const currentAssetValues = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass.map((item) => item.value)
      : [];

    console.log("currentAssetValues", prevAssetValues, currentAssetValues);

    const prevIssuerValues = Array.isArray(prevState.selectedIssuer)
      ? prevState.selectedIssuer.map((item) => item.value)
      : [];
    const currentIssuerValues = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer.map((item) => item.value)
      : [];

    const prevDealsValues = Array.isArray(prevState.selectedDeals)
      ? prevState.selectedDeals.map((item) => item.value)
      : [];
    const currentDealsValues = Array.isArray(this.state.selectedDeals)
      ? this.state.selectedDeals.map((item) => item.value)
      : [];

    // Improved comparison for selectedAssetClass
    if (
      prevAssetValues.length !== currentAssetValues.length ||
      !prevAssetValues.every(
        (value, index) => value === currentAssetValues[index]
      )
    ) {
      await this.GetDataByUpdateAsset();
    }

    // Improved comparison for selectedIssuer
    if (
      prevIssuerValues.length !== currentIssuerValues.length ||
      !prevIssuerValues.every(
        (value, index) => value === currentIssuerValues[index]
      )
    ) {
      await this.GetDataByUpdateIssuer();
    }

    // Improved comparison for selectedDeals
    if (
      prevDealsValues.length !== currentDealsValues.length ||
      !prevDealsValues.every(
        (value, index) => value === currentDealsValues[index]
      )
    ) {
      await this.GetDataByTableChartData();
    }

    // Check if the showApplyChange value has changed
    if (prevState.showApplyChange !== this.state.showApplyChange) {
      await this.GetDataByTableChartData();
    }
  }

  render() {
    // Custom styles for react-select
    const customStyles = {
      control: (provided) => ({
        ...provided,
        border: "1px solid black",
        borderRadius: "8px",
        minHeight: "38px",
        boxShadow: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#adaeaa",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensures the dropdown is above other elements
      }),
      indicatorSeparator: (provided) => ({
        display: "none", // Removes the vertical line
      }),
    };

    const { showDatepicker, startDate, endDate } = this.state;
    // console.log("?????????startDate", startDate, endDate);

    return (
      <React.Fragment>
        <div className="">
          <Sidebar1 activeComponent={"PortfolioAnalytics"} />
          <div className="content-bdb">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <h3 className="headerdashboard">PORTFOLIO</h3>
                    </div>
                  </div>
                  <div className="PortfolioStds">
                    <button
                      type="button"
                      onClick={() => this.handleClickStandard()}
                      className={
                        this.state.investorTab1 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      Standard
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickOnDemand()}
                      className={
                        this.state.investorTab2 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      On-Demand
                    </button>
                  </div>

                  <div className="dropdown-row">
                    <div className="dropdown-container">
                      <label className="dropdown-label">
                        Transaction Type:
                      </label>
                      <div className="dropdown-content">
                        <Select
                          options={this.state.transacionOptions}
                          value={this.state.selectedTransaction}
                          onChange={this.handleTransactionChange}
                          isClearable
                          placeholder="Select any one"
                          styles={customStyles}
                          components={{ DropdownIndicator }}
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Asset Class:</label>
                      <div className="dropdown-content">
                        <Select
                          options={this.state.assetClassOptions}
                          value={this.state.selectedAssetClass}
                          onChange={this.handleSelectChange}
                          isClearable
                          placeholder="Select any one"
                          styles={customStyles}
                          components={{ DropdownIndicator }}
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Issuer Group:</label>
                      <div className="dropdown-content">
                        <MultiSelect
                          options={this.state.issuerOptions}
                          value={this.state.selectedIssuer}
                          onChange={this.handleIssuerChange}
                          valueRenderer={customValueRenderer}
                          labelledBy="Select"
                          className="custom-multiselect"
                        />
                      </div>
                    </div>

                    <div className="dropdown-container">
                      <label className="dropdown-label">Deals:</label>
                      <div className="dropdown-content">
                        <MultiSelect
                          options={this.state.dealsOptions}
                          value={this.state.selectedDeals}
                          onChange={this.handleDealsChange}
                          valueRenderer={customValueRenderer}
                          labelledBy="Select"
                          className="custom-multiselect"
                        />
                      </div>
                    </div>
                  </div>

                  <DataDisplay />

                  <div className="SpaceDown"></div>

                  <div className="scrollable-content workbench-table-container">
                    {/* <div className="row row14">
                      <div className="general-InvContainer">
                        <div>
                          <label className="label">AssetClass</label>

                          <div className="align-strat-tabs">
                            <Select
                              options={this.state.assetClassOptions}
                              value={this.state.selectedAssetClass}
                              onChange={this.handleSelectChange}
                              isClearable
                              placeholder="Select any one"
                              // className="custom-select"
                              styles={customStyles}
                              components={{ DropdownIndicator }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="align-all-dropdowns">
                        <div>
                          <label className="label">Issuer</label>
                          <MultiSelect
                            options={this.state.issuerOptions}
                            value={this.state.selectedIssuer}
                            onChange={this.handleIssuerChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect"
                          />
                        </div>
                      </div>
                      <div className="align-all-dropdowns">
                        <div>
                          <label className="label">Deals</label>
                          <MultiSelect
                            options={this.state.dealsOptions}
                            value={this.state.selectedDeals}
                            onChange={this.handleDealsChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect"
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className=" date-align-left">
                      <div className="align-perf-dropdowns">
                        <div className="date-range-picker">
                          <h6 className="timePeriod">Time Period : </h6>
                          <div className="align-per-tabs">
                            <div className="date-input-container">
                              <input
                                type="text"
                                readOnly
                                className="date-input"
                                onClick={this.toggleDatepicker}
                                placeholder="Select Date Range"
                                value={
                                  startDate && endDate
                                    ? `${this.formatDate(
                                        startDate
                                      )} - ${this.formatDate(endDate)}`
                                    : startDate
                                    ? `${this.formatDate(startDate)}`
                                    : endDate
                                    ? `${this.formatDate(endDate)}`
                                    : ""
                                }
                              />
                              <FaRegCalendarAlt
                                className="calendar-icon"
                                onClick={this.toggleDatepicker}
                              />
                            </div>

                            {showDatepicker && (
                              <div className="datepicker">
                                <span>Select the Date Range</span>
                                <div
                                  className="datepicker-row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <label className="datepicker-label">
                                    From:
                                  </label>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={this.handleStartDateChange}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Select Start Date"
                                    className="date-picker"
                                  />
                                </div>
                                <div className="datepicker-row">
                                  <label className="datepicker-label">
                                    To:
                                  </label>
                                  <DatePicker
                                    selected={endDate}
                                    onChange={this.handleEndDateChange}
                                    selectsEnd
                                    showYearDropdown
                                    dropdownMode="select"
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText="Select End Date"
                                    className="date-picker"
                                  />
                                </div>
                                <div className="button-row">
                                  <button
                                    className="daterange-btn"
                                    onClick={this.applyDateRange}
                                  >
                                    Apply
                                  </button>
                                  <button
                                    className="daterange-cancelbtn"
                                    onClick={() =>
                                      this.setState({ showDatepicker: false })
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <PortfolioDash />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(PortfolioStandard);
