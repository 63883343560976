/* eslint-disable require-jsdoc */
import React, { PureComponent, createRef } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
// import FormLoader from "../../../../../components/loader/formLoader";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
// import { TrainRounded } from "@material-ui/icons";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NumberComp2 from "../../../../../components/NumberComp2";
import NumberComp4 from "../../../../../components/NumberComp4";
// import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
// import Box from "@mui/material/Box";
import { customStylesauto } from "../../../../../components/customscripts/customscript";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import {
  tranchesDealRecurring,
  CalculatePayments,
  CalculatePrePayments,
  tranchesGetDealRecurring,
  getIndexDealRecurring,
  CalculateConsolidatedPayments,
  CalculateESMAPayments,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
  CalculateCollateralPayments,
  addtransactionTranchesRecurring,
  editTranchestransactionDealRecurring,
  deleteTranchestransactionDealRecurring,
} from "../../../../../servies/services";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactModal from "react-modal";
// import {
//   customStylesPATablePopup,
//   customStylesautosmallmodal,
// } from "../../../../../components/customscripts/customscript";
import CloseIcon from "@material-ui/icons/Close";
import BeanEater from "../../../../../images/BeanEater.gif";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import { debounce } from "lodash";

class Tranches_Recurring extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      loading1: false,
      tableData: [],
      tableData1: [],
      TranchesDropdown: [],
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      TableName: "Tranches",
      downArrow: false,
      Index: 0,
      isIndexChange: false,
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      isESMA_Flag: sessionStorage.getItem("isESMA_Flag"),
      Version: sessionStorage.getItem("version"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      VersionsChange: [],
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getLoansLoader: false,
      openPopup: false,
      formLoader: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      formData: {
        Date: "",
        Tranches: "",
        Type: "",
        Amount: "",
        TxnID: "",
      },
      deleteRowVal: {
        Date: "",
        Tranches: "",
        Type: "",
        Amount: "",
        "Modified Date": "",
        "Txn ID": "",
      },
      searchText: "",
      isBorrow: false,
      BorrowingBase: "False",
      rowsSelected: null,
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,

      openModalPayment: false,
    };
    this.buttonRef = createRef();
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.Tranches(this.state.DealName)}>Deal</button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        {/* <hr className="servicer-popover-hr" />
      <button>Change Period</button> */}
      </Popover>
    );
  };

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  showPrev = () => {
    window.location.assign("/admin/general_recurring");
  };
  showNext = () => {
    window.location.assign("/admin/fees_recurring");
  };
  onOpenModal = () => {
    this.setState({ open1: true });
  };
  onCloseModal = () => {
    this.setState({ open1: false });
  };
  onOpenModal1 = () => {
    this.setState({ open2: true });
  };
  onCloseModal1 = () => {
    this.setState({ open2: false });
  };
  goBackToDashboard = () => {
    sessionStorage.removeItem("index");
    this.props.history.push({
      pathname: "/dashboard",
    });
  };
  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/accounts_recurring");
  };

  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/tests_recurring");
  };
  handleBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: true,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/borrowingbase_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/payment_recurring");
  };
  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/collateral_recurring");
  };

  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: true,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/loantape_recurring");
  };

  handleClickConsolidated = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: true,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/consolidated_recurring");
  };

  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: true,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    window.location.assign("/admin/files_recurring");
  };
  handleClickManualInputs = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: true,
    });
    window.location.assign("/admin/manualinput_recurring");
  };

  tranchesDealRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.TableName = this.state.TableName;
    data.Index = `${parseFloat(this.state.Index)}%`;
    data.NextPaymentDate = this.state.NextPaymentDate;
    data.LastPaymentDate = this.state.LastPaymentDate;
    data.peers = this.state.peers;

    console.log("datata", data);
    const APIResponse = await tranchesDealRecurring(data);

    // console.log("tranchesDealRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        loading: false,
        openModalPayment: false,
        screenloader: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
        screenloader: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  tranchesGetDealRecurring = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      loading: true,
      loading1: true,
      screenloader: true,
    });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.Version;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await tranchesGetDealRecurring(data);

    console.log("testsDealRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data.Tranches.Data,
        Index: APIResponse.data.Tranches.Index,
        tableData1: APIResponse.data.TrancheTransactions,
        loading: false,
        loading1: false,
        openModalPayment: false,
        screenloader: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        loading1: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        loading1: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  getIndexDealRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.Version;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getIndexDealRecurring(data);

    console.log("testsDealRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data.Tranches,
        Index: APIResponse.Index,
        // tableData1: APIResponse.data.TrancheTransactions,
        loading: false,
        openModalPayment: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // TranscationsGetDealRecurring = async () => {
  //   this.setState({ getLoansLoader: true, tableData1: [], loading: true });
  //   let x = this.state?.NextPaymentDate;
  //   let month = x.slice(0, 2);
  //   let year = x.slice(6, 10);
  //   var data = {};
  //   data.DealName = this.state.DealName;
  //   data.Month = month;
  //   data.Year = year;
  //   data.Version = this.state.Version;
  //   data.TableName = "TrancheTransactions";
  //   data.peer = this.state.peer;

  //   console.log("datata", data);
  //   const APIResponse = await TranscationsGetDealRecurring(data);

  //   console.log("testsDealRecurring", APIResponse.data);
  //   if (APIResponse.status === 200) {
  //     this.setState({
  //       getLoansLoader: false,
  //       tableData1: APIResponse.data,
  //       loading: false,
  //       openModalPayment: false,
  //     });
  //     // const message = "Deal Document Update Success";
  //     // this.props.enqueueSnackbar(message, {
  //     //   variant: "info",
  //     //   autoHideDuration: 3000,
  //     // });
  //     //window.location.assign("/admin/previewmapfields");
  //   } else if (APIResponse.status === 201) {
  //     this.setState({
  //       getLoansLoader: false,
  //       loading: false,
  //       openModalPayment: false,
  //     });
  //     const message = APIResponse.data.message;
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   } else {
  //     this.setState({
  //       getLoansLoader: false,
  //       loading: false,
  //       openModalPayment: false,
  //     });
  //     const message = APIResponse.data.message;
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  // };

  onCloseModalBorrow = () => {
    this.setState({ isBorrow: false });
  };
  onOpenModalBorrow = () => {
    this.setState({ isBorrow: true });
  };
  checkBorrowBaseYes = async () => {
    this.setState(
      { calculateBorrow: true, BorrowingBase: "True", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };
  checkBorrowBaseNo = async () => {
    this.setState(
      { calculateBorrow: false, BorrowingBase: "False", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        // const message = APIResponse.data.message;
        // this.props.enqueueSnackbar(message, {
        //   variant: "info",
        //   autoHideDuration: 3000,
        // });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.BorrowingBase = this.state.BorrowingBase;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("TranchesCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateCollateralPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateCollateralPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateCollateralPayments(data);

    console.log("GeneralCalculateCollateralPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        await this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        if (sessionStorage.getItem("isESMA_Flag") === "Yes") {
          await this.CalculateESMAPayments();
        } else {
          window.location.assign("/admin/tranches_recurring");
        }
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateESMAPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateESMAPayments(data);

    console.log("PaymentCalculateESMAPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        window.location.assign("/admin/tranches_recurring");
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  Tranches = (value) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("dashChanges", false);
    this.props.history.push({
      pathname: "/admin/tranches",
      state: { checkRecurring: true },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: false,
        checkRecurringTranches: true,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringBorrowingBase: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  onOpenModal3 = (deleteValue) => {
    console.log("value: ", deleteValue);
    console.log("delete tranche modal");
    this.setState({
      open4: true,
      deleteRowVal: {
        Date: deleteValue[0],
        Tranches: deleteValue[1],
        Type: deleteValue[2],
        Amount: deleteValue[3],
        "Modified Date": deleteValue[4],
        "Txn ID": deleteValue[5],
      },
    });
  };

  handleRowClick = (rowData) => {
    console.log("rowData", rowData);

    this.setState({
      open3: true,
      selectedRow: {
        Date: rowData[0],
        Tranches: rowData[1],
        Type: rowData[2],
        Amount: rowData[3],
        "Modified Date": rowData[4],
        "Txn ID": rowData[5],
        // "Collection Period": rowData[0],
        // "From Account": rowData[1],
        // "To Account": rowData[2],
        // "Transaction Name": rowData[3],
        // Amount: rowData[4],
        // "Modified Date": rowData[6],
        // "Txn ID": rowData[7],
      },
      formData: {
        Date: rowData[0],
        Tranches: rowData[1],
        Type: rowData[2],
        Amount: rowData[3],
      },
      // formData1: {
      //   "Collection Period": rowData[0],
      //   "From Account": rowData[1],
      //   "To Account": rowData[2],
      //   "Transaction Name": rowData[3],
      //   Amount: rowData[4],
      // },
      showModal: true,
    });

    // const startIndex = this.state.tableData.findIndex(
    //   (item) => item["Account Name"] === rowData[1]
    // );
    // const endIndex = this.state.tableData.findIndex(
    //   (item) => item["Account Name"] === rowData[2]
    // );

    // if (startIndex !== -1 && endIndex !== -1) {
    //   const extractedObjects = this.state.tableData.slice(
    //     startIndex,
    //     endIndex + 1
    //   );
    //   console.log(extractedObjects);
    //   // this.state.tableData = extractedObjects;

    //   this.setState({ newData: extractedObjects });
    // } else {
    //   console.log("Start or end index not found");
    // }
  };

  onCloseModal4 = async () => {
    this.setState({ open5: false });
  };

  onCloseModal2 = async () => {
    this.setState({ open3: false });
  };

  onCloseModal3 = async () => {
    this.setState({ open4: false });
  };

  onopenModel4 = () => {
    this.setState({
      open5: true,
      formData: {
        Date: "",
        Tranches: "",
        Type: "",
        Amount: "",
      },
    });
  };

  AddSubmit = (e) => {
    e.preventDefault();

    if (
      this.state.formData &&
      this.state.formData.Amount !== null &&
      this.state.formData.Amount !== undefined
    ) {
      console.log(this.state.formData);
      const renameAmount = parseFloat(
        this.state.formData.Amount.replace(/,/g, "")
      );
      delete this.state.formData.Amount;
      this.state.formData.Amount = renameAmount;

      //   const beginningBalanceArray = this.state.tableData.map(item => parseFloat(item["Beginning Balance"]) || 0);
      // const depositAmountArray = this.state.tableData.map(item => parseFloat(item["Deposit Amount"]) || 0);

      // const sumArray = beginningBalanceArray.map((balance, index) => balance + depositAmountArray[index]);
      // const maxSum = Math.max(...sumArray); // Find the maximum value in sumArray

      // const checkTotal = parseFloat(this.state.formData.Amount) < maxSum;

      // console.log("checkTotal", checkTotal);

      this.addtransactionTranchesRecurring();
      console.log("hello we have clicked the button");
    } else {
      console.log("formData or Amount is null or undefined");
    }
  };

  EditSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formData);

    const renameAmount = this.state.formData.Amount.toString().includes(",")
      ? parseFloat(this.state.formData.Amount.replace(/,/g, ""))
      : this.state.formData.Amount;
    delete this.state.formData.Amount;
    this.state.formData.Amount = renameAmount;

    this.editTranchestransactionDealRecurring();
    console.log("hello we have clicked the Edit button");
  };

  editTranchestransactionDealRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.TableName = "TrancheTransactions";
    data.ExistingData = this.state.selectedRow;
    const ObjAdd = {
      ...this.state.formData,
    };
    data.Data = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await editTranchestransactionDealRecurring(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        await this.tranchesGetDealRecurring();
        await this.onCloseModal2();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal2();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal2();
    }
  };

  deleteTranchestransactionDealRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.TableName = "TrancheTransactions";
    data.peers = this.state.peers;
    const Obj = {
      ...this.state.deleteRowVal,
    };
    data.DeleteData = Obj;
    data.peers = this.state.peers;
    console.log("dataDelete", data);
    const APIResponse = await deleteTranchestransactionDealRecurring(data);

    if (APIResponse.status === 200) {
      this.setState({ formLoader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      await this.onCloseModal3();
      await this.tranchesGetDealRecurring();
    } else {
      this.setState({ formLoader: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  addtransactionTranchesRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.TableName = "TrancheTransactions";
    const ObjAdd = {
      ...this.state.formData,
    };
    data.Data = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await addtransactionTranchesRecurring(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.Success === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.tranchesGetDealRecurring();
        this.onCloseModal4();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal4();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal4();
    }
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState(
      { DealName: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.DealName);
        await this.getAllPaymentDatesByDealName();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.tranchesGetDealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.tranchesGetDealRecurring();
            // this.tranchesDealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.tranchesGetDealRecurring();
            });
          }
        }
      }
    );
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState(
      { NextPaymentDate: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        await this.getAllVersionsByPaymentDate();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.tranchesGetDealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.tranchesGetDealRecurring();
            // this.tranchesDealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.tranchesGetDealRecurring();
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.tranchesGetDealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.tranchesGetDealRecurring();
            // this.tranchesDealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.tranchesGetDealRecurring();
            });
          }
        }
      }
    );
  };

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even) > td:first-child": {
              backgroundColor: "white !important",
              position: "relative", // Add position relative
              "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: "rgb(229, 229, 229, 0.3) !important",
              },
              "&:first-child": {
                position: "sticky",
                left: 0,
                top: 0,
                zIndex: 1,
                backgroundColor: "rgba(255, 255, 255, 1)", // Adjust the alpha value to your preference
              },
              "& > *": {
                color: "#000 !important", // Adjust this as needed
              },
            },
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },

        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
            "&::after": {
              content: "",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "white",
              zIndex: -99,
            },
            "@media (min-width: 800px)": {
              "&:first-child": {
                position: "sticky",
                left: 0,
                top: 0,
                zIndex: 1,
                backgroundColor: "rgba(255, 255, 255, 1)", // Adjust the alpha value to your preference
              },
            },
          },
        },

        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "white !important",
            backgroundClip: "padding-box",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
            position: "relative",
            zIndex: 1,
            "&:first-child": {
              position: "sticky",
              left: 0,
              zIndex: 500,
              top: 0,
            },
            "&::after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(1, 142, 130, 0.1) !important",
              zIndex: -1,
            },
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            scrollbarWidth: "thin",
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
        },
      },
    });

  getMuiTheme1 = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
      },
    });

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.Index !== this.state.Index &&
      this.state.Index !== "" &&
      this.state.isIndexChange
    ) {
      this.setState({ isIndexChange: false });
      await this.tranchesDealRecurring();
    }
  }
  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    // sessionStorage.setItem("index", this.state.Index);
    const { Version, getDashboardVersions } = this.state;

    if (this.state.isLoansProcessed === "No") {
      window.location.assign("/admin/general_recurring");
    }

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isDisable: false, Version: "WIP" }, async () => {
        // await this.getIndexDealRecurring();
        await this.tranchesGetDealRecurring();
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isDisable: this.state.isDisable });
        // await this.getIndexDealRecurring();
        await this.tranchesGetDealRecurring();
        // this.tranchesDealRecurring();
      } else {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          // await this.getIndexDealRecurring();
          await this.tranchesGetDealRecurring();
        });
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isDisable: this.state.isDisable });
    //   this.tranchesDealRecurring();
    // } else {
    //   this.setState({ isDisable: false });
    //   this.tranchesGetDealRecurring();
    // }
  }

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: "none",
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    const options1 = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: "none",
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading1 === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        name: "Name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Interest Rate",
        label: "Interest Rate",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp4 value={value}></NumberComp4>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Capped Coupon",
        label: "Capped Coupon",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp4 value={value}></NumberComp4>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Accrual Days",
        label: "Accural Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>{value}</div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Beginning Principal Balance",
        label: "Beginning Principal Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Beginning Interest Shortfall",
        label: "Beginning Interest Shortfall",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Interest Accrued",
        label: "Interest Accured",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Optimal Interest",
        label: "Optimal Interest",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Interest Paid",
        label: "Interest Paid",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Principal Paid",
        label: "Principal Paid",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Total Distribution",
        label: "Total Distribution",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Loss Allocated",
        label: "Loss Allocated",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Deferred Interest",
        label: "Deferred Interest",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Ending Principal Balance",
        label: "Ending Principal Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Ending Interest Shortfall",
        label: "Ending Interest Shortfall",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Cumulative Loss Allocated",
        label: "Cumulative Loss Allocated",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Bond Total Interest Amount",
        label: "Bond Total Interest Amount",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Coupon Cap1",
        label: "Coupon Cap1",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp4 value={value}></NumberComp4>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Coupon Cap2",
        label: "Coupon Cap2",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp4 value={value}></NumberComp4>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Beginning WAC Shortfall",
        label: "Beginning WAC Shortfall",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Interest on WAC Shortfall",
        label: "Interest on WAC Shortfall",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Current WAC Shortfall",
        label: "Current WAC Shortfall",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Total WAC Shortfall",
        label: "Total WAC Shortfall",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "WAC Shortfall Paid",
        label: "WAC Shortfall Paid",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Ending WAC Shortfall",
        label: "Ending WAC Shortfall",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Interest Accrual Start Date",
        label: "Interest Accrual Start Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>{value}</div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Interest Accrual End Date",
        label: "Interest Accrual End Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>{value}</div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Beginning Available Balance",
        label: "Beginning Available Balance",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Ending Available Balance",
        label: "Ending Available Balance",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Commitment Balance",
        label: "Commitment Balance",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Average Daily Principal Balance",
        label: "Average Daily Principal Balance",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Average Daily Fee Basis",
        label: "Average Daily Fee Basis",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "0.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Commitment Fee",
        label: "Commitment Fee",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div style={{ paddingLeft: "1.5rem" }}>
                  <NumberComp2 value={value}></NumberComp2>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Index Determination Date",
        label: "Index Determination Date",
        options: {
          filter: true,
          sort: true,
          display:
            this.state.isSecuritisation !== "Securitisation" ? false : true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <React.Fragment>{value}</React.Fragment>;
          },
        },
      },
      {
        name: "Beginning Notional Balance",
        label: "Beginning Notional Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const isNumber = /^-?\d*\.?\d+$/.test(value);

            return isNumber ? (
              <div style={{ paddingLeft: "0.5rem" }}>
                <NumberComp2 value={value}></NumberComp2>
              </div>
            ) : (
              <div style={{ paddingLeft: "0.5rem" }}>{value}</div>
            );
          },
        },
      },
      {
        name: "Ending Notional Balance",
        label: "Ending Notional Balance",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const isNumber = /^-?\d*\.?\d+$/.test(value);

            return isNumber ? (
              <div style={{ paddingLeft: "0.5rem" }}>
                <NumberComp2 value={value}></NumberComp2>
              </div>
            ) : (
              <div style={{ paddingLeft: "0.5rem" }}>{value}</div>
            );
          },
        },
      },
      {
        name: "Record Date",
        label: "Record Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <React.Fragment>{value}</React.Fragment>;
          },
        },
      },
    ];
    const columns1 = [
      {
        name: "Date",
        label: "Date",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "Tranches",
        label: "Tranches",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return <div>{value}</div>;
          },
        },
      },

      {
        name: "Type",
        label: "Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Amount",
        label: "Amount",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <NumberComp2 value={value}></NumberComp2>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Modified Date",
        label: "ModifiedDate",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "Txn ID",
        label: "TxnID",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "Txn ID",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "20px",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="">
                  <span>
                    {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}

                    {this.state.isDisable === true ? (
                      <>
                        <button
                          className="popupbutton23"
                          onClick={() =>
                            this.handleRowClick(tableMeta.rowData.splice(0, 8))
                          }
                          disabled
                        >
                          Edit
                        </button>
                        <button
                          className="popupbutton22"
                          onClick={() =>
                            this.onOpenModal3(tableMeta.rowData.splice(0, 8))
                          }
                          disabled
                        >
                          Delete
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="popupbutton1"
                          onClick={() =>
                            this.handleRowClick(tableMeta.rowData.splice(0, 8))
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="popupbuttons1"
                          onClick={() =>
                            this.onOpenModal3(tableMeta.rowData.splice(0, 8))
                          }
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </span>
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Deal Recurring"} />
          {this.state.screenloader === true ? (
            <LinearLoader msg={""} />
          ) : (
            <div className="content">
              <div className="header">
                <Header></Header>
              </div>
              <div className="page-contentofpool1">
                <div className="row1 arrow-dealcontent">
                  <div
                    className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                      <KeyboardBackspaceIcon
                        onClick={this.goBackToDashboard}
                        className="left-arrow-muis1 left-arrow-servicer"
                      ></KeyboardBackspaceIcon>
                      <h3 className="headerdashboard">Deal Recurring</h3>
                    </div>
                  </div>
                </div>

                <div className="row1 css-recurring move-dropdowns">
                  <div className="recurring_details_new">
                    <div>
                      <label className="dealInfo">Deal Name </label>
                      {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1-deal"
                          value={this.state?.DealName}
                          onChange={this.handleDealNameChange}
                        >
                          {sortedDeals.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="dealInfo">Payment Date </label>
                      {/* <h6 className="dealInfo1">
                          {this.state?.NextPaymentDate}
                        </h6> */}
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={this.state?.NextPaymentDate}
                          onChange={this.handlePaymentDateChange}
                        >
                          {this.state.getDashboardDates.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="dealInfo">Version </label>
                      {/* <h6 className="dealInfo1">{this.state?.Version}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={
                            this.state.Version === "WIP"
                              ? sessionStorage.getItem("version")
                              : this.state.Version
                          }
                          onChange={this.handleVersionChange}
                        >
                          {this.state.getDashboardVersions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="buttonsverified uw-deal-details-button-container">
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="bottom"
                      overlay={this.popoverBottom()}
                      onExited={this.handleOverlayHide}
                    >
                      <Button
                        variant="outlined"
                        onClick={this.handleButtonClick}
                      >
                        Edit
                        {this.state.downArrow ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </Button>
                    </OverlayTrigger>
                    {this.state.isDisable === true ||
                    this.state.isLoansProcessed === "No" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.CalculatePrePayments}
                        disabled
                      >
                        Calculate
                        {this.state.formLoader === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.formLoader}
                        onClick={
                          sessionStorage.getItem("isSecuritisation") !==
                          "Securitisation"
                            ? this.onOpenModalBorrow
                            : this.CalculatePrePayments
                        }
                      >
                        Calculate
                        {this.state.formLoader === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    )}
                  </div>
                </div>

                <div className="row row1 movement-tabs">
                  <div>
                    {/* <div className="tablechangebutton"> */}
                    <div className="tablechangebuttonloans">
                      <button
                        type="button"
                        onClick={() => this.handleClickGeneral()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        General
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTranches()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Tranches
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickFees()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Fees
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickExpenses()}
                        className={
                          this.state.activeInsights4 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Expenses
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickManualInputs()}
                        className={
                          this.state.activeInsightsMI == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Manual Inputs
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAccounts()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Accounts
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTests()}
                        className={
                          this.state.activeInsights6 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Tests
                      </button>
                      {this.state.isSecuritisation !== "Securitisation" ? (
                        <button
                          type="button"
                          onClick={() => this.handleBorrowingBase()}
                          className={
                            this.state.activeInsightsBB == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Borrowing Base
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => this.handleClickVariables()}
                        className={
                          this.state.activeInsights7 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Variables
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPaymentRules()}
                        className={
                          this.state.activeInsights8 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Payment Rules
                      </button>
                      {/* <button
                        type="button"
                        onClick={() => this.handleClickCollateral()}
                        className={
                          this.state.activeInsights9 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Collateral
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickLoanTape()}
                        className={
                          this.state.activeInsights10 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Loan Tape
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickConsolidated()}
                        className={
                          this.state.activeInsights11 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Consolidated
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickFiles()}
                        className={
                          this.state.activeInsights12 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Files
                      </button> */}
                    </div>
                  </div>
                  <div className="recurring_details movement-index">
                    {/* <div>
                    <label className="dealInfo">Deal Name </label>
                    <h6 className="dealInfo1">{this.state?.DealName}</h6>
                  </div>

                  <div>
                    <label className="dealInfo">Payment Date </label>
                    <h6 className="dealInfo1">{this.state?.NextPaymentDate}</h6>
                  </div> */}

                    <div
                      className="input-container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.2rem",
                        position: "absolute",
                        top: "20rem",
                        right: "2.4rem",
                      }}
                    >
                      <label
                        className="dealInfo"
                        style={{
                          flex: "0.5",
                          textAlign: "end",
                          marginTop: "1.3px",
                        }}
                      >
                        Index :
                      </label>
                      <div
                        className="flex input"
                        style={{ flex: "0.5", height: "2rem", width: "16rem" }}
                      >
                        <input
                          required
                          placeholder="Type here"
                          className="input-none"
                          type="number"
                          onKeyDown={this.blockInvalidChar}
                          onChange={(e) => {
                            // sessionStorage.setItem(
                            //   "index",
                            //   e.target.value === "" ? 0 : e.target.value
                            // );
                            this.setState({
                              Index: e.target.value,
                              isIndexChange: true,
                            });
                          }}
                          value={parseFloat(this.state.Index)}
                        />
                        <p>%</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row1 movement-header">
                  <div className="investor-heading-container">
                    <h1 className="headerdashboard1">List of Tranches</h1>
                  </div>
                </div>

                <React.Fragment>
                  <div className="workbench-table-container movement-table">
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        // title={'Dashboard'}
                        data={this.state.tableData}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                    <ReactModal
                      isOpen={this.state.openModalPayment}
                      contentLabel="Minimal Modal Example"
                      style={{
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "fixed", // Use fixed positioning for the overlay
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                        },
                        content: {
                          position: "absolute",
                          background: "transparent", // Make the modal content transparent
                          border: "none", // Optional: Remove any borders
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          overflow: "hidden",
                        },
                      }}
                    >
                      <React.Fragment>
                        <img
                          src={BeanEater}
                          style={{
                            width: "8vw",
                            height: "9vh",
                            backgroundColor: "transparent",
                          }}
                        />
                        <h3 className="dot-loader">Loading.Please wait</h3>
                      </React.Fragment>
                    </ReactModal>
                  </div>

                  {this.state.isSecuritisation !== "Securitisation" ? (
                    <>
                      <div className="row row1 movement-tranche-trans">
                        <div className="investor-heading-container">
                          <h1 className="headerdashboard1"></h1>
                        </div>
                        {this.state.isDisable === true ||
                        this.state.isLoansProcessed === "No" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.onopenModel4}
                            disabled
                          >
                            Add Transactions
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.onopenModel4}
                          >
                            Add Transactions
                          </Button>
                        )}
                      </div>
                      <div className="workbench-table-container movement-table">
                        <MuiThemeProvider theme={this.getMuiTheme1()}>
                          <MUIDataTable
                            // title={'Dashboard'}
                            data={this.state.tableData1}
                            columns={columns1}
                            options={options1}
                          />
                        </MuiThemeProvider>
                        <div>
                          <>
                            <ReactModal
                              isOpen={this.state.open5}
                              onRequestClose={this.onCloseModal4}
                              contentLabel="Minimal Modal Example"
                              style={customStylesauto}
                            >
                              <React.Fragment>
                                <div className="modalPopup">
                                  <h2>Add Transactions</h2>
                                  <button
                                    type="button"
                                    className="closePopup"
                                    style={{ minWidth: "30px" }}
                                    onClick={this.onCloseModal4}
                                  >
                                    <CloseIcon></CloseIcon>
                                  </button>

                                  <div className="modalshiftcontent">
                                    <form
                                      className="form-container"
                                      onSubmit={this.AddSubmit}
                                    >
                                      <div
                                        className="input-generalContainer-Date"
                                        style={{ marginLeft: "-1.7rem" }}
                                      >
                                        <label
                                          className="label"
                                          style={{ marginLeft: "1.4rem" }}
                                        >
                                          Date
                                        </label>
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDatePicker
                                            className="input-Datepickercss"
                                            disableToolbar
                                            margin="normal"
                                            id="date1"
                                            value={
                                              this.state.formData["Date"] ||
                                              null
                                            }
                                            onChange={(date) => {
                                              const formattedDate = date
                                                ? moment(
                                                    date,
                                                    "MM/DD/YYYY",
                                                    true
                                                  ).format("MM/DD/YYYY")
                                                : null;
                                              this.setState({
                                                formData: {
                                                  ...this.state.formData,
                                                  ["Date"]: formattedDate,
                                                },
                                              });
                                            }}
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                            disableopenonenter="true"
                                            animateYearScrolling={false}
                                            autoOk={true}
                                            clearable
                                            variant="filled"
                                            helperText={""}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                      <div className="input-container">
                                        <label className="label">
                                          Tranches
                                        </label>
                                        <select
                                          className="input-select"
                                          onChange={(e) => {
                                            this.setState({
                                              formData: {
                                                ...this.state.formData,
                                                Tranches: e.target.value,
                                              },
                                            });
                                          }}
                                          value={
                                            this.state.formData["Tranches"]
                                          }
                                        >
                                          <option
                                            value=""
                                            disabled
                                            className="selectclass"
                                          >
                                            Select Any One
                                          </option>
                                          {this.state.tableData.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.Name}
                                                >
                                                  {item.Name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>

                                      <div className="input-container">
                                        <label className="label">Type</label>
                                        <select
                                          className="input-select"
                                          onChange={({ target: { value } }) => {
                                            this.setState((prevState) => ({
                                              formData: {
                                                ...prevState.formData,
                                                Type: value,
                                              },
                                            }));
                                          }}
                                          value={this.state.formData.Type}
                                        >
                                          <option value="">
                                            Select any one
                                          </option>
                                          <option value="Draw">Draw</option>
                                          <option value="Paydown">
                                            Paydown
                                          </option>
                                        </select>
                                      </div>
                                      <div className="input-container">
                                        <label className="label">Amount</label>
                                        <input
                                          placeholder="Type here"
                                          className="input"
                                          type="text"
                                          onChange={(e) => {
                                            let removeCharC =
                                              e.target.value.replace(
                                                /[^0-9\.]/g,
                                                ""
                                              );
                                            let formattedValue =
                                              removeCharC.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              );
                                            this.setState({
                                              formData: {
                                                ...this.state.formData,
                                                Amount: formattedValue,
                                              },
                                            });
                                          }}
                                          value={this.state.formData.Amount}
                                        />
                                      </div>

                                      <div className="modalsubmit">
                                        <div className="submitbuttonbg">
                                          <div className="row">
                                            <div className="row justify-content-end1">
                                              <button
                                                type="button"
                                                className="popupbutton22"
                                                onClick={this.onCloseModal4}
                                              >
                                                Cancel
                                              </button>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                              >
                                                Save
                                                {this.state.formLoader ===
                                                true ? (
                                                  <CircularProgress
                                                    size="22px"
                                                    color="primary"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </React.Fragment>
                            </ReactModal>

                            <ReactModal
                              isOpen={this.state.open3}
                              onRequestClose={this.onCloseModal2}
                              contentLabel="Minimal Modal Example"
                              style={customStylesauto}
                            >
                              <React.Fragment>
                                <div className="modalPopup">
                                  <h2>Edit Transactions</h2>
                                  <button
                                    type="button"
                                    className="closePopup"
                                    style={{ minWidth: "30px" }}
                                    onClick={this.onCloseModal2}
                                  >
                                    <CloseIcon></CloseIcon>{" "}
                                  </button>

                                  {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                                  <div className="modalshiftcontent">
                                    <form
                                      className="form-container"
                                      onSubmit={this.EditSubmit}
                                    >
                                      <div
                                        className="input-generalContainer-Date"
                                        style={{ marginLeft: "-1.7rem" }}
                                      >
                                        <label
                                          className="label"
                                          style={{ marginLeft: "1.4rem" }}
                                        >
                                          Date
                                        </label>
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDatePicker
                                            className="input-Datepickercss"
                                            disableToolbar
                                            margin="normal"
                                            id="date1"
                                            value={
                                              this.state.formData["Date"] ||
                                              null
                                            } // Set initial value to null or a specific date
                                            onChange={(date) =>
                                              this.setState({
                                                formData: {
                                                  ...this.state.formData,
                                                  ["Date"]: date
                                                    ? moment(date)
                                                        .format("MM/DD/YYYY")
                                                        .toString()
                                                    : null,
                                                },
                                              })
                                            }
                                            keyboard="true"
                                            placeholder="MM/DD/YYYY"
                                            format={"MM/DD/YYYY"}
                                            disableopenonenter="true"
                                            animateYearScrolling={false}
                                            autoOk={true}
                                            clearable
                                            variant="filled"
                                            helperText={""}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                      <div className="input-container">
                                        <label className="label">
                                          Tranches
                                        </label>
                                        <select
                                          className="input-select"
                                          onChange={(e) => {
                                            this.setState({
                                              formData: {
                                                ...this.state.formData,
                                                Tranches: e.target.value,
                                              },
                                            });
                                          }}
                                          value={
                                            this.state.formData["Tranches"]
                                          }
                                        >
                                          <option
                                            value=""
                                            disabled
                                            className="selectclass"
                                          >
                                            Select Any One
                                          </option>
                                          {this.state.tableData.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.Name}
                                                >
                                                  {item.Name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>

                                      <div className="input-container">
                                        <label className="label">Type</label>
                                        <select
                                          className="input-select"
                                          onChange={({ target: { value } }) => {
                                            this.setState((prevState) => ({
                                              formData: {
                                                ...prevState.formData,
                                                Type: value,
                                              },
                                            }));
                                          }}
                                          value={this.state.formData.Type}
                                        >
                                          <option value="">
                                            Select any one
                                          </option>
                                          <option value="Draw">Draw</option>
                                          <option value="Paydown">
                                            Paydown
                                          </option>
                                        </select>
                                      </div>

                                      <div className="input-container">
                                        <label className="label">Amount</label>
                                        <input
                                          placeholder="Type here"
                                          className="input"
                                          type="text"
                                          onChange={(e) => {
                                            let removeCharC =
                                              e.target.value.replace(
                                                /[^0-9\.]/g,
                                                ""
                                              );
                                            let formattedValue =
                                              removeCharC.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              );
                                            this.setState({
                                              formData: {
                                                ...this.state.formData,
                                                Amount: formattedValue,
                                              },
                                            });
                                          }}
                                          value={this.state.formData.Amount}
                                        />
                                      </div>

                                      <div className="modalsubmit">
                                        <div className="submitbuttonbg">
                                          <div className="row">
                                            <div className="row justify-content-end1">
                                              <button
                                                type="button"
                                                className="popupbutton22"
                                                onClick={this.onCloseModal2}
                                              >
                                                Cancel
                                              </button>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                              >
                                                Save
                                                {this.state.formLoader ===
                                                true ? (
                                                  <CircularProgress
                                                    size="22px"
                                                    color="primary"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </React.Fragment>
                            </ReactModal>

                            <div id="modal1">
                              <ReactModal
                                isOpen={this.state.open4}
                                onRequestClose={this.onCloseModal3}
                                contentLabel="Minimal Modal Example"
                                style={customStylesautosmallmodal1}
                              >
                                <React.Fragment>
                                  <div className="modalPopup">
                                    <h3 className="popupheading">
                                      Are you sure, you want to Delete this
                                      Transaction "
                                      {this.state.deleteRowVal["Tranches"]}"?
                                    </h3>

                                    {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                                    <div className="modalshiftcontent">
                                      <div className="modalsubmit">
                                        <div className="submitbuttonbg">
                                          <div className="row">
                                            <div className="row justify-content-end11">
                                              <button
                                                type="button"
                                                className="popupbutton1"
                                                onClick={this.onCloseModal3}
                                              >
                                                No
                                              </button>

                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={
                                                  this
                                                    .deleteTranchestransactionDealRecurring
                                                }
                                              >
                                                Yes, Delete it
                                                {this.state.formLoader ===
                                                true ? (
                                                  <CircularProgress
                                                    size="25px"
                                                    color="primary"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </ReactModal>
                            </div>
                            <div id="modal1">
                              <ReactModal
                                isOpen={this.state.isBorrow}
                                onRequestClose={this.onCloseModalBorrow}
                                contentLabel="Minimal Modal Example"
                                style={customStylesautosmallmodalpopupBorrow}
                              >
                                <React.Fragment>
                                  <div className="modalPopup">
                                    <h3 className="popupheading">
                                      Do you want to run Payment Waterfall?
                                    </h3>

                                    {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                                    <div className="modalshiftcontent">
                                      <div className="modalsubmit">
                                        <div className="submitbuttonbg">
                                          <div className="row">
                                            <div className="row justify-content-start11">
                                              <button
                                                type="button"
                                                className="popupbutton1"
                                                onClick={
                                                  this.onCloseModalBorrow
                                                }
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                            <div className="row justify-content-end11">
                                              <button
                                                type="button"
                                                className="popupbutton1"
                                                onClick={this.checkBorrowBaseNo}
                                              >
                                                No
                                              </button>

                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={
                                                  this.checkBorrowBaseYes
                                                }
                                              >
                                                Yes
                                                {/* {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )} */}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              </ReactModal>
                            </div>
                          </>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <>
                    <div className="btn_move">
                      <div className="btn_prev" onClick={this.showPrev}>
                        Previous
                      </div>
                      <button className="btn_next" onClick={this.showNext}>
                        Next
                      </button>
                    </div>
                  </>
                </React.Fragment>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Tranches_Recurring);
