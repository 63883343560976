/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Header from "../../../../../components/header/index.js";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../../../components/loader/index.js";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import axios from "axios";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  GetDataByDefaultValue,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import PerformanceDropDowns from "./PerformanceDropDowns.js";
import DataDisplaySummary from "./DataDisplaySummary.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ChatAssistant from "./ChatAssistant.js";
import IDA from "../../../../../images/IDA.svg";
import BETA from "../../../../../images/BETA.svg";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};
class DealAnalyticsPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      assetClassOptions: [],
      selectedAssetClass: [], // Selected option for react-select
      IssuerGroup: [],
      issuerOptions: [], // New state for Issuer options
      selectedIssuer: [], // New state for selected Issuers
      dealsOptions: [], // New state for Deals options
      selectedDeals: [],
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
      screenloader: false,
      pooldetails: {},
      bdbUrl: "",
      formData1: {
        "First Payment Date": "",
      },
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickSummary = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
    });
    window.location.assign("/investor/summaryAnalytics");
  };

  handleClickStrats = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
      investorTab3: false,
    });
    window.location.assign("/investor/stratsAnalytics");
  };

  handleClickPerformance = () => {
    this.setState({
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
    });
    window.location.assign("/investor/performanceAnalytics");
  };

  handleSelectChange(selectedOption) {
    this.setState({ selectedAssetClass: selectedOption });
  }

  handleIssuerChange = (selectedOptions) => {
    this.setState({ selectedIssuer: selectedOptions, screenloader: true });
  };

  handleDealsChange = (selectedOptions) => {
    this.setState({ selectedDeals: [selectedOptions] });
  };

  GetDataByDefaultValue = async () => {
    var data = {};
    data.UserId = sessionStorage.getItem("user_id");
    data.UserName = sessionStorage.getItem("user_name");

    console.log("datata", data);
    this.setState({
      screenloader: true,
      tableData: [],
      loading: true,
    });
    const APIResponse = await GetDataByDefaultValue(data);
    // console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const assetClassArray = APIResponse.data.result.asset_class;
      const assetClassOptions = assetClassArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all asset classes as selected by default
      const allAssetClassesSelected = assetClassOptions;

      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all issuers as selected by default
      const allIssuersSelected = issuerOptions;

      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      // Select the first deal by default
      const firstDealSelected =
        dealsOptions.length > 0 ? [dealsOptions[0]] : null;

      this.setState({
        getLoansLoader: false,
        AssetClass: assetClassArray,
        assetClassOptions: assetClassOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: firstDealSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateAsset = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    data.asset_class = selectedAssetClass.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateAsset(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      this.setState({
        getLoansLoader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        // Deals: dealsArray,
        // dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        // selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateIssuer = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    data.issuer_group = selectedIssuer.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateIssuer(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      // Select the first deal by default
      const firstDealSelected =
        dealsOptions.length > 0 ? [dealsOptions[0]] : null;

      this.setState({
        getLoansLoader: false,
        screenloader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        // IssuerGroup: issuerArray,
        // issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: firstDealSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    // this.bdbapi();
    await this.GetDataByDefaultValue();
    // await this.GetDataByTableChartData();
  }

  async componentDidUpdate(prevProps, prevState) {
    // Handle initial null state and ensure arrays before mapping for selectedAssetClass
    const prevAssetValues = prevState.selectedAssetClass
      ? prevState.selectedAssetClass.map((item) => item.value)
      : [];
    const currentAssetValues = this.state.selectedAssetClass
      ? this.state.selectedAssetClass.map((item) => item.value)
      : [];

    // Handle initial null state and ensure arrays before mapping for selectedIssuer
    const prevIssuerValues = prevState.selectedIssuer
      ? prevState.selectedIssuer.map((item) => item.value)
      : [];
    const currentIssuerValues = this.state.selectedIssuer
      ? this.state.selectedIssuer.map((item) => item.value)
      : [];

    // Handle initial null state and ensure arrays before mapping for selectedDeals
    const prevDealsValues = prevState.selectedDeals
      ? prevState.selectedDeals.map((item) => item.value)
      : [];
    const currentDealsValues = this.state.selectedDeals
      ? this.state.selectedDeals.map((item) => item.value)
      : [];

    // Check if the selectedAssetClass values have changed
    if (
      JSON.stringify(prevAssetValues) !== JSON.stringify(currentAssetValues)
    ) {
      await this.GetDataByUpdateAsset();
    }

    // Check if the selectedIssuer values have changed
    if (
      JSON.stringify(prevIssuerValues) !== JSON.stringify(currentIssuerValues)
    ) {
      await this.GetDataByUpdateIssuer();
    }
  }

  render() {
    const customStyles = {
      control: (provided) => ({
        ...provided,
        border: "1px solid black",
        borderRadius: "8px",
        minHeight: "38px",
        boxShadow: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#adaeaa",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensures the dropdown is above other elements
      }),
      indicatorSeparator: (provided) => ({
        display: "none", // Removes the vertical line
      }),
    };

    return (
      <React.Fragment>
        <div className="">
          <Sidebar1 activeComponent={"DealAnalytics"} />
          <div className="content-bdb">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h3 className="headerdashboard">DEAL</h3>
                      </div>
                    </div>
                  </div>

                  <div className="FixTails">
                    <div className="dropdown-row">
                      <div className="dropdown-container">
                        <label className="dropdown-label">Asset Class:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.assetClassOptions}
                            value={this.state.selectedAssetClass}
                            onChange={this.handleSelectChange}
                            isClearable
                            placeholder="Select any one"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Issuer Group:</label>
                        <div className="dropdown-content-topBar">
                          <MultiSelect
                            options={this.state.issuerOptions}
                            value={this.state.selectedIssuer}
                            onChange={this.handleIssuerChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect"
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Deals:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.dealsOptions}
                            value={this.state.selectedDeals}
                            onChange={this.handleDealsChange}
                            isClearable
                            placeholder="Select deals"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Date:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            // options={this.state.assetClassOptions}
                            // value={this.state.selectedAssetClass}
                            // onChange={this.handleSelectChange}
                            isClearable
                            placeholder="Select any one"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>
                    </div>

                    <DataDisplaySummary />

                    <div className="SummaryStds">
                      <div className="col-7 col-sm-6 col-md-7 hellocard">
                        <button
                          type="button"
                          onClick={() => this.handleClickSummary()}
                          className={
                            this.state.investorTab1 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Summary
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickStrats()}
                          className={
                            this.state.investorTab2 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Strats
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickPerformance()}
                          className={
                            this.state.investorTab3 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Performance
                        </button>
                      </div>

                      <div className="IDA_Virtual">
                        <div className="IDA_Icons">
                          <img src={IDA} alt="VirtualAsst" />
                          <img src={BETA} alt="VirtualAsst" className="beta" />
                        </div>{" "}
                        <ChatAssistant />
                      </div>
                    </div>
                  </div>

                  <div className="scrollable-content-dash workbench-table-container">
                    <PerformanceDropDowns />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(DealAnalyticsPerformance);
