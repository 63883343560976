import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Strats from "./Strats";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

export default function StratsDropDowns({
  selectedDeals,
  selectedIssuer,
  selectedAssetClass,
  selectedDates,
  startDate,
  endDate,
}) {
  const options = [
    { label: "Dec-22", value: "Dec-22" },
    { label: "Nov-22", value: "Nov-22" },
    { label: "Oct-22", value: "Oct-22" },
    { label: "Sep-22", value: "Sep-22" },
    { label: "Aug-22", value: "Aug-22" },
    { label: "Jul-22", value: "Jul-22" },
    { label: "Jun-22", value: "Jun-22" },
    { label: "May-22", value: "May-22" },
    { label: "Apr-22", value: "Apr-22" },
    { label: "Mar-22", value: "Mar-22" },
    { label: "Feb-22", value: "Feb-22" },
    { label: "Jan-22", value: "Jan-22" },
  ];

  const PrimaryStratOptions = [
    { label: "Account Status", value: "Account Status" },
    { label: "Balloon Flag", value: "Balloon Flag" },
    { label: "Collateral Type", value: "Collateral Type" },
    { label: "Current Principal Balance", value: "Current Principal Balance" },
    { label: "DTI", value: "DTI" },
    { label: "FICO", value: "FICO" },
    { label: "Guarantor Type", value: "Guarantor Type" },
    { label: "Interest Rate Type", value: "Interest Rate Type" },
    { label: "Lien", value: "Lien" },
    { label: "Loan Modification Type", value: "Loan Modification Type" },
    { label: "Loan-to-Cost", value: "Loan-to-Cost" },
    { label: "LTV", value: "LTV" },
    { label: "Net Interest Rate", value: "Net Interest Rate" },
    { label: "Occupancy Type", value: "Occupancy Type" },
    { label: "Property City", value: "Property City" },
    { label: "Property State", value: "Property State" },
    { label: "Property Type", value: "Property Type" },
    { label: "Purpose", value: "Purpose" },
    { label: "Current Interest Rate", value: "Current Interest Rate" },
    { label: "Remaining Term", value: "Remaining Term" },
  ];

  const SecondaryStratOptions = [
    { label: "Account Status", value: "Account Status" },
    { label: "Balloon Flag", value: "Balloon Flag" },
    { label: "Collateral Type", value: "Collateral Type" },
    { label: "Current Principal Balance", value: "Current Principal Balance" },
    { label: "DTI", value: "DTI" },
    { label: "FICO", value: "FICO" },
    { label: "Guarantor Type", value: "Guarantor Type" },
    { label: "Interest Rate Type", value: "Interest Rate Type" },
    { label: "Lien", value: "Lien" },
    { label: "Loan Modification Type", value: "Loan Modification Type" },
    { label: "Loan-to-Cost", value: "Loan-to-Cost" },
    { label: "LTV", value: "LTV" },
    { label: "Net Interest Rate", value: "Net Interest Rate" },
    { label: "Occupancy Type", value: "Occupancy Type" },
    { label: "Property City", value: "Property City" },
    { label: "Property State", value: "Property State" },
    { label: "Property Type", value: "Property Type" },
    { label: "Purpose", value: "Purpose" },
    { label: "Current Interest Rate", value: "Current Interest Rate" },
    { label: "Remaining Term", value: "Remaining Term" },
  ];

  const TertiaryStratOptions = [
    { label: "Account Status", value: "Account Status" },
    { label: "Balloon Flag", value: "Balloon Flag" },
    { label: "Collateral Type", value: "Collateral Type" },
    { label: "Current Principal Balance", value: "Current Principal Balance" },
    { label: "DTI", value: "DTI" },
  ];

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid black",
      borderRadius: "8px",
      minHeight: "38px",
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#adaeaa",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensures the dropdown is above other elements
    }),
    indicatorSeparator: (provided) => ({
      display: "none", // Removes the vertical line
    }),
  };

  const [selectedAsOf, setSelectedAsOf] = useState({
    label: "Jan-22",
    value: "Jan-22",
  });
  const [selectedPrimary, setSelectedPrimary] = useState([
    { label: "Balloon Flag", value: "Balloon Flag" },
  ]);
  const [selectedSecondary, setSelectedSecondary] = useState({
    label: "Property Type",
    value: "Property Type",
  });
  const [selectedTertiary, setSelectedTertiary] = useState(null);

  // Function to filter options based on selections
  const filterOptions = (options, selectedPrimary, selectedSecondary) => {
    const selectedValues = [
      ...selectedPrimary.map((item) => item.value),
      selectedSecondary?.value,
    ];
    return options.filter((option) => !selectedValues.includes(option.value));
  };

  return (
    <div>
      <div className="align-strats-dropdowns">
        <div className="align-strat-side-dropdowns">
          <div>
            <h6>As of</h6>
            <div className="align-strat-tabs">
              <Select
                options={options}
                value={selectedAsOf}
                onChange={setSelectedAsOf}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
          <div>
            <h6>Primary Strat</h6>
            <div className="align-strat-tabs-primary">
              <MultiSelect
                options={PrimaryStratOptions}
                value={selectedPrimary}
                onChange={setSelectedPrimary}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Secondary Strat</h6>
            <div className="align-strat-tabs">
              <Select
                options={filterOptions(
                  SecondaryStratOptions,
                  selectedPrimary,
                  []
                )}
                value={selectedSecondary}
                onChange={setSelectedSecondary}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
          <div>
            <h6>Tertiary Strat</h6>
            <div className="align-strat-tabs">
              <Select
                options={filterOptions(
                  TertiaryStratOptions,
                  selectedPrimary,
                  selectedSecondary
                )}
                value={selectedTertiary}
                onChange={setSelectedTertiary}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
        </div>
      </div>

      <Strats
        selectedAsOf={selectedAsOf}
        selectedPrimary={selectedPrimary}
        selectedSecondary={selectedSecondary}
        selectedTertiary={selectedTertiary}
        selectedDeals={selectedDeals}
        selectedIssuer={selectedIssuer}
        selectedAssetClass={selectedAssetClass}
        selectedDates={selectedDates}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}
