import React, { useState, useEffect } from "react";
import { Table, Typography } from "antd";
import { ViewStratsTab as fetchViewStratsTab } from "../../../../../servies/services.js";
import NumberComp2 from "../../../../../components/NumberComp2.js";

const { Title } = Typography;

const Strats = ({
  selectedAsOf,
  selectedPrimary,
  selectedSecondary,
  selectedTertiary,
  selectedDeals,
  selectedIssuer,
  selectedAssetClass,
  selectedDates,
  startDate,
  endDate,
}) => {
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});

  const pageSize = 10; // Number of records per page

  // Transforming the array of objects into an array of values
  const selectedIssuerValues = selectedIssuer.map((item) => item.value);

  // console.log("selectedIssuerValues", selectedIssuerValues);

  const getViewStratsTab = async (primaryStrat) => {
    console.log(selectedPrimary, selectedAsOf);
    // const data = {
    //   dealName: selectedDeals[0].label,
    //   date: selectedAsOf ? selectedAsOf.value : "",
    //   primaryStrat,
    //   secondaryStrat: selectedSecondary ? selectedSecondary.value : "",
    //   tertiaryStrat: selectedTertiary ? selectedTertiary.value : "",
    //   assetClass: selectedAssetClass[0].value,
    //   issuer: selectedIssuerValues,
    //   startDate: startDate,
    //   endDate: endDate,
    // };

    const data = {
      dealName: "Test-F",
      date: selectedAsOf ? selectedAsOf.value : "",
      primaryStrat,
      secondaryStrat: selectedSecondary ? selectedSecondary.value : "",
      tertiaryStrat: selectedTertiary ? selectedTertiary.value : "",
      assetClass:
        "Residential Real Estate                                                                             ",
      issuer: "Issuer",
    };

    setLoading(true);
    // Retain existing data while loading new data
    setTableData((prev) => ({
      ...prev,
      [primaryStrat]: prev[primaryStrat] || [],
    }));
    try {
      const response = await fetchViewStratsTab(data);
      const parsedData = JSON.parse(response.data.result);
      // Assuming dynamicFlags are derived from selected values
      const dynamicFlags = selectedPrimary;

      const groupedData = groupDataByDynamicFlag(
        parsedData,
        dynamicFlags,
        selectedSecondary,
        selectedTertiary
      );
      const uniqueGroupedData = Array.from(
        new Map(groupedData.map((item) => [item.key, item])).values()
      );
      setTableData((prev) => ({ ...prev, [primaryStrat]: uniqueGroupedData }));
      // Set default pagination for the newly loaded data
      setPagination((prev) => ({
        ...prev,
        [primaryStrat]: { current: 1, pageSize },
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupDataByDynamicFlag = (
    data,
    dynamicFlags,
    secondaryFlag,
    tertiaryFlag
  ) => {
    const groupedData = data.reduce((acc, item) => {
      dynamicFlags.forEach((flag) => {
        const flagValue = item[flag.value];
        if (
          flag.value !== secondaryFlag?.value &&
          flag.value !== tertiaryFlag?.value &&
          flagValue
        ) {
          if (!acc[flagValue]) {
            acc[flagValue] = { balloonFlag: flagValue, children: [] };
          }
          acc[flagValue].children.push(item);
        }
      });
      return acc;
    }, {});

    return Object.values(groupedData).map((group) => ({
      ...group,
      key: group.balloonFlag,
      // Ensure no duplication here
      children: Array.from(new Set(group.children)),
    }));
  };

  useEffect(() => {
    if (selectedPrimary && selectedPrimary.length > 0) {
      selectedPrimary.forEach((primary) => getViewStratsTab(primary.value));
    }
  }, [
    selectedAsOf,
    selectedPrimary,
    selectedSecondary,
    selectedTertiary,
    selectedDeals,
    selectedDates,
  ]);

  const handlePageChange = (primaryStrat, page) => {
    setPagination((prev) => ({
      ...prev,
      [primaryStrat]: { ...prev[primaryStrat], current: page },
    }));
  };

  const getCurrentPageData = (data, primaryStrat) => {
    const current = pagination[primaryStrat]?.current || 1;
    const startIndex = (current - 1) * pageSize;

    // Iterate over each object in data and slice the children array
    let temp = data.map((item) => {
      const paginatedChildren = item.children[0];
      return {
        ...item,
        ...paginatedChildren,
      };
    });
    return temp;
  };

  const detailColumns = [
    {
      title: "",
      dataIndex: "balloonFlag",
      width: 150,
      key: "balloonFlag",
    },
    { title: "Loan", dataIndex: "Loan", key: "loan", width: 150 },
    {
      title: "Original Balance",
      dataIndex: "Original Balance",
      key: "originalBalance",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
    {
      title: "Current Balance",
      dataIndex: "Current Balance",
      key: "currentBalance",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
    {
      title: "%% of Current Balance",
      dataIndex: "%% of Current Balance",
      key: "percentCurrentBalance",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
    {
      title: "Avg Loan Balance",
      dataIndex: "Avg Loan Balance",
      key: "avgLoanBalance",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
    {
      title: "WAC",
      dataIndex: "WAC",
      key: "wac",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
    {
      title: "WA FICO",
      dataIndex: "WA FICO",
      key: "waFico",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
    {
      title: "WA LTV",
      dataIndex: "WA LTV",
      key: "waLtv",
      width: 150,
      render: (value) => <NumberComp2 value={value} />,
    },
  ];

  // Dynamically add the selected secondary column if it exists
  if (selectedSecondary) {
    detailColumns.push({
      title: selectedSecondary.label,
      dataIndex: selectedSecondary.value,
      key: selectedSecondary.value,
      render: (value) => value?.toLocaleString(),
      width: 150,
    });
  }
  // Dynamically add the selected Tertiary column if it exists
  if (selectedTertiary) {
    detailColumns.push({
      title: selectedTertiary.label,
      dataIndex: selectedTertiary.value,
      key: selectedTertiary.value,
      render: (value) => value?.toLocaleString(),
      width: 150,
    });
  }

  return (
    <div className="table-container">
      {selectedPrimary && selectedPrimary.length > 0 ? (
        selectedPrimary.map((primary) => (
          <div key={primary.value}>
            <Title level={3}>{primary.label}</Title>
            <Table
              className="performance-table"
              columns={detailColumns}
              dataSource={getCurrentPageData(
                tableData[primary.value] || [],
                primary.value
              )}
              rowKey="key"
              pagination={{
                current: pagination[primary.value]?.current || 1,
                pageSize,
                total: tableData[primary.value]?.length || 0,
                onChange: (page) => handlePageChange(primary.value, page),
              }}
              bordered
              loading={loading}
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Strats;
