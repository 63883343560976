/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  DealRecurring,
  CalculatePayments,
  CalculatePrePayments,
  CalculateCollateralPayments,
  CalculateConsolidatedPayments,
  FilesAddDealRecurring,
  FilesRecurring,
  DownloadRowClick,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
} from "../../../../../servies/services";
import NumberComp2 from "../../../../../components/NumberComp2";
import * as moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import BeanEater from "../../../../../images/BeanEater.gif";
import ReactModal from "react-modal";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";

class Files_Recurring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      tableData: [],
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      VersionsChange: [],
      TableName: "PaymentRules",
      formLoader1: false,
      userId: sessionStorage.getItem("user_id"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getLoansLoader: false,
      openPopup: false,
      open1: false,
      open2: false,
      searchText: "",
      isBorrow: false,
      BorrowingBase: "False",
      rowsSelected: null,
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: true,
      activeInsightsBB: false,
      file1: "",
      filename1: "",
      formData: {
        Document: "",
        DocumentType: "",
        FileType: "",
        ReportDate: "",
        Privacy: "UnRestricted",
      },
    };
  }
  showPrev = () => {
    window.location.assign("/admin/consolidated_recurring");
  };
  //   showNext = () => {
  //     window.location.assign("/admin/collateral_recurring");
  //   };
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.PaymentRules(this.state.DealName)}>
          Deal
        </button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        {/* <hr className="servicer-popover-hr" />
      <button>Change Period</button> */}
      </Popover>
    );
  };

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  onOpenModal = () => {
    this.setState({ open1: true });
  };
  onCloseModal = () => {
    this.setState({ open1: false });
  };
  onOpenModal1 = () => {
    this.setState({ open2: true });
  };
  onCloseModal1 = () => {
    this.setState({ open2: false });
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/tranches_recurring");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/accounts_recurring");
  };
  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/tests_recurring");
  };
  handleBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: true,
    });
    window.location.assign("/admin/borrowingbase_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/payment_recurring");
  };
  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/collateral_recurring");
  };

  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: true,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/loantape_recurring");
  };

  handleClickConsolidated = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: true,
      activeInsights12: false,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/consolidated_recurring");
  };

  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: true,
      activeInsightsBB: false,
    });
    window.location.assign("/admin/files_recurring");
  };

  DealRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.Version;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await DealRecurring(data);

    console.log("paymentrulesDealRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        loading: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onCloseModalBorrow = () => {
    this.setState({ isBorrow: false });
  };
  onOpenModalBorrow = () => {
    this.setState({ isBorrow: true });
  };
  checkBorrowBaseYes = async () => {
    this.setState(
      { calculateBorrow: true, BorrowingBase: "True", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };
  checkBorrowBaseNo = async () => {
    this.setState(
      { calculateBorrow: false, BorrowingBase: "False", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        // const message = APIResponse.data.message;
        // this.props.enqueueSnackbar(message, {
        //   variant: "info",
        //   autoHideDuration: 3000,
        // });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.BorrowingBase = this.state.BorrowingBase;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("PaymentCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateCollateralPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateCollateralPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateCollateralPayments(data);

    console.log("GeneralCalculateCollateralPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        window.location.assign("/admin/files_recurring");
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  PaymentRules = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/paymentrules",
      state: { checkRecurring: true },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: false,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringBorrowingBase: false,
        checkRecurringPaymentRules: true,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState(
      { DealName: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.DealName);
        await this.getAllPaymentDatesByDealName();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (Version === "WIP" || getDashboardVersions.length === 1) {
          this.setState({ isDisable: false });
          this.FilesRecurring();
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({ isDisable: this.state.isDisable });
            this.FilesRecurring();
          } else {
            this.setState({ isDisable: false });
            this.FilesRecurring();
          }
        }
      }
    );
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState(
      { NextPaymentDate: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        await this.getAllVersionsByPaymentDate();

        if (this.state.isLoansProcessed === "No") {
          window.location.assign("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (Version === "WIP" || getDashboardVersions.length === 1) {
          this.setState({ isDisable: false });
          this.FilesRecurring();
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({ isDisable: this.state.isDisable });
            this.FilesRecurring();
          } else {
            this.setState({ isDisable: false });
            this.FilesRecurring();
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.FilesRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call FilesRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.FilesRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.FilesRecurring();
            });
          }
        }
      }
    );
  };

  handleOnChange1 = (e) => {
    this.setState({
      file1: e.target.files[0],
      filename1: e.target.files[0].name,
    });
    console.log("eeee", e.target.files[0].name);
    console.log("file1", e.target.files[0]);
  };

  AddSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formData);

    this.setState({
      formData: {
        Document: "",
        DocumentType: "",
        FileType: "",
        ReportDate: "",
        Privacy: "UnRestricted",
      },
      formLoader1: true,
      file1: "",
    });

    this.FilesAddDealRecurring();
  };

  FilesAddDealRecurring = async () => {
    this.setState({ formLoader: true });

    const newdata = new FormData();
    newdata.append("DealName", this.state.DealName);
    newdata.append("Document", this.state.formData.Document);
    newdata.append("DocumentType", this.state.formData.DocumentType);
    newdata.append("FileType", this.state.formData.FileType);
    newdata.append("ReportDate", this.state.formData.ReportDate);
    newdata.append("Privacy", this.state.formData.Privacy);
    newdata.append("File", this.state.file1);
    newdata.append("userId", this.state.userId);
    newdata.append("peers", this.state.peers);
    console.log("newdata", newdata);

    const APIResponse = await FilesAddDealRecurring(newdata);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.success === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.FilesRecurring();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  FilesRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await FilesRecurring(data);

    console.log("FilesRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        openModalPayment: false,
        loading: false,
        formLoader1: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //window.location.assign("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
        openModalPayment: false,
      });
      const message = "Something Went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  DownloadRowClick = async (tableData) => {
    console.log(tableData);
    this.setState({ loading: true });
    const data = {};
    data.documentID = tableData[7];
    data.peer = this.state.peer;

    const newDoc = tableData[0];
    console.log("datata", data);
    const APIResponse = await DownloadRowClick(data);

    if (APIResponse.status === 200) {
      let fileType = "";

      if (tableData[2] === "PDF") {
        fileType = "application/pdf";
      } else if (tableData[2] === "CSV") {
        fileType = "text/csv";
      } else if (tableData[2] === "Excel") {
        fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }

      // // Your input string
      // const inputString = APIResponse.data;

      // // Create a TextEncoder
      // const textEncoder = new TextEncoder();

      // // Encode the string into bytes
      // const encodedBytes = textEncoder.encode(inputString);

      // // Convert the bytes to binary representation
      // const binaryString = Array.from(encodedBytes, byte => byte.toString(2)).join(' ');

      // console.log(binaryString);

      // Create a Blob containing the Excel data
      const blob = new Blob([APIResponse.data], { type: fileType });

      // Create a download URL for the Blob
      const downloadUrl = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", newDoc);
      document.body.appendChild(link);

      // Trigger a click event to download the file
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = "Something Went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  getAcceptedFormats() {
    const selectedFileType = this.state.formData.FileType;
    let acceptedFormats = "";

    if (selectedFileType === "PDF") {
      acceptedFormats = ".pdf";
    } else if (selectedFileType === "CSV") {
      acceptedFormats = ".csv";
    } else if (selectedFileType === "Excel") {
      acceptedFormats =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }

    return acceptedFormats;
  }

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);

    if (this.state.isLoansProcessed === "No") {
      window.location.assign("/admin/general_recurring");
    }

    const { Version, getDashboardVersions } = this.state;

    if (Version === "WIP" || getDashboardVersions.length === 1) {
      this.setState({ isDisable: false });
      this.FilesRecurring();
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        this.setState({ isDisable: this.state.isDisable });
        this.FilesRecurring();
      } else {
        this.setState({ isDisable: false });
        this.FilesRecurring();
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isDisable: this.state.isDisable });
    //   this.FilesRecurring();
    // } else {
    //   this.setState({ isDisable: false });
    //   this.FilesRecurring();
    // }
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
      },
    });

  render() {
    const sortedDeals = [...this.state.getDashboardDeals].sort();
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        name: "Document",
        label: "Document",
        options: {
          filter: false, // Disable filtering for the number column
          sort: false, // Disable sorting for the number column
          // customBodyRender: (value, tableMeta) => {
          //   const rowIndex = tableMeta.rowIndex;
          //   const uniqueNumber = rowIndex + 1; // Add 1 to start numbering from 1

          //   return <div style={{"textAlign":"center"}}>{uniqueNumber}</div>;
          // },
        },
      },
      {
        name: "Document Type",
        label: "Document Type",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "File Type",
        label: "File Type",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "Report Date",
        label: "Report Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "File Status",
        label: "File Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "File Size",
        label: "File Size",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Privacy",
        label: "Privacy",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Document ID",
        options: {
          display: false,
        },
      },
      {
        name: "Document",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "20px",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="">
                  <span>
                    <button
                      className="popupbutton1"
                      // onClick={() =>
                      //   // this.onOpenModal2(
                      //   //   value,
                      //     // tableMeta.rowData[7],
                      //     // tableMeta.rowData.splice(1, 4)
                      //   // )
                      //   console.log("-1-1-1")
                      // }
                      onClick={() =>
                        this.DownloadRowClick(tableMeta.rowData.splice(0, 8))
                      }
                    >
                      Download
                    </button>
                  </span>
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Reporting"} />
          <div className="content">
            <div className="header">
              <Header></Header>
            </div>
            <div className="page-contentofpool1">
              <div className="row1 ">
                <div
                  className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1.5rem",
                    width: "90rem",
                    position: "relative",
                    left: "0.2rem",
                  }}
                >
                  <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                    {/* <KeyboardBackspaceIcon
                      onClick={this.goBackToDashboard}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon> */}
                    <h3 className="headerdashboard">Reporting</h3>
                  </div>
                </div>
              </div>

              <div className="row1 css-recurring move-dropdowns-reporting">
                <div className="recurring_details_new_Reporting">
                  <div>
                    <label className="dealInfo">Deal Name </label>
                    {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                    <div>
                      <select
                        className="input-select-general-new1-deal"
                        value={this.state?.DealName}
                        onChange={this.handleDealNameChange}
                      >
                        {sortedDeals.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="dealInfo">Payment Date </label>
                    {/* <h6 className="dealInfo1">
                          {this.state?.NextPaymentDate}
                        </h6> */}
                    <div>
                      <select
                        className="input-select-general-new1"
                        value={this.state?.NextPaymentDate}
                        onChange={this.handlePaymentDateChange}
                      >
                        {this.state.getDashboardDates.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="dealInfo">Version </label>
                    {/* <h6 className="dealInfo1">{this.state?.Version}</h6> */}
                    <div>
                      <select
                        className="input-select-general-new1"
                        value={
                          this.state.Version === "WIP"
                            ? sessionStorage.getItem("version")
                            : this.state.Version
                        }
                        onChange={this.handleVersionChange}
                      >
                        {this.state.getDashboardVersions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row1 movement-tabs-reporting">
                <div>
                  {/* <div className="tablechangebutton"> */}
                  <div className="tablechangebuttonloans">
                    {/* <button
                      type="button"
                      onClick={() => this.handleClickGeneral()}
                      className={
                        this.state.activeInsights1 === true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      General
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickTranches()}
                      className={
                        this.state.activeInsights2 === true
                          ? "issuerDashboard-table-top-button-workbench-active"
                          : "issuerDashboard-table-top-button-workbench"
                      }
                    >
                      Tranches
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickFees()}
                      className={
                        this.state.activeInsights3 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Fees
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickExpenses()}
                      className={
                        this.state.activeInsights4 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Expenses
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickAccounts()}
                      className={
                        this.state.activeInsights5 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Accounts
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickTests()}
                      className={
                        this.state.activeInsights6 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Tests
                    </button>
                    {this.state.isSecuritisation !== "Securitisation" ? (
                      <button
                        type="button"
                        onClick={() => this.handleBorrowingBase()}
                        className={
                          this.state.activeInsightsBB == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Borrowing Base
                      </button>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => this.handleClickVariables()}
                      className={
                        this.state.activeInsights7 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Variables
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickPaymentRules()}
                      className={
                        this.state.activeInsights8 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Payment Rules
                    </button> */}
                    <button
                      type="button"
                      onClick={() => this.handleClickCollateral()}
                      className={
                        this.state.activeInsights9 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Collateral
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickLoanTape()}
                      className={
                        this.state.activeInsights10 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Loan Tape
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickConsolidated()}
                      className={
                        this.state.activeInsights11 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Consolidated
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickFiles()}
                      className={
                        this.state.activeInsights12 === true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Files
                    </button>
                  </div>
                </div>
                {/* <div className="recurring_details">
                      <div>
                        <label className="dealInfo">Deal Name </label>
                        <h6 className="dealInfo1">{this.state.DealName}</h6>
                      </div>
                      <div>
                        <label className="dealInfo">Payment Date </label>
                        <h6 className="dealInfo1">{this.state?.NextPaymentDate}</h6>
                      </div>
                    </div> */}
              </div>

              <div className="file_reporting">
                <div style={{ display: "flex", paddingBottom: "0.3rem",paddingTop: "1.3rem" }}>
                  <h3 className="headerdashboard">Add File</h3>
                </div>
                <form className="" id="form1" onSubmit={this.AddSubmit}>
                  <div className="file_details_new">
                    <div className="file_row">
                      <div className="file_item">
                        <label className="dealInfo">Document</label>
                        <input
                          required
                          className="input-general-file"
                          value={this.state.formData.Document}
                          onChange={(e) => {
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                Document: e.target.value,
                              },
                            });
                          }}
                          placeholder="Type Here"
                        />
                      </div>
                      <div className="file_item">
                        <label className="dealInfo">Document Type</label>
                        <div>
                          <select
                            required
                            className="input-select-general-file"
                            value={this.state.formData.DocumentType}
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  DocumentType: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="" className="selectclass">
                              Select any one
                            </option>
                            <option value="Loan Tape">Loan Tape</option>
                            <option value="Notices">Notices</option>
                            <option value="Modifications">Modifications</option>
                            <option value="Deal Document">Deal Document</option>
                            <option value="Deal Disclousre">
                              Deal Disclousre
                            </option>
                            <option value="Officers Certificates">
                              Officers Certificates
                            </option>
                            <option value="Servicer Disclousre">
                              Servicer Disclousre
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="file_item">
                        <label className="dealInfo">File Type</label>
                        <div>
                          <select
                            required
                            className="input-select-general-file"
                            value={this.state.formData.FileType}
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  FileType: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="" className="selectclass">
                              Select any one
                            </option>
                            <option value="PDF">PDF</option>
                            <option value="CSV">CSV</option>
                            <option value="Excel">Excel</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="file_row">
                      <div className="file_item">
                        <label className="dealInfo">Report Date</label>
                        <div>
                          <select
                            required
                            className="input-select-general-file"
                            value={this.state.formData.ReportDate}
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  ReportDate: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="" className="selectclass">
                              Select any one
                            </option>
                            {this.state.getDashboardDates.map((option) => (
                              <>
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="file_item">
                        <label className="dealInfo">Privacy</label>
                        <div>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100px",
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              checked={
                                this.state.formData.Privacy === "Restricted"
                              }
                              onChange={(e) => {
                                const newValue = e.target.checked
                                  ? "Restricted"
                                  : "UnRestricted";
                                this.setState((prevState) => ({
                                  formData: {
                                    ...prevState.formData,
                                    Privacy: newValue,
                                  },
                                }));
                              }}
                            />
                            Restricted
                          </span>
                        </div>
                      </div>
                      <div className="file_item">
                        <label className="dealInfo">Upload Document</label>
                        <div className="servicer-popup-upload-btn-contianer">
                          <div className="kyc-card__button-container1">
                            <div>
                              {this.state.formData.FileType === "" ? (
                                <button
                                  className="card__button"
                                  style={{
                                    position: "relative",
                                  }}
                                  disabled
                                >
                                  <label
                                    htmlFor="icon-button-file-id21"
                                    className="upload-button-label1"
                                  >
                                    Upload
                                  </label>
                                  <input
                                    required
                                    id="icon-button-file-id21"
                                    type="file"
                                    accept={this.getAcceptedFormats()}
                                    style={{
                                      position: "absolute",
                                      width: "60%",
                                      height: "100%",
                                      cursor: "pointer",
                                      top: "0",
                                      right: "0px",
                                      opacity: "0",
                                      border: "1.2px solid #212121",
                                    }}
                                    onChange={this.handleOnChange1}
                                    disabled
                                  />
                                </button>
                              ) : (
                                <button
                                  className="card__button"
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <label
                                    htmlFor="icon-button-file-id21"
                                    className="upload-button-label1"
                                  >
                                    Upload
                                  </label>
                                  <input
                                    required
                                    id="icon-button-file-id21"
                                    type="file"
                                    accept={this.getAcceptedFormats()}
                                    style={{
                                      position: "absolute",
                                      width: "60%",
                                      height: "100%",
                                      cursor: "pointer",
                                      top: "0",
                                      right: "0px",
                                      opacity: "0",
                                      border: "1.2px solid #212121",
                                    }}
                                    onChange={this.handleOnChange1}
                                  />
                                </button>
                              )}
                            </div>
                            {this.state.file1 !== "" && (
                              <div className="kyc-card__select_name-container">
                                <p>{this.state.filename1}</p>
                                {/* <button type="button" onClick={handleClickCross}>
                          x
                      </button> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="rowfile">
                  <div
                    className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "10rem",
                    }}
                  ></div>
                  <div className="col-12 col-sm-6 col-md-5 hellocard">
                    <div className="buttonsverified uw-deal-details-button-container">
                      {this.state.isLoansProcessed === "No" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          form="form1"
                          disabled
                        >
                          Add
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          form="form1"
                        >
                          Add
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row row1">
                  {/* <div className="dashboard-top-right-container1">
                  <div className="search-bar-outer-container uw-search-button-container">
                    {
                      <button
                      type="button"
                      className="popupbutton-css"
                        onClick={()=>this.onOpenModal1()}
                      >
                        Configure
                      </button>
                    }
                  </div>
                </div> */}
                </div>
              </div>

              <React.Fragment>
                <div className="workbench-table-container movement-table-reporting">
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      // title={'Dashboard'}
                      data={this.state.tableData}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                  <ReactModal
                    isOpen={this.state.openModalPayment}
                    contentLabel="Minimal Modal Example"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "fixed", // Use fixed positioning for the overlay
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                      },
                      content: {
                        position: "absolute",
                        background: "transparent", // Make the modal content transparent
                        border: "none", // Optional: Remove any borders
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        overflow: "hidden",
                      },
                    }}
                  >
                    <React.Fragment>
                      <img
                        src={BeanEater}
                        style={{
                          width: "8vw",
                          height: "9vh",
                          backgroundColor: "transparent",
                        }}
                        alt="Bean Eater"
                      />
                      <h3 className="dot-loader">Loading.Please wait</h3>
                    </React.Fragment>
                  </ReactModal>

                  <div id="modal1">
                    <ReactModal
                      isOpen={this.state.isBorrow}
                      onRequestClose={this.onCloseModalBorrow}
                      contentLabel="Minimal Modal Example"
                      style={customStylesautosmallmodalpopupBorrow}
                    >
                      <React.Fragment>
                        <div className="modalPopup">
                          <h3 className="popupheading">
                            Do you want to run Payment Waterfall?
                          </h3>

                          {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                          <div className="modalshiftcontent">
                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <div className="row">
                                  <div className="row justify-content-start11">
                                    <button
                                      type="button"
                                      className="popupbutton1"
                                      onClick={this.onCloseModalBorrow}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="row justify-content-end11">
                                    <button
                                      type="button"
                                      className="popupbutton1"
                                      onClick={this.checkBorrowBaseNo}
                                    >
                                      No
                                    </button>

                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                      onClick={this.checkBorrowBaseYes}
                                    >
                                      Yes
                                      {/* {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )} */}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </ReactModal>
                  </div>
                </div>
                <>
                  <div className="btn_move">
                    <div className="btn_prev" onClick={this.showPrev}>
                      Previous
                    </div>
                    <button
                      className="btn_next"
                      onClick={this.showNext}
                      disabled
                    >
                      Next
                    </button>
                  </div>
                </>
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Files_Recurring);
