/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Header from "../../../../../components/header/index.js";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../../../components/loader/index.js";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  GetDataByDefaultValue,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
  GetDataByTableChartDataDeal,
  GetDataByPaymentDatesDeal,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import Iframe from "react-iframe";
import NumberComp2 from "../../../../../components/NumberComp2.js";
import Performance from "./Performance.js";
import SummaryChart from "./SummaryChart.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import DataDisplaySummary from "./DataDisplaySummary.js";
import SummaryDash from "./SummaryDash.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { format } from "date-fns";
import "./DealSummary.css";
import ChatAssistant from "./ChatAssistant.js";
import IDA from "../../../../../images/IDA.svg";
import BETA from "../../../../../images/BETA.svg";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

class DealAnalyticsSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      showDatepicker: false,
      showApplyChange: false,
      hasLoaded: false,
      startDate: new Date("2022-01-01"), // Setting startDate to May 1, 2024
      endDate: new Date("2024-07-25"),
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      AssetClass: [],
      assetClassOptions: [],
      selectedAssetClass: [], // Selected option for react-select
      IssuerGroup: [],
      issuerOptions: [], // New state for Issuer options
      selectedIssuer: [], // New state for selected Issuers
      dealsOptions: [], // New state for Deals options
      selectedDeals: [],
      dateOptions: [],
      selectedDates: [],
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
      screenloader: false,
      pooldetails: {},
      bdbUrl: "",
      formData1: {
        "First Payment Date": "",
      },
    };
    // Bind the handleSelectChange function
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDealsChange = this.handleDealsChange.bind(this);
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickSummary = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
    });
    window.location.assign("/investor/summaryAnalytics");
  };

  handleClickStrats = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
      investorTab3: false,
    });
    window.location.assign("/investor/stratsAnalytics");
  };

  handleClickPerformance = () => {
    this.setState({
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
    });
    window.location.assign("/investor/performanceAnalytics");
  };

  toggleDatepicker = () => {
    this.setState((prevState) => ({
      showDatepicker: !prevState.showDatepicker,
    }));
  };

  handleStartDateChange = (date) => {
    this.setState((prevState) => ({
      startDate: date,
      endDate:
        prevState.endDate && date > prevState.endDate
          ? null
          : prevState.endDate,
    }));
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  toggleDatepicker = () => {
    this.setState((prevState) => ({
      showDatepicker: !prevState.showDatepicker,
    }));
  };

  applyDateRange = () => {
    this.setState((prevState) => ({
      showApplyChange: !prevState.showApplyChange,
    }));
    console.log("endDate", this.state.endDate);
  };

  formatDate = (date) => {
    try {
      if (!date || isNaN(new Date(date))) return "";
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(date));
    } catch (error) {
      console.error("Error formatting date:", error, date);
      return "";
    }
  };

  formatDateForData = (date) => {
    try {
      if (!date || isNaN(new Date(date))) return "";
      return format(new Date(date), "dd-MM-yyyy");
    } catch (error) {
      console.error("Error formatting date for data:", error, date);
      return "";
    }
  };

  handleIssuerChange = (selectedOptions) => {
    this.setState({ selectedIssuer: selectedOptions });
  };

  handleDealsChange = (selectedOptions) => {
    this.setState({
      selectedDeals: [selectedOptions],
    });
  };
  handleDatesChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions);

    const startDate =
      this.state.dateOptions.length > 0 ? this.state.dateOptions[0].value : "";

    // If selectedOptions is null (cleared), set startDate and selectedDates accordingly
    if (!selectedOptions) {
      this.setState({
        selectedDates: [],
        startDate: startDate,
        // endDate: "",
      });
      return;
    }

    const endDate =
      this.state.dateOptions.length > 0
        ? this.state.dateOptions[this.state.dateOptions.length - 1].value
        : "";

    this.setState({
      selectedDates: [selectedOptions],
      startDate: selectedOptions.value,
      endDate: endDate,
    });
  };

  handleSelectChange(selectedOption) {
    this.setState({ selectedAssetClass: selectedOption });
  }

  handleIssuerChange = (selectedOptions) => {
    this.setState({ selectedIssuer: selectedOptions, screenloader: true });
  };

  GetDataByDefaultValue = async () => {
    var data = {};
    data.UserId = sessionStorage.getItem("user_id");
    data.UserName = sessionStorage.getItem("user_name");

    console.log("datata", data);
    this.setState({ screenloader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByDefaultValue(data);
    // console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const assetClassArray = APIResponse.data.result.asset_class;
      const assetClassOptions = assetClassArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all asset classes as selected by default
      const allAssetClassesSelected = assetClassOptions;

      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      // Set all issuers as selected by default
      const allIssuersSelected = issuerOptions;

      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      console.log("dealsOptions", dealsOptions);

      // Select the first deal by default
      const firstDealSelected =
        dealsOptions.length > 0 ? [dealsOptions[0]] : null;
      // Select the first deal by default
      const startDateSelected = APIResponse.data.result.start_date;
      const endDateSelected = APIResponse.data.result.end_date;

      console.log("firstDealSelected", firstDealSelected);

      this.setState({
        getLoansLoader: false,
        AssetClass: assetClassArray,
        assetClassOptions: assetClassOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: firstDealSelected, // Set all deals as selected
        startDate: startDateSelected, // Set all deals as selected
        endDate: endDateSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateAsset = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    data.asset_class = selectedAssetClass.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateAsset(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      this.setState({
        getLoansLoader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        IssuerGroup: issuerArray,
        issuerOptions: issuerOptions, // Set issuer options
        // Deals: dealsArray,
        // dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        // selectedDeals: allDealsSelected, // Set all deals as selected
        loading: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateIssuer = async () => {
    var data = {};
    data.UserName = sessionStorage.getItem("user_name");
    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    data.issuer_group = selectedIssuer.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateIssuer(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      // Select the first deal by default
      const firstDealSelected =
        dealsOptions.length > 0 ? [dealsOptions[0]] : null;

      this.setState({
        getLoansLoader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        // IssuerGroup: issuerArray,
        // issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: firstDealSelected, // Set all deals as selected
        loading: false,
        screenloader: false,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
      });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByTableChartDataDeal = async () => {
    const { selectedDeals, Deals, startDate, endDate } = this.state;

    // Safely map selected deals back to their original structure
    const selectedDealsOriginal = (
      Array.isArray(selectedDeals) ? selectedDeals : []
    )
      .map((selectedDeal) => {
        if (!selectedDeal || !selectedDeal.value) return null; // Safeguard against null or undefined selectedDeal
        const originalDeal = Deals.find(
          (deal) => deal && Object.keys(deal)[0] === selectedDeal.value // Ensure deal is not null
        );
        return originalDeal || null; // Return null if no original deal found
      })
      .filter(Boolean); // Remove any null values

    console.log("selectedDealsOriginal", selectedDealsOriginal);

    var data = {};

    // Ensure selectedAssetClass is an array before using map
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    // Ensure selectedIssuer is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    const formattedStartDate = this.formatDateForData(startDate);
    const formattedEndDate = this.formatDateForData(endDate);
    console.log("formattedStartDate", formattedStartDate, formattedEndDate);

    data.asset_class = selectedAssetClass
      .map((item) => item && item.value)
      .filter(Boolean);
    data.issuer_group = selectedIssuer
      .map((item) => item && item.value)
      .filter(Boolean);
    data.deal_type = selectedDealsOriginal;
    data.start_date = startDate;
    data.end_date = endDate;
    console.log("datata", data);

    this.setState({
      screenloader: true,
      tableData: [],
      loading: true,
      // selectedDeals: selectedDealsOriginal,
    });

    try {
      const APIResponse = await GetDataByTableChartDataDeal(data);
      console.log("APIResponse", APIResponse);

      if (APIResponse.status === 200) {
        this.setState({
          getLoansLoader: false,
          screenloader: false,
        });
        sessionStorage.setItem(
          "finalDataSummary",
          JSON.stringify(APIResponse.data.result)
        );
        console.log(">>>>>>>>>>>>>", APIResponse.data.result.table);
        sessionStorage.setItem(
          "finalDataTableSummary",
          JSON.stringify(APIResponse.data.result.table)
        );
      } else {
        this.setState({ getLoansLoader: false, loading: false });
        const message = "Something went wrong";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ getLoansLoader: false, loading: false });
      this.props.enqueueSnackbar("An error occurred while fetching data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  GetDataByPaymentDatesDeal = async () => {
    const { selectedDeals, selectedDates, Deals, startDate, endDate } =
      this.state;

    var data = {};
    data.deal_id = selectedDeals[0].value;

    this.setState({
      screenloader: true,
      loading: true,
      selectedDates: [{ value: "All", label: "All" }],
    });

    try {
      const APIResponse = await GetDataByPaymentDatesDeal(data);
      console.log("APIResponse", APIResponse);

      if (APIResponse.status === 200) {
        const dateOptions = APIResponse.data.payment_dates.map((date) => {
          // Assuming `date` is a string, directly map it to the object format
          return { value: date, label: date };
        });

        console.log("dateOptions", dateOptions);

        this.setState({
          getLoansLoader: false,
          screenloader: false,
          dateOptions: dateOptions,
        });
      } else {
        this.setState({ getLoansLoader: false, loading: false });
        const message = "Something went wrong";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ getLoansLoader: false, loading: false });
      this.props.enqueueSnackbar("An error occurred while fetching data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    await this.GetDataByDefaultValue();
  }

  async componentDidUpdate(prevProps, prevState) {
    // Helper function to map values safely
    const mapValues = (items) =>
      Array.isArray(items)
        ? items
            .filter((item) => item != null && typeof item === "object")
            .map((item) => item.value)
        : [];

    // Safely map values for each state variable
    const prevAssetValues = mapValues(prevState.selectedAssetClass);
    const currentAssetValues = mapValues(this.state.selectedAssetClass);

    const prevIssuerValues = mapValues(prevState.selectedIssuer);
    const currentIssuerValues = mapValues(this.state.selectedIssuer);

    const prevDealsValues = mapValues(prevState.selectedDeals);
    const currentDealsValues = mapValues(this.state.selectedDeals);

    const prevDatesValues = mapValues(prevState.selectedDates);
    const currentDatesValues = mapValues(this.state.selectedDates);

    console.log("currentDatesValues", prevDatesValues, currentDatesValues);

    // Add a condition to check if state has meaningfully changed and avoid initial double calls
    if (!this.state.hasLoaded) {
      // Prevent execution until the initial data load is completed
      this.setState({ hasLoaded: true });
      return;
    }

    // Check if the selectedAssetClass values have changed
    if (
      JSON.stringify(prevAssetValues) !== JSON.stringify(currentAssetValues)
    ) {
      await this.GetDataByUpdateAsset();
    }

    // Check if the selectedIssuer values have changed
    if (
      JSON.stringify(prevIssuerValues) !== JSON.stringify(currentIssuerValues)
    ) {
      await this.GetDataByUpdateIssuer();
    }

    // Combine calls to GetDataByTableChartDataDeal to avoid multiple triggers
    const dealsChanged =
      JSON.stringify(prevDealsValues) !== JSON.stringify(currentDealsValues);
    const datesChanged =
      JSON.stringify(prevDatesValues) !== JSON.stringify(currentDatesValues) &&
      prevDatesValues.length !== 0; // Prevent calling on initial load

    // Call GetDataByTableChartDataDeal only if deals or dates have changed
    if (dealsChanged || datesChanged) {
      await this.GetDataByTableChartDataDeal();
    }

    // Only call GetDataByPaymentDatesDeal if deals have changed
    if (dealsChanged) {
      await this.GetDataByPaymentDatesDeal();
    }
  }

  render() {
    const { showDatepicker, startDate, endDate } = this.state;
    console.log("selectedDeals", this.state.selectedDeals);

    const customStyles = {
      control: (provided) => ({
        ...provided,
        border: "1px solid black",
        borderRadius: "8px",
        minHeight: "38px",
        boxShadow: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#adaeaa",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensures the dropdown is above other elements
      }),
      indicatorSeparator: (provided) => ({
        display: "none", // Removes the vertical line
      }),
    };

    return (
      <React.Fragment>
        <div className="">
          <Sidebar1 activeComponent={"DealAnalytics"} />
          <div className="content-bdb">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",

                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h3 className="headerdashboard">DEAL</h3>
                      </div>
                    </div>
                  </div>

                  <div className="FixTails">
                    <div className="dropdown-row">
                      <div className="dropdown-container">
                        <label className="dropdown-label">Asset Class:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.assetClassOptions}
                            value={this.state.selectedAssetClass}
                            onChange={this.handleSelectChange}
                            isClearable
                            placeholder="Select any one"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Issuer Group:</label>
                        <div className="dropdown-content-topBar">
                          <MultiSelect
                            options={this.state.issuerOptions}
                            value={this.state.selectedIssuer}
                            onChange={this.handleIssuerChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect"
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Deals:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.dealsOptions}
                            value={this.state.selectedDeals}
                            onChange={this.handleDealsChange}
                            isClearable
                            placeholder="Select deals"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Date:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.dateOptions}
                            value={this.state.selectedDates}
                            onChange={this.handleDatesChange}
                            isClearable
                            placeholder="All"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>
                    </div>

                    <DataDisplaySummary />

                    <div className="SummaryStds">
                      <div className="col-7 col-sm-6 col-md-7 hellocard">
                        <button
                          type="button"
                          onClick={() => this.handleClickSummary()}
                          className={
                            this.state.investorTab1 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Summary
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickStrats()}
                          className={
                            this.state.investorTab2 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Strats
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickPerformance()}
                          className={
                            this.state.investorTab3 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Performance
                        </button>
                      </div>

                      <div className="IDA_Virtual">
                        <div className="IDA_Icons">
                          <img src={IDA} alt="VirtualAsst" />
                          <img src={BETA} alt="VirtualAsst" className="beta" />
                        </div>
                        <ChatAssistant />
                      </div>
                    </div>
                  </div>

                  <div className="scrollable-content workbench-table-container scrollSummary">
                    {/* <div className="row row14">
                      <div className="general-InvContainer">
                        <div>
                          <label className="label">AssetClass</label>

                          <div className="align-strat-tabs">
                            <Select
                              options={this.state.assetClassOptions}
                              value={this.state.selectedAssetClass}
                              onChange={this.handleSelectChange}
                              isClearable
                              placeholder="Select any one"
                              // className="custom-select"
                              styles={customStyles}
                              components={{ DropdownIndicator }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="align-all-dropdowns">
                        <div>
                          <label className="label">Issuer</label>
                          <MultiSelect
                            options={this.state.issuerOptions}
                            value={this.state.selectedIssuer}
                            onChange={this.handleIssuerChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect"
                          />
                        </div>
                      </div>
                      <div className="align-all-dropdowns">
                        <div>
                          <label className="label">Deals</label>
                          <MultiSelect
                            options={this.state.dealsOptions}
                            value={this.state.selectedDeals}
                            onChange={this.handleDealsChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect"
                          />
                        </div>
                      </div>
                    </div> */}

                    <SummaryDash />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(DealAnalyticsSummary);
